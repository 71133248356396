import { useEffect, useRef, useState } from 'react';
import NavBar from '../../components/NAV-BAR';
import SideNavBar from '../../components/SIDE-NAV-BAR';
import './Clientes-Style.css'
import { collection, doc, getDoc, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { auth, db, storage } from '../../config/firebase';
import { getDownloadURL, ref, deleteObject, uploadString, uploadBytes, uploadBytesResumable } from '@firebase/storage';
// import { createUserWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

function Clientes() {
    const [NomeCliente, setNomeCliente] = useState("")
    const [SubNomeCliente, setSubNomeCliente] = useState("")
    const [EmailCliente, setEmailCliente] = useState("")
    const [CellTipoCliente, setCellTipoCliente] = useState("casa")
    const [CellCodCliente, setCellCodCliente] = useState("br")
    const [CellNumCliente, setCellNumCliente] = useState("")
    const [EndTipoCliente, setEndTipoCliente] = useState("trabalho")
    const [EndBairroCliente, setEndBairroCliente] = useState("")
    const [EndRuaCliente, setEndRuaCliente] = useState("")
    const [EndNumCliente, setEndNumCliente] = useState("")
    const [EndCompCliente, setEndCompCliente] = useState("")
    const [EndCidadeCliente, setEndCidadeCliente] = useState("")
    const [EndEstadoCliente, setEndEstadoCliente] = useState("")
    const [EndCepCliente, setEndCepCliente] = useState("")
    const [VerifyCep, setVerifyCep] = useState(false)
    const [EtiCliente, setEtiCliente] = useState("")
    const [Clientes, setClientes] = useState("")

    const [SitIsVisible, setSitIsVisible] = useState(false)

    const canvasRef = useRef(null)

    const [ImageUrlLocal, setImageUrlLocal] = useState("")

    const [NewLogo, setNewLogo] = useState(null)
    const [LogoToEdit, setLogoToEdit] = useState("")

    const toastId = useRef(null)

    function OpenSit() {
        setSitIsVisible(true)
    }

    function CloseSit() {
        setSitIsVisible(false)
    }

    function CleanInput() {
        setNomeCliente("")
        setSubNomeCliente("")
        setEmailCliente("")
        setCellTipoCliente("")
        setCellCodCliente("")
        setCellNumCliente("")
        setEndTipoCliente("")
        setEndBairroCliente("")
        setEndRuaCliente("")
        setEndNumCliente("")
        setEndCidadeCliente("")
        setEndEstadoCliente("")
        setEndCepCliente("")
        setEtiCliente("")
    }

    function validatorEmail(email) {
        let emailPattern =
            /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,6})$/;
        return emailPattern.test(email);
    }

    function CancelarSalvarCliente() {
        const Card = document.querySelector(".AddClienteCard")
        const Card2 = document.querySelector(".FadeAddClinte")
        Card.style.display = "none"
        Card2.style.display = "none"
        CleanInput()
    }

    function ShowCarAddCliente(N) {
        const Card = document.querySelector(".AddClienteCard")
        const Card2 = document.querySelector(".FadeAddClinte")
        Card.style.display = "block"
        Card2.style.display = "flex"
    }

    function ValidateCep() {
        if (!EndCepCliente) {
            toast.warn("Preencha o cep")
        } else {
            const value = EndCepCliente.replace(/[^0-9]+/, '');
            const url = `https://viacep.com.br/ws/${value}/json/`;
            fetch(url)
                .then(response => response.json())
                .then(json => {
                    if (json.logradouro) {
                        setEndRuaCliente(json.logradouro)
                        setEndBairroCliente(json.bairro)
                        setEndCidadeCliente(json.localidade)
                        setEndEstadoCliente(json.uf)
                        setEndCompCliente(json.complemento)
                        setVerifyCep(true)
                    }
                    else {
                        setVerifyCep(false)
                        toast.warn("Cep incorreto")
                    }
                });
        }
    }

    function SalvarCliente() {
        if (NomeCliente === "") {
            toast.warn('Preencha o campo "Nome"')
        } else if (SubNomeCliente === "") {
            toast.warn('Preencha o campo "Sobre Nome"')
        } else if (EmailCliente === "") {
            toast.warn('Preencha o campo "Email"')
        } else if (validatorEmail(EmailCliente) !== true) {
            toast.warn('Email invalido')
        } else if (CellNumCliente === "") {
            toast.warn('Preencha o campo "Numero"')
        } else if (EndNumCliente === "") {
            toast.warn('Preencha o campo "Numero endereço"')
        } else if (VerifyCep !== true) {
            toast.warn('Cep invalido')
        } else {
            toastId.current = toast("Salvando cliente", {
                autoClose: false,
                closeButton: false
            });
            const uid = uuidv4()
            const TasksRef = doc(db, 'Clientes', uid);

            const StorageRefPhoto = ref(storage, "Clientes/" + uid + "/imagens/capa-" + uid + ".png")
            uploadString(StorageRefPhoto, ImageUrlLocal, 'data_url').then((snapshot) => {
                getDownloadURL(StorageRefPhoto).then(url => {
                    try {
                        setDoc(TasksRef, {
                            Id: uid,
                            NomeCl: NomeCliente,
                            SubNomeCl: SubNomeCliente,
                            EmailCl: EmailCliente,
                            CellTipoCl: CellTipoCliente,
                            CellCodCl: CellCodCliente,
                            CellNumCl: CellNumCliente,
                            EndTipoCl: EndTipoCliente,
                            EndBairroCl: EndBairroCliente,
                            EndRuaCl: EndRuaCliente,
                            EndNumCl: EndNumCliente,
                            EndCidadeCl: EndCidadeCliente,
                            EndEstadoCl: EndEstadoCliente,
                            EndCepCl: EndCepCliente,
                            EndCompCl: EndCompCliente,
                            EtiCl: EtiCliente,
                            ImageUrl: url,
                            Senhapadrão: NomeCliente + "_" + EmailCliente,
                        }).then(() => {

                            const divToExport = document.querySelector('.AddClienteForm');
                            const scale = "6";

                            html2canvas(divToExport, { scale: scale }).then((canvas) => {

                                const dataURL = canvas.toDataURL('image/png');
                                const storageRef = ref(storage, "Clientes/" + uid + "/imagens/Perfil-" + NomeCliente + ".png");

                                uploadBytes(storageRef, dataURLtoBlob(dataURL)).then((snapshot) => {

                                    return getDownloadURL(storageRef)
                                        .then((url) => {
                                            updateDoc(TasksRef, {
                                                FotoPerfil: url,
                                            }).then(() => {
                                                toast.update(toastId.current, {
                                                    render: "Salvo com sucesso",
                                                    type: toast.TYPE.SUCCESS,
                                                    autoClose: 5000,
                                                    closeButton: null,
                                                    onClose: CancelarSalvarCliente()
                                                });
                                            })
                                        }).catch((e) => {
                                            toast.update(toastId.current, {
                                                render: (`Error inesperado: ${e}`),
                                                type: toast.TYPE.ERROR,
                                                autoClose: 5000,
                                                closeButton: null,
                                            });
                                        });

                                }).catch((e) => {
                                    toast.update(toastId.current, {
                                        render: (`Error inesperado: ${e}`),
                                        type: toast.TYPE.ERROR,
                                        autoClose: 5000,
                                        closeButton: null,
                                    });
                                });
                            });

                        })
                    } catch (e) {
                        toast.update(toastId.current, {
                            render: (`Error inesperado: ${e}`),
                            type: toast.TYPE.ERROR,
                            autoClose: 5000,
                            closeButton: null,
                        });
                        console.log(e.code);
                        console.log(e.messsage);
                    }
                })
            })
        }

        // Função auxiliar para converter dataURL em Blob
        function dataURLtoBlob(dataURL) {
            const arr = dataURL.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
    }

    function HandleFileNewLogoChange(e) {
        if (e.target.files[0]) {
            setNewLogo(e.target.files[0]);
        }
        EditarLogo()
    };

    function EditarLogo() {
        if (NewLogo === null) {
            toast.warn("Selecione uma foto para substiruir")
        } else {
            toastId.current = toast("Atualizando foto", {
                autoClose: false,
                closeButton: false
            });
            const StorageRef2 = ref(storage, "Clientes/" + LogoToEdit + "/imagens/capa-" + LogoToEdit + ".png")
            const UpLoadCapa = uploadBytesResumable(StorageRef2, NewLogo, 'data_url')
            UpLoadCapa.on(
                "state_changed",
                snapshot => { },
                error => { alert(error) },
                () => {
                    getDownloadURL(UpLoadCapa.snapshot.ref).then(url => {
                        const TasksRef = doc(db, 'Clientes', LogoToEdit);
                        try {
                            updateDoc(TasksRef, { ImageUrl: url })
                                .then(() => {
                                    toast.update(toastId.current, {
                                        render: "Atualizado com sucesso",
                                        type: toast.TYPE.SUCCESS,
                                        autoClose: 5000,
                                        closeButton: null,
                                        onOpen: setLogoToEdit("") + setNewLogo(null)
                                    });
                                })
                        } catch (e) {
                            toast.update(toastId.current, {
                                render: (`Error inesperado: ${e}`),
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                closeButton: null,
                            });
                        }
                    })
                }
            )
        }
    }

    function handleChangeCellTipo(event) {
        setCellTipoCliente(event.target.value);
    };

    function handleChangeCellTCod(event) {
        setCellCodCliente(event.target.value);
    };

    function handleChangeEndTipo(event) {
        setEndTipoCliente(event.target.value);
    };

    const Nc1 = NomeCliente ? NomeCliente[0].toUpperCase() : "N"
    const Nc2 = SubNomeCliente ? SubNomeCliente[0].toUpperCase() : "A"

    let randomColor = Math.floor(Math.random() * 16777215).toString(16);

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        context.fillStyle = "#" + randomColor
        context.fillRect(0, 0, context.canvas.width, context.canvas.height)
        context.fillStyle = '#ffffff'
        context.font = "38px arial";
        context.fillText(Nc1, 9, 51);
        context.fillText(Nc2, 40, 51);
        let image = canvas.toDataURL('image/png')
        setImageUrlLocal(image)
    }, [NomeCliente, SubNomeCliente])

    useEffect(() => {
        const q = query(collection(db, 'Clientes'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setClientes(todosArr);
        });
        return () => unsubscribe();
    }, []);

    const [SelectC, setSelectC] = useState("")
    const [optVisibleIndex, setOptVisibleIndex] = useState(null);

    function TogleOPTBox(index, N) {
        setSelectC(N)
        if (optVisibleIndex === index) {
            setOptVisibleIndex(null);
            setSelectC("")
        } else {
            setOptVisibleIndex(index);
        }
    };

    window.addEventListener("keydown", (e) => {
        if (e.keyCode === 27) {
            setOptVisibleIndex(null);
            setSelectC("")
            FecharPerfil()
            CloseSit()
        }
    })

    function CriarOrçamento(i) {
        window.location.href = (`http://localhost:3000/crm/orçamentos/add`)
    }

    function Criarfatura(i) {
        window.location.href = (`http://localhost:3000/crm/faturas/add`)
    }

    const [ClienteInfo, setClienteInfo] = useState([])

    useEffect(() => {
        if (SelectC !== "") {
            const loadProjectDetails = async () => {
                try {
                    const projectDoc = doc(db, 'Clientes', SelectC);
                    const projectSnapshot = await getDoc(projectDoc);
                    if (projectSnapshot.exists()) {
                        const projectData = projectSnapshot.data();
                        setClienteInfo([
                            projectData.NomeCl,
                            projectData.SubNomeCl,
                            projectData.EmailCl,
                            projectData.CellTipoCl,
                            projectData.CellCodCl,
                            projectData.CellNumCl,
                            projectData.EndTipoCl,
                            projectData.EndBairroCl,
                            projectData.EndRuaCl,
                            projectData.EndNumCl,
                            projectData.EndCompCl,
                            projectData.EndCidadeCl,
                            projectData.EndEstadoCl,
                            projectData.EndCepCl,
                            projectData.EtiCl,
                            projectData.ImageUrl,
                            projectData.FotoPerfil,
                        ]);
                    } else {
                        alert("nao foi possiel achar: " + SelectC)
                    }
                } catch (error) {
                    console.error("Erro DB" + error);
                }
            };
            loadProjectDetails();
        }
    }, [SelectC]);

    function CancelarPreviw() {
        const Card = document.querySelector(".AddClienteCard2")
        const Card2 = document.querySelector(".FadeAddClinte2")
        Card.style.display = "none"
        Card2.style.display = "none"
        CleanInput()
    }

    function ShowPreviw() {
        const Card = document.querySelector(".AddClienteCard2")
        const Card2 = document.querySelector(".FadeAddClinte2")
        Card.style.display = "block"
        Card2.style.display = "flex"
    }

    function EditarCL() {
        setOptVisibleIndex(null);
        ShowPreviw()
        setNomeCliente(ClienteInfo[0])
        setSubNomeCliente(ClienteInfo[1])
        setEmailCliente(ClienteInfo[2])
        setCellTipoCliente(ClienteInfo[3])
        setCellCodCliente(ClienteInfo[4])
        setCellNumCliente(ClienteInfo[5])
        setEndTipoCliente(ClienteInfo[6])
        setEndBairroCliente(ClienteInfo[7])
        setEndRuaCliente(ClienteInfo[8])
        setEndNumCliente(ClienteInfo[9])
        setEndCompCliente(ClienteInfo[10])
        setEndCidadeCliente(ClienteInfo[11])
        setEndEstadoCliente(ClienteInfo[12])
        setEndCepCliente(ClienteInfo[13])
        setEtiCliente(ClienteInfo[14])
    }

    function SalvarEditarCliente() {
        if (NomeCliente === "") {
            toast.warn('Preencha o campo "Nome"')
        } else if (SubNomeCliente === "") {
            toast.warn('Preencha o campo "Sobre Nome"')
        } else if (EmailCliente === "") {
            toast.warn('Preencha o campo "Email"')
        } else if (validatorEmail(EmailCliente) !== true) {
            toast.warn('Email invalido')
        } else if (CellNumCliente === "") {
            toast.warn('Preencha o campo "Numero"')
        } else if (EndNumCliente === "") {
            toast.warn('Preencha o campo "Numero endereço"')
        } else if (VerifyCep !== true) {
            toast.warn('Cep invalido')
        } else {
            toastId.current = toast("Salvando cliente", {
                autoClose: false,
                closeButton: false
            });
            const TasksRef = doc(db, 'Clientes', SelectC);

            try {
                updateDoc(TasksRef, {
                    NomeCl: NomeCliente,
                    SubNomeCl: SubNomeCliente,
                    EmailCl: EmailCliente,
                    CellTipoCl: CellTipoCliente,
                    CellCodCl: CellCodCliente,
                    CellNumCl: CellNumCliente,
                    EndTipoCl: EndTipoCliente,
                    EndBairroCl: EndBairroCliente,
                    EndRuaCl: EndRuaCliente,
                    EndNumCl: EndNumCliente,
                    EndCidadeCl: EndCidadeCliente,
                    EndEstadoCl: EndEstadoCliente,
                    EndCepCl: EndCepCliente,
                    EndCompCl: EndCompCliente,
                    EtiCl: EtiCliente,
                }).then(() => {
                    const divToExport = document.querySelector('.AddClienteForm2');
                    const scale = "6";

                    html2canvas(divToExport, { scale: scale }).then((canvas) => {

                        const dataURL = canvas.toDataURL('image/png');
                        const storageRef = ref(storage, "Clientes/" + SelectC + "/imagens/Perfil-" + NomeCliente + ".png");

                        uploadBytes(storageRef, dataURLtoBlob(dataURL)).then((snapshot) => {

                            return getDownloadURL(storageRef)
                                .then((url) => {
                                    updateDoc(TasksRef, {
                                        FotoPerfil: url,
                                    }).then(() => {
                                        toast.update(toastId.current, {
                                            render: "Salvo com sucesso",
                                            type: toast.TYPE.SUCCESS,
                                            autoClose: 5000,
                                            closeButton: null,
                                            onClose: CancelarSalvarCliente()
                                        });
                                    })
                                }).catch((e) => {
                                    toast.update(toastId.current, {
                                        render: (`Error inesperado 1 : ${e}`),
                                        type: toast.TYPE.ERROR,
                                        autoClose: 5000,
                                        closeButton: null,
                                    });
                                });

                        }).catch((e) => {
                            toast.update(toastId.current, {
                                render: (`Error inesperado 2 : ${e}`),
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                closeButton: null,
                            });
                        });
                    });

                })
            } catch (e) {
                toast.update(toastId.current, {
                    render: (`Error inesperado 3 : ${e}`),
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    closeButton: null,
                });
                console.log(e.code);
                console.log(e.messsage);
            }
        }
        // Função auxiliar para converter dataURL em Blob
        function dataURLtoBlob(dataURL) {
            const arr = dataURL.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
    }

    function AbrirPerfil() {
        setOptVisibleIndex(null);
        const Card = document.querySelector(".ImgPerfil")
        Card.style.display = "flex"
    }

    function FecharPerfil() {
        const Card = document.querySelector(".ImgPerfil")
        Card.style.display = "none"
        setSelectC("")
    }

    function VizualizarFaturas(N) {
        window.location.href = `/crm/faturas/search/${N}`
    }

    function VizualizarOrçamentos(N) {
        window.location.href = `/crm/orçamentos/search/${N}`
    }

    const [FaturasDoCliente, setFaturasDoCliente] = useState([])

    useEffect(() => {
        const q = query(
            collection(db, 'Faturas'),
            where('Id_Cliente', '==', SelectC)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setFaturasDoCliente(todosArr);
        });
        return () => unsubscribe();
    }, [SelectC]);

    const FaturasDoClienteF1 = [...FaturasDoCliente.filter(item => item.Status === "Faturada")]

    const [ValorDevedorTotal, setValorDevedorTotal] = useState(0)

    useEffect(() => {
        // Função para calcular a diferença e somar os valores
        const calcularDiferencaESomar = () => {
            // Subtrair ValorPago de Total para cada item
            const diferencaValores = FaturasDoClienteF1.map(item => item.Total - item.ValorPago);

            // Somar as diferenças dos valores
            const somaDiferencas = diferencaValores.reduce((total, diferenca) => total + diferenca, 0);

            // Imprimir o resultado (ou fazer o que precisar com ele)
            setValorDevedorTotal(somaDiferencas);
        };

        // Chamar a função ao carregar o componente
        calcularDiferencaESomar();
    }, [FaturasDoClienteF1]); // Certifique-se de incluir seuArray como dependência se for modificado dinamicamente

    const [totalPago, setTotalPago] = useState(0);

    useEffect(() => {
        // Função para calcular o valor total pago
        const calcularTotalPago = () => {
            // Somar o ValorPago de todos os itens
            const totalPago = FaturasDoClienteF1.reduce((total, item) => total + item.ValorPago, 0);

            // Salvar o total pago no estado
            setTotalPago(totalPago);
        };

        // Chamar a função ao carregar o componente
        calcularTotalPago();
    }, [FaturasDoClienteF1]);

    const handleDownload = () => {
        const section = document.querySelector('.Situação'); // Substitua pela classe real da sua seção
        if (!section) {
            console.error('Seção não encontrada');
            return;
        }

        html2canvas(section).then(canvas => {
            // Cria um link para fazer o download da imagem gerada
            const link = document.createElement('a');
            link.href = canvas.toDataURL();
            link.download = 'screenshot.png'; // Substitua pelo nome desejado do arquivo
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    return (
        <>
            <head>
                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/clientes`} />
                <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="600" />
                <meta property="og:description" content={`Gerenciamento de clientes`} />
                <meta name="description" content="Gerenciamento de clientes" />
            </head>

            <title>KAWHE | CLIENTES</title>

            <NavBar />
            <main>
                <SideNavBar />
                <div className='MainClientes'>
                    <section className='NavUpLoad'>
                        <h2 className='TxHeaderUpLoad'>Clientes <span className='QtnArquivos'>{Clientes.length}</span></h2>
                        <button className='BtnAddUpLoad' onClick={() => ShowCarAddCliente()}><i class="fa-solid fa-plus fa-plus-pj"></i>Adicionar Clientes</button>
                        <section className='Fade FadeAddClinte'>
                            <section className='AddClienteCard'>
                                <section className='AddClienteHeader'>
                                    CRIAR NOVO CLIENTE:
                                </section>
                                <section className='AddClienteForm'>
                                    <div className='ROW-FORM-AC'>
                                        <div className='TextNome TX-CL'>NOME:</div>
                                        <div className='C-C InpNome'>
                                            <div className='InpBNome'>
                                                <input type="name" id='AddClienteNome' className='AddClienteNome ADC' placeholder='NOME' onChange={e => setNomeCliente(e.target.value)} value={NomeCliente} />
                                                <input type="sub-name" id='AddClienteSubNome' className='AddClienteSubNome ADC' placeholder='SOBRE NOME' onChange={e => setSubNomeCliente(e.target.value)} value={SubNomeCliente} />
                                            </div>
                                            <div>
                                                <canvas ref={canvasRef} id='FotoPerfilCliente' className='FotoPerfilCliente' width="75px" height="75px" ></canvas>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ROW-FORM-AC'>
                                        <div className='TextEmail TX-CL'>EMAIL:</div>
                                        <input type="email" id='AddClienteEmail' className='AddClienteEmail ADC' placeholder='EMAIL' onChange={e => setEmailCliente(e.target.value)} value={EmailCliente} />
                                    </div>

                                    <div className='ROW-FORM-AC'>
                                        <div className='Textcell TX-CL'>TELEFONE:</div>
                                        <div className='TelefoneAddCl'>
                                            <select id='AddClienteCellTipo' className='AddClienteCellTipo ADC AddClienteCell' onChange={handleChangeCellTipo}>
                                                <option value="CASA">CASA</option>
                                                <option value="MOBILE">MOBILE</option>
                                                <option value="TRABALHO">TRABALHO</option>
                                            </select>
                                            <select id='AddClienteCellCod' className='AddClienteCellCod ADC AddClienteCell' onChange={handleChangeCellTCod} >
                                                <option value="BR">+55</option>
                                                <option value="US">+1</option>
                                            </select>
                                            <input type="tel" id='AddClienteCellNum' className='AddClienteCellNum ADC AddClienteCell' placeholder='NUMERO COM DDD' onChange={e => setCellNumCliente(e.target.value)} value={CellNumCliente} />
                                        </div>
                                    </div>

                                    <div className='ROW-FORM-AC'>
                                        <div className='Textend TX-CL'>ENDEREÇO:</div>
                                        <div className='END_DIV'>
                                            <select id='AddClienteEndTipo' className='AddClienteEndTipo ADC' onChange={handleChangeEndTipo}>
                                                <option value="CASA">CASA</option>
                                                <option value="TRABALHO">TRABALHO</option>
                                            </select>
                                            <div>
                                                <input type="bairo" id='AddClienteEndBairro' className='AddClienteEndBairro ADC' placeholder='BAIRRO' onChange={e => setEndBairroCliente(e.target.value)} value={EndBairroCliente} />
                                                <input type="rua" id='AddClienteEndRua' className='AddClienteEndRua ADC' placeholder='RUA' onChange={e => setEndRuaCliente(e.target.value)} value={EndRuaCliente} />
                                                <input type="text" id='AddClienteEndNumero' className='AddClienteEndNumero ADC' placeholder='NUMERO' onChange={e => setEndNumCliente(e.target.value)} value={EndNumCliente} />
                                            </div>
                                            <input type="text" id='AddClienteEndComp' className='AddClienteEndComp ADC' placeholder='COMPLEMENTO (OPCIONAL)' onChange={e => setEndCompCliente(e.target.value)} value={EndCompCliente} />
                                            <div>
                                                <input type="text" id='AddClienteEndCidade' className='AddClienteEndEstado ADC' placeholder='ESTADO' onChange={e => setEndEstadoCliente(e.target.value)} value={EndEstadoCliente} />
                                                <input type="text" id='AddClienteEndCidade' className='AddClienteEndCidade ADC' placeholder='CIDADE' onChange={e => setEndCidadeCliente(e.target.value)} value={EndCidadeCliente} />
                                                <input maxLength="8" type="text" id='AddClienteEndCep' className='AddClienteEndCep ADC' placeholder='CÓDIGO POSTAL (CEP)' onChange={e => setEndCepCliente(e.target.value)} value={EndCepCliente} onBlur={() => ValidateCep()} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ROW-FORM-AC TX-CL'>
                                        <div className='TextEti'>ETIQUETAS:</div>
                                        <input type="text" id='AddClienteEti' className='AddClienteEti ADC' onChange={e => setEtiCliente(e.target.value)} value={EtiCliente} />
                                    </div>
                                </section>
                                <section className='AddClienteFoot'>
                                    <button className='BtnSalvarCliente' onClick={() => SalvarCliente()}>SALVAR</button>
                                    <button className='BtnCancelarSalvarCliente' onClick={() => CancelarSalvarCliente()}>CANCELAR</button>
                                </section>
                            </section>

                        </section>

                    </section>

                    <section className='ListaClientes'>
                        <tr>
                            <th className='TdImgC'>Logo</th>
                            <th className='TdNameC'>Nome</th>
                            <th className='TdEmailC'>Email</th>
                            <th className='TdOptC'>Opções</th>
                        </tr>
                        {
                            Clientes ? Clientes.map((i, index) => (
                                <tr key={index} className='tabela'>
                                    <td className='TdImgC'>

                                        <img className='LogoCliente' src={i.ImageUrl} alt={"logo " + i.NomeCl} />
                                    </td>
                                    <td className='TdNameC'>{i.NomeCl + " " + i.SubNomeCl}</td>
                                    <td className='TdEmailC'>{i.EmailCl}</td>

                                    <td className='TdOptC'>
                                        <div className={`OPT OPT${index}`} style={{ display: optVisibleIndex === index ? 'block' : 'none' }}>
                                            <button onClick={() => EditarCL()}>Editar</button>
                                            <button onClick={() => AbrirPerfil()} >Vizualizar</button>
                                            <button onClick={() => OpenSit()}>Situação</button>
                                            <button onClick={() => CriarOrçamento()}>Criar orçamento</button>
                                            <button onClick={() => Criarfatura()}>Criar fatura</button>
                                            <button onClick={() => VizualizarFaturas(i.NomeCl + " " + i.SubNomeCl)}>Ver faturas</button>
                                            <button onClick={() => VizualizarOrçamentos(i.NomeCl + " " + i.SubNomeCl)}>Ver orçamentos</button>
                                        </div>
                                        <button className="BTN_OPT_OR" onClick={() => TogleOPTBox(index, i.Id)}>
                                            <div className="CIRCLE_BTN_OR"></div>
                                            <div className="CIRCLE_BTN_OR"></div>
                                            <div className="CIRCLE_BTN_OR"></div>
                                        </button>
                                    </td>
                                </tr>
                            ))
                                :
                                <tr>
                                    <td>
                                        <svg className='Loading'
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="3em"
                                            viewBox="0 0 512 512">
                                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                                        </svg>
                                    </td>
                                </tr>
                        }
                    </section>
                    {
                        SitIsVisible &&
                        <div className='Centred'>
                            <div>

                                <section className='Situação'>
                                    <p className='TitleSit'>{ClienteInfo[0] + " " + ClienteInfo[1]}</p>
                                    <p className='FatABSIT'>Faturas em aberto</p>
                                    {FaturasDoCliente ?
                                        FaturasDoCliente.map((fatura, index) => {
                                            if (fatura.Status === "Faturada") {
                                                return (
                                                    <>
                                                        <a className='NomeFatSit' href={`/crm/Faturas/${fatura.N_Or}`}>{fatura.Title}</a>
                                                        <p className='VSit'>
                                                            Valor: R$ {(fatura.Total - fatura.ValorPago).toFixed(2).replace(".", ",")}
                                                        </p>
                                                    </>
                                                );
                                            } else {
                                                return null
                                            }
                                        })
                                        :
                                        (
                                            <>
                                                <p>Nenhuma fatura em aberto</p>
                                            </>
                                        )
                                    }
                                    {ValorDevedorTotal > 0 && (
                                        <>
                                            <p className='VTPSite'>Valor Devedor Total</p>
                                            <p className='VSIT'>R$ {ValorDevedorTotal.toFixed(2).replace(".", ",")}</p>
                                        </>
                                    )
                                    }

                                    {totalPago > 0 && (
                                        <>
                                            <p className='VTPSite'>Valor Pago Total</p>
                                            <p className='VSIT'>{totalPago}</p>
                                        </>
                                    )
                                    }
                                </section>
                                <button onClick={CloseSit}>Fechar</button>
                            </div>
                            <button className='BTNDANW' onClick={handleDownload}>Baixar Foto</button>
                        </div>
                    }
                </div>
            </main>

            <section className='Fade FadeAddClinte2'>
                <section className='AddClienteCard2'>
                    <section className='AddClienteHeader'>
                        EDITAR CLIENTE:
                    </section>
                    <section className='AddClienteForm2'>
                        <div className='ROW-FORM-AC'>
                            <div className='TextNome TX-CL'>NOME:</div>
                            <div className='C-C InpNome'>
                                <div className='InpBNome'>
                                    <input type="name" id='AddClienteNome' className='AddClienteNome ADC' placeholder='NOME' onChange={e => setNomeCliente(e.target.value)} value={NomeCliente} />
                                    <input type="sub-name" id='AddClienteSubNome' className='AddClienteSubNome ADC' placeholder='SOBRE NOME' onChange={e => setSubNomeCliente(e.target.value)} value={SubNomeCliente} />
                                </div>
                                <div className='EditableImg' >
                                    <label htmlFor="FileLogoCliente" >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="25px"
                                            viewBox="0 0 512 512">
                                            <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                                        </svg>
                                    </label>
                                    <img src={ClienteInfo[15]} alt={`Logo ${NomeCliente}`} />
                                </div>
                            </div>
                        </div>

                        <div className='ROW-FORM-AC'>
                            <div className='TextEmail TX-CL'>EMAIL:</div>
                            <input type="email" id='AddClienteEmail' className='AddClienteEmail ADC' placeholder='EMAIL' onChange={e => setEmailCliente(e.target.value)} value={EmailCliente} />
                        </div>

                        <div className='ROW-FORM-AC'>
                            <div className='Textcell TX-CL'>TELEFONE:</div>
                            <div className='TelefoneAddCl'>
                                <select id='AddClienteCellTipo' className='AddClienteCellTipo ADC AddClienteCell' onChange={handleChangeCellTipo}>
                                    <option value="CASA">CASA</option>
                                    <option value="MOBILE">MOBILE</option>
                                    <option value="TRABALHO">TRABALHO</option>
                                </select>
                                <select id='AddClienteCellCod' className='AddClienteCellCod ADC AddClienteCell' onChange={handleChangeCellTCod} >
                                    <option value="BR">+55</option>
                                    <option value="US">+1</option>
                                </select>
                                <input type="tel" id='AddClienteCellNum' className='AddClienteCellNum ADC AddClienteCell' placeholder='NUMERO COM DDD' onChange={e => setCellNumCliente(e.target.value)} value={CellNumCliente} />
                            </div>
                        </div>

                        <div className='ROW-FORM-AC'>
                            <div className='Textend TX-CL'>ENDEREÇO:</div>
                            <div className='END_DIV'>
                                <select id='AddClienteEndTipo' className='AddClienteEndTipo ADC' onChange={handleChangeEndTipo}>
                                    <option value="CASA">CASA</option>
                                    <option value="TRABALHO">TRABALHO</option>
                                </select>
                                <div>
                                    <input type="bairo" id='AddClienteEndBairro' className='AddClienteEndBairro ADC' placeholder='BAIRRO' onChange={e => setEndBairroCliente(e.target.value)} value={EndBairroCliente} />
                                    <input type="rua" id='AddClienteEndRua' className='AddClienteEndRua ADC' placeholder='RUA' onChange={e => setEndRuaCliente(e.target.value)} value={EndRuaCliente} />
                                    <input type="text" id='AddClienteEndNumero' className='AddClienteEndNumero ADC' placeholder='NUMERO' onChange={e => setEndNumCliente(e.target.value)} value={EndNumCliente} />
                                </div>
                                <input type="text" id='AddClienteEndComp' className='AddClienteEndComp ADC' placeholder='COMPLEMENTO (OPCIONAL)' onChange={e => setEndCompCliente(e.target.value)} value={EndCompCliente} />
                                <div>
                                    <input type="text" id='AddClienteEndCidade' className='AddClienteEndEstado ADC' placeholder='ESTADO' onChange={e => setEndEstadoCliente(e.target.value)} value={EndEstadoCliente} />
                                    <input type="text" id='AddClienteEndCidade' className='AddClienteEndCidade ADC' placeholder='CIDADE' onChange={e => setEndCidadeCliente(e.target.value)} value={EndCidadeCliente} />
                                    <input maxLength="8" type="text" id='AddClienteEndCep' className='AddClienteEndCep ADC' placeholder='CÓDIGO POSTAL (CEP)' onChange={e => setEndCepCliente(e.target.value)} value={EndCepCliente} onBlur={() => ValidateCep()} />
                                </div>
                            </div>
                        </div>

                        <div className='ROW-FORM-AC TX-CL'>
                            <div className='TextEti'>ETIQUETAS:</div>
                            <input type="text" id='AddClienteEti' className='AddClienteEti ADC' onChange={e => setEtiCliente(e.target.value)} value={EtiCliente} />
                        </div>
                    </section>
                    <section className='AddClienteFoot'>
                        <button className='BtnSalvarCliente' onClick={() => SalvarEditarCliente()}>SALVAR</button>
                        <button className='BtnCancelarSalvarCliente' onClick={() => CancelarPreviw()}>CANCELAR</button>
                    </section>
                </section>

                <input type="file" id='FileLogoCliente' className='FileLogoCliente' onChange={HandleFileNewLogoChange} />

            </section>

            <section className='ImgPerfil' style={{ display: 'none' }} >
                <img src={ClienteInfo[16]} alt={`Perfil ${NomeCliente}`} />
                <p onClick={FecharPerfil}>X</p>
            </section>

        </>
    )
}
export default Clientes;