import './nav-bar-style.css'
import { auth } from '../../config/firebase';
import {  onAuthStateChanged, signOut } from 'firebase/auth';

function NavBar() {

    onAuthStateChanged(auth, (user) => {
        if (!user) {
            window.location.href = "/login"
        }
        if (user.uid === "pT9QAfGunpZ3Abzl1PrRxeW9v5Q2" || user.uid === "RaugT4w726WAhUvG8H5nUyi9e493") {
        } else {
            signOut(auth).then(() => {
                window.location.href = "/login"
            }).catch((error) => {
                console.log(error)
            });
        }
    });

    return (
        <>
            <nav className="cabeçalho">
                <section className="marca">
                    <a href="https://admin.kawhealvesdossantos.com.br"><img className="logo" src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa" alt="logo" /></a>
                    <h1 className="meu-nome">KAWHE ALVES DOS SANTOS</h1>
                </section>
            </nav>
        </>
    )
}
export default NavBar;