import { useEffect, useRef, useState } from 'react';
import NavBar from '../../components/NAV-BAR';
import SideNavBar from '../../components/SIDE-NAV-BAR';
import './UpLoads-Style.css'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { auth, db, storage } from '../../config/firebase';
import { collection, deleteDoc, doc, onSnapshot, query, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';


function UploadsPage() {

  let DataAtual = new Date();
  let Ano = DataAtual.getFullYear()

  const [File, setFile] = useState(null)
  const [FileViw, setFileViw] = useState(null)
  const [FileProgress, setFileProgress] = useState(0)
  const [SelectedYear, setSelectedYear] = useState(Ano)

  const toastId = useRef(null);

  function ShowCardAddUpLoads() {
    const Card = document.querySelector(".CardControlable")
    Card.style.display = "block"
    setFile(null)
    setFileProgress(0)
  }

  function CancelarUpLoads() {
    const Card = document.querySelector(".CardControlable")
    Card.style.display = "none"
  }

  document.addEventListener("keydown", (event) => {
    if (event.keyCode === 27) { //escape key
      CancelarUpLoads();
    }
  })

  function HandleFileChange(e) {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  function SaveUpLoad() {
    if (!File) {
      toast.warn("Selecione um arquivo")
      return;
    } else {
      toastId.current = toast("Salvando seu arquivo", {
        autoClose: false,
        closeButton: false
      });
      const id = uuidv4()
      const FileName = File.name
      const StorageRef = ref(storage, "Uploads/" + Ano + "/admin/" + FileName)
      const UpLoadCapa = uploadBytesResumable(StorageRef, File)
      UpLoadCapa.on(
        "state_changed",
        snapshot => {
          const CapaProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setFileProgress(CapaProgress)
        },
        error => {
          alert(error)
        },
        () => {
          getDownloadURL(UpLoadCapa.snapshot.ref).then(url => {
            const TasksRef = doc(db, 'Uploads-Admin', FileName);
            try {
              setDoc(TasksRef, {
                Id: id,
                Name: FileName,
                Url: url,
                Type: File.type,
                Year: Ano,
              }).then(() => {
                toast.update(toastId.current, {
                  render: "Salvo com sucesso",
                  type: toast.TYPE.SUCCESS,
                  autoClose: 5000,
                  closeButton: null,
                  onOpen: setFile(null) + setFileProgress(0) + CancelarUpLoads
                });
              })
            } catch (e) {
              toast.update(toastId.current, {
                render: (`Error inesperado: ${e}`),
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                closeButton: null,
              });
            }
          })
        }
      )
    }
  }

  useEffect(() => {
    const q = query(collection(db, 'Uploads-Admin'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let todosArr = [];
      querySnapshot.forEach((doc) => {
        todosArr.push({ ...doc.data() });
      });
      setFileViw(todosArr);
    });
    return () => unsubscribe();
  }, []);

  function DeletarArquivo(i, a) {
    deleteDoc(doc(db, 'Uploads-Admin', i))
    const desertRef = ref(storage, "Uploads/" + a + "/admin/" + i);
    deleteObject(desertRef)
  }

  const [searchText, setSearchText] = useState('');
  const [filteredYear, setFilteredYear] = useState(null);

  function HandleSearchChange(e) {
    setSearchText(e.target.value);
  }

  function FilterFiles(file) {
    const nameMatches = file.Name.toLowerCase().includes(searchText.toLowerCase());
    const yearMatches = !filteredYear || file.Year === filteredYear;
    return nameMatches && yearMatches;
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    // Adicione estilos visuais ou classes CSS conforme necessário
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    // Remova estilos visuais ou classes CSS adicionadas durante o arrastar
  };

  return (
    <>
      <head>
        <meta property="og:title" content="CRM || kawhe" />
        <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/clientes`} />
        <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta property="og:description" content={`Uploads`} />
        <meta name="description" content="Uploads" />
      </head>


      <title>KAWHE | UPLOADS | RESTRITO</title>
      <NavBar />
      <main>
        <SideNavBar />
        <div className='MainUpLoad'>
          <section className='NavUpLoad'>
            <h2 className='TxHeaderUpLoad'>Arquivos <span className='QtnArquivos'>{FileViw && FileViw.length}</span></h2>
            <button className='BtnAddUpLoad' onClick={ShowCardAddUpLoads} ><i class="fa-solid fa-plus fa-plus-pj"></i>Adicionar Aquivos</button>
            <div className='CardControlable'>
              <div className='Fade'>
                <section className='CardAddUpLoad'>
                  <span>SELICINE SEU ARQUIVO:</span>
                  <div className='DivLabelInputUpLoad'>
                    <label htmlFor="InputUpLoad" className='LabelInputUpLoad' onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)} onDragEnter={(e) => handleDragEnter(e)} onDragLeave={(e) => handleDragLeave(e)} >
                      {File ? File.name : "SOLTE SEU ARQUIVO AQUI"}
                    </label>
                  </div>
                  <input type="file" id='InputUpLoad' className='InputUpLoad' onChange={HandleFileChange} />
                  <button className='BtnSaveUpLoad btnpopuploads' onClick={SaveUpLoad}>Salvar</button>
                  <button className='BtnCancelarUpLoad btnpopuploads' onClick={CancelarUpLoads}>Cancelar</button>
                  <progress value={FileProgress} max={100} className='ProgressUpLoad'></progress>
                </section>
              </div>
            </div>
          </section>
          <section className='SearchUpLoad'>
            <div className='CardSearch'>
              <svg xmlns="http://www.w3.org/2000/svg" className='SearchSimbol' height="1.5em" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
              <div className='Separete'>:</div>
              <div>
                <input type="text" name="search" id="search" className='InputSearchUpLoad' onChange={HandleSearchChange} />
                <div className='LineInputUpLoad'></div>
              </div>
              <div className='SelectYear'>
                <input type="number" min={2023} onChange={e => setFilteredYear(parseInt(e.target.value))} value={filteredYear || ''} />
              </div>
            </div>
            {/* <div className='SelectYear' >
              <input type="number" min={2023} onChange={e => setSelectedYear(e.target.value)} value={SelectedYear} />
            </div> */}
          </section>

          <div className="GridPreview">
            {!FileViw ? (
              <div className='LoadingUp'>
                <svg className='Loading'
                  xmlns="http://www.w3.org/2000/svg"
                  height="3em"
                  viewBox="0 0 512 512">
                  <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                </svg>
              </div>
            )
              : FileViw.filter(FilterFiles).map((i, index) => (

                <div key={index} className="FilePreview">
                  <p><a href={i.Url} download={i.Name}>{i.Name}</a></p> <button onClick={() => DeletarArquivo(i.Name, i.Year)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.5em"
                      viewBox="0 0 448 512">
                      <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    </svg>
                  </button>
                </div>
              ))}
          </div>

        </div>
      </main>
    </>
  );
}
export default UploadsPage;
