import { signOut } from "firebase/auth";
import "./Hub-Style.css"
import { auth } from "../../../../config/firebase";
import { toast } from 'react-toastify'

function HubVelha() {

    function LogOut() {
        signOut(auth).then(() => {
            window.location.href = "/games/velha/online/login"
        }).catch((error) => {
            toast.error(error.message)
        });
    }

    return (
        <>
            <nav>
                <div>
                    <button onClick={LogOut}>
                        sair
                    </button>
                </div>
            </nav>
        </>
    )
}
export default HubVelha;