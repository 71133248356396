import './index-login-Style.css'
import { useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { doc, setDoc } from 'firebase/firestore'
import { auth, db } from '../../../../config/firebase'
import { toast } from 'react-toastify'
import { signInAnonymously } from 'firebase/auth'

function LoginVelha () {
  const toastId = useRef(null)

  const id = uuidv4()

  const [Name, setName] = useState('')

  console.log(Name)

  function login () {
    if (Name !== '') {
      toastId.current = toast('Salvando seu arquivo', {
        autoClose: false,
        closeButton: false
      })

      signInAnonymously(auth)
        .then(() => {
          const TasksRef = doc(db, 'User-Games/', id)
          try {
            setDoc(TasksRef, {
              Id: id,
              Name: Name,
              Current_session: ''
            }).then(() => {
              window.location.href='/games/velha/online/salas'
            })
          } catch (e) {
            toast.update(toastId.current, {
              render: `Error inesperado: ${e}`,
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              closeButton: null
            })
          }
        })
        .catch(e => {
          toast.update(toastId.current, {
            render: `Error inesperado: ${e}`,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
            closeButton: null
          })
        })
    } else {
      toast.warn('Preencha o nome')
    }
  }

  return (
    <>
      <input
        type='text'
        id='Name'
        onChange={e => setName(e.target.value)}
        value={Name}
      />
      <button onClick={login}>Entrar</button>
    </>
  )
}
export default LoginVelha
