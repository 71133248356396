import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Login from "../pages/login";
import Projetos from "../pages/projetos";
import TaskManagerDeleted from "../pages/TaskManager/Deleted";
import TaskManagerInProgress from "../pages/TaskManager/InProgress";
import TaskManagerReady from "../pages/TaskManager/Ready";
import Erro404 from "../pages/Error404";
import UploadsPage from "../pages/Upload";
import AddProjetos from "../pages/projetos/AddProjetos";
import EditarProjeto from "../pages/projetos/EditarProjeto";
import TicTacToe from "../pages/Games/Velha-Of/TicTacToe";
import Clientes from "../pages/Clientes";
import Orçamento from "../pages/Orçamentos";
import CriarOrçamentos from "../pages/Orçamentos/CriarOrçamentos";
import T from "../pages/teste/teste";
import AceitarOr from "../pages/Orçamentos/AceitarOrcamento";
import LoginVelha from "../pages/Games/Velha-On/login/login";
import HubVelha from "../pages/Games/Velha-On/Hub/inidex";
import CreatAcount from "../pages/Games/Velha-On/Creat-Acount";
import Faturas from "../pages/Faturas";
import CriarFaturas from "../pages/Faturas/CriarFatura";
import ViwFaturas from "../pages/Faturas/Viw";
import EditarOrçamento from "../pages/Orçamentos/EditarOrçamento";
import ViwFaturasCl from "../pages/Faturas/ViwFCl";
import ViwOrçamentoCl from "../pages/Orçamentos/ViwOCl";
import Configurações from "../pages/Configurações";
import Ajenda from "../pages/ajenda";
import Produtos from "../pages/Produtos";
import Calc_Hid from "../pages/Calc-Hid";
import Redirects from "../pages/Redirects/inde";


function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />

                {/* projetos */}
                <Route path="/projetos" element={<Projetos />} />
                <Route path="/projetos/AddProjetos" element={<AddProjetos />} />
                <Route path="/projetos/EditarProjetos/:id" element={<EditarProjeto />} />
                <Route path="/produtos" element={<Produtos />} />
                {/* projetos */}

                {/* CRM */}
                <Route path="/crm/clientes" element={<Clientes />} />

                <Route path="/crm/orçamentos" element={<Orçamento />} />

                <Route path="/crm/orçamentos/:N" element={<AceitarOr />} />
                <Route path="/crm/aceitaror/:N" element={<AceitarOr />} />

                <Route path="/crm/orçamentos/add" element={<CriarOrçamentos />} />
                <Route path="/crm/orçamentos/Editar/:N" element={<EditarOrçamento />} />
                <Route path="/crm/Orçamentos/search/:IdCl" element={<ViwOrçamentoCl />} />

                <Route path="/crm/faturas" element={<Faturas />} />
                <Route path="/crm/faturas/:N" element={<ViwFaturas />} />
                <Route path="/crm/faturas/search/:IdCl" element={<ViwFaturasCl />} />
                <Route path="/crm/faturas/add" element={<CriarFaturas />} />
                {/* CRM */}

                <Route path="/redirects" element={<Redirects />} />

                {/* Tasks Manager */}
                <Route path="/kawhe/cancelado" element={<TaskManagerDeleted />} />
                <Route path="/kawhe/emprogresso" element={<TaskManagerInProgress />} />
                <Route path="/kawhe/finalizado" element={<TaskManagerReady />} />
                {/* Tasks Manager */}

                {/* Uploads */}
                <Route path="/uploads" element={<UploadsPage />} />
                {/* Uploads */}

                {/* Erros */}
                <Route path="/*" element={<Erro404 />} />
                {/* Erros */}

                {/* Games */}
                <Route path="/games/velha" element={<TicTacToe />} />

                    {/* online */}
                    <Route path="/games/velha/online/login" element={<LoginVelha />} />
                    <Route path="/games/velha/online/Register" element={<CreatAcount />} />
                    <Route path="/games/velha/online/salas" element={<HubVelha />} />
                    {/* online */}
                {/* Games */}

                {/* comfigurações */}
                <Route path="/Configurações" element={<Configurações />} />
                {/* comfigurações */}

                {/* Pessoal */}
                <Route path="/Ajenda" element={<Ajenda />} />
                {/* Pessoal */}

                <Route path="/t" element={<T />} />

                {/* Aneoxs */}
                <Route path="/anexos/calc-hid" element={<Calc_Hid/>} />
                {/* Aneoxs */}

            </Routes>
        </BrowserRouter>
    )
}
export default AppRoutes;