import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import NavBar from "../../components/NAV-BAR";
import SideNavBar from "../../components/SIDE-NAV-BAR";
import { db } from "../../config/firebase";
import "./Configurações-style.css"
import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { toast } from "react-toastify";


function Configurações() {

    // sis email
    useEffect(() => {
        const loadProjectDetails = async () => {
            try {
                const projectDoc = doc(db, 'Comfigs', 'Sis_Email');
                const projectSnapshot = await getDoc(projectDoc);
                if (projectSnapshot.exists()) {
                    const projectData = projectSnapshot.data();
                    setEmailSentTogleContato(projectData.Email_Contato);
                }
            } catch (error) {
                toast.error(error);
            }
        };
        loadProjectDetails();
    },);
    // sis email

    // Proximo numero fatura/orçamento
    useEffect(() => {
        const loadProjectDetails = async () => {
            try {
                const projectDoc = doc(db, 'Comfigs', 'NumeroPOr');
                const projectSnapshot = await getDoc(projectDoc);
                if (projectSnapshot.exists()) {
                    const projectData = projectSnapshot.data();
                    setProximoNumeroORFT(projectData.NumeroOr);
                    setProximoNumeroORFTA(projectData.NumeroOr);
                }
            } catch (error) {
                toast.error(error);
            }
        };
        loadProjectDetails();
    }, []);
    // Proximo numero fatura/orçamento

    // Get Email Togle Contato
    const [EmailSentTogleContato, setEmailSentTogleContato] = useState(false);

    const handleToggle = () => {
        setEmailSentTogleContato(!EmailSentTogleContato);
        const TasksRef = doc(db, 'Comfigs', 'Sis_Email');
        try {
            updateDoc(TasksRef, {
                Email_Contato: !EmailSentTogleContato
            }).then(() => {
                toast.success("configuração salva com sucesso!");
            })
        } catch (error) {
            toast.error("Algo deu errado");
        }
    };
    // Get Email Togle Contato

    // Get Email Togle Contato
    const [IsDataChanged, setIsDataChanged] = useState(false)
    const [ProximoNumeroORFT, setProximoNumeroORFT] = useState();
    const [ProximoNumeroORFTA, setProximoNumeroORFTA] = useState();
    const handleNumeroORChange = (e) => {
        setProximoNumeroORFTA(parseInt(e.target.value, 10));
        if (parseInt(e.target.value, 10) === ProximoNumeroORFT) {
            console.log("everdade")
            setIsDataChanged(false)
        } else {
            setIsDataChanged(true); // Indica que houve uma alteração nos dados
        }
    };
    // Get Email Togle Contato

    function salva() {
        const Ref1 = doc(db, 'Comfigs', 'NumeroPOr');
        try {
            updateDoc(Ref1, {
                NumeroOr: ProximoNumeroORFTA
            }).then(() => {
                toast.success("configurações salva com sucesso!", {
                    autoClose: 1500,
                    onClose: window.location.reload()
                });
            })
        } catch (error) {
            toast.error("Algo deu errado");
        }
    }

    // horario

    var [IsDataHChanged, setIsDataHChanged] = useState(false)

    const [Horarios_Velha, setHorarios_Velha] = useState()

    const [Horarios_novo, setHorarios_novo] = useState([
        {
            seg: ["LO", "LO", "LO", "LO", "LO"],
            ter: ["LO", "LO", "LO", "LO", "LO"],
            qua: ["LO", "LO", "LO", "LO", "LO"],
            qui: ["LO", "LO", "LO", "LO", "LO"],
            sex: ["LO", "LO", "LO", "LO", "LO"]
        }
    ])

    useEffect(() => {
        async function gethorario() {
            const Ref1 = doc(db, 'Comfigs', 'Horario');
            try {
                const docSnap = await getDoc(Ref1);
                if (docSnap.exists()) {
                    const horarios = docSnap.data().Horario;
                    setHorarios_Velha(horarios)
                    setHorarios_novo(horarios)
                } else {
                    console.log("Nenhum documento encontrado!");
                }
            } catch (error) {
                console.error("Erro ao ler horário:", error);
            }
        }
        gethorario()
    }, []);

    function Segunda(dia, e) {

        switch (dia) {
            case 0:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            e.target.value,
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 1:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            e.target.value,
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 2:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            e.target.value,
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 3:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            e.target.value,
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 4:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            e.target.value,
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
        }
    }
    function Terça(dia, e) {
        switch (dia) {
            case 0:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            e.target.value,
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 1:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            e.target.value,
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 2:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            e.target.value,
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 3:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            e.target.value,
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 4:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            e.target.value,
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
        }
    }
    function Quarta(dia, e) {
        switch (dia) {
            case 0:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            e.target.value,
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 1:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            e.target.value,
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 2:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            e.target.value,
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 3:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            e.target.value,
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 4:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            e.target.value,
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
        }
    }
    function Quinta(dia, e) {
        switch (dia) {
            case 0:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            e.target.value,
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 1:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            e.target.value,
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 2:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            e.target.value,
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 3:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            e.target.value,
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 4:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            e.target.value,
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
        }
    }
    function Sexta(dia, e) {
        switch (dia) {
            case 0:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            e.target.value,
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 1:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            e.target.value,
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 2:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            e.target.value,
                            Horarios_novo[0].sex[3],
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 3:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            e.target.value,
                            Horarios_novo[0].sex[4]
                        ]
                    }
                ])
                break
            case 4:
                setIsDataHChanged(true)
                setHorarios_novo([
                    {
                        seg: [
                            Horarios_novo[0].seg[0],
                            Horarios_novo[0].seg[1],
                            Horarios_novo[0].seg[2],
                            Horarios_novo[0].seg[3],
                            Horarios_novo[0].seg[4]
                        ],
                        ter: [
                            Horarios_novo[0].ter[0],
                            Horarios_novo[0].ter[1],
                            Horarios_novo[0].ter[2],
                            Horarios_novo[0].ter[3],
                            Horarios_novo[0].ter[4]
                        ],
                        qua: [
                            Horarios_novo[0].qua[0],
                            Horarios_novo[0].qua[1],
                            Horarios_novo[0].qua[2],
                            Horarios_novo[0].qua[3],
                            Horarios_novo[0].qua[4]
                        ],
                        qui: [
                            Horarios_novo[0].qui[0],
                            Horarios_novo[0].qui[1],
                            Horarios_novo[0].qui[2],
                            Horarios_novo[0].qui[3],
                            Horarios_novo[0].qui[4]
                        ],
                        sex: [
                            Horarios_novo[0].sex[0],
                            Horarios_novo[0].sex[1],
                            Horarios_novo[0].sex[2],
                            Horarios_novo[0].sex[3],
                            e.target.value,
                        ]
                    }
                ])
                break
        }
    }

    const opt = [
        { value: "BIO", nome: "BIO" },
        { value: "GEO", nome: "GEO" },
        { value: "SOC", nome: "SOC" },
        { value: "PÓS M", nome: "PÓS M" },
        { value: "ELETIVA", nome: "ELETIVA" },
        { value: "PORT", nome: "PORT" },
        { value: "HIST", nome: "HIST" },
        { value: "QUIM", nome: "QUIM" },
        { value: "TRILHA", nome: "TRILHA" },
        { value: "ES ORI", nome: "ES ORI" },
        { value: "EF", nome: "EF" },
        { value: "MAT", nome: "MAT" },
        { value: "ARTES", nome: "ARTES" },
        { value: "FILO", nome: "FILO" },
        { value: "PV", nome: "PV" },
        { value: "FIS", nome: "FIS" }
    ]

    function Salva_Horario() {
        const Ref1 = doc(db, 'Comfigs', 'Horario');
        try {
            updateDoc(Ref1, {
                Horario: Horarios_novo
            }).then(() => {
                toast.success("configurações salva com sucesso!", {
                    autoClose: 1500,
                });
                setIsDataHChanged(false)
            })
        } catch (error) {
            toast.error("Algo deu errado");
        }
    }

    return (
        <>
            <head>
                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/configurações`} />
                <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="600" />
                <meta property="og:description" content={`Configurações do sistema`} />
                <meta name="description" content="Configurações do sistema" />
            </head>
            <title>KAWHE | CONFIGURAÇÕES</title>
            <NavBar />
            <main>
                <SideNavBar />
                <div className="MainComfigurações">
                    <section className='NavUpLoad'>
                        <h2 className='TxHeaderUpLoad'>Configurações</h2>
                        {
                            IsDataChanged && (
                                <button onClick={salva} className="BtnSalvar" >Salvar</button>
                            )
                        }
                        {
                            IsDataHChanged && (
                                <button onClick={Salva_Horario} className="BtnSalvar" >Salvar</button>
                            )
                        }
                    </section>
                    <div className="LinhaComfig"></div>

                    <table>
                        <thead>
                            <tr>
                                <th colspan="2">Envios de email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><a href="https://kawhealvesdossantos.com.br/contato">https://kawhealvesdossantos.com.br/contato</a></td>
                                <td className="VarialComfig">
                                    <Switch
                                        onChange={handleToggle}
                                        checked={EmailSentTogleContato}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="material-switch"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="LinhaComfig"></div>

                    <table>
                        <thead>
                            <tr>
                                <th colspan="2">Orçamentos / Faturas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Numero da/o proxima/o fatura/orçamento</td>
                                <td className="VarialComfig">
                                    <input type="number" onChange={handleNumeroORChange} value={ProximoNumeroORFTA} />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <thead>
                            <tr>
                                <th colspan="2">Horarios Escolar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Segunda</td>
                                <td className="VarialComfig">
                                    <select onChange={(e) => Segunda(0, e)} name="segunda1" id="seg1" className={`${Horarios_novo[0].seg[0].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].seg[0]}>{Horarios_novo[0].seg[0]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Segunda(1, e)} name="segunda2" id="seg2" className={`${Horarios_novo[0].seg[1].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].seg[1]}>{Horarios_novo[0].seg[1]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Segunda(2, e)} name="segunda3" id="seg3" className={`${Horarios_novo[0].seg[2].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].seg[2]}>{Horarios_novo[0].seg[2]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Segunda(3, e)} name="segunda4" id="seg4" className={`${Horarios_novo[0].seg[3].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].seg[3]}>{Horarios_novo[0].seg[3]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Segunda(4, e)} name="segunda5" id="seg5" className={`${Horarios_novo[0].seg[4].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].seg[4]}>{Horarios_novo[0].seg[4]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Terça</td>
                                <td className="VarialComfig">
                                    <select onChange={(e) => Terça(0, e)} name="terça1" id="ter1" className={`${Horarios_novo[0].ter[0].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].ter[0]}>{Horarios_novo[0].ter[0]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Terça(1, e)} name="terça2" id="ter2" className={`${Horarios_novo[0].ter[1].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].ter[1]}>{Horarios_novo[0].ter[1]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Terça(2, e)} name="terça3" id="ter3" className={`${Horarios_novo[0].ter[2].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].ter[2]}>{Horarios_novo[0].ter[2]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Terça(3, e)} name="terça4" id="ter4" className={`${Horarios_novo[0].ter[3].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].ter[3]}>{Horarios_novo[0].ter[3]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Terça(4, e)} name="terça5" id="ter5" className={`${Horarios_novo[0].ter[4].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].ter[4]}>{Horarios_novo[0].ter[4]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Quarta</td>
                                <td className="VarialComfig">
                                    <select onChange={(e) => Quarta(0, e)} name="" id="" className={`${Horarios_novo[0].qua[0].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qua[0]}>{Horarios_novo[0].qua[0]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Quarta(1, e)} name="" id="" className={`${Horarios_novo[0].qua[1].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qua[1]}>{Horarios_novo[0].qua[1]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Quarta(2, e)} name="" id="" className={`${Horarios_novo[0].qua[2].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qua[2]}>{Horarios_novo[0].qua[2]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Quarta(3, e)} name="" id="" className={`${Horarios_novo[0].qua[3].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qua[3]}>{Horarios_novo[0].qua[3]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Quarta(4, e)} name="" id="" className={`${Horarios_novo[0].qua[4].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qua[4]}>{Horarios_novo[0].qua[4]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Quinta</td>
                                <td className="VarialComfig">
                                    <select onChange={(e) => Quinta(0, e)} name="" id="" className={`${Horarios_novo[0].qui[0].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qui[0]}>{Horarios_novo[0].qui[0]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Quinta(1, e)} name="" id="" className={`${Horarios_novo[0].qui[1].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qui[1]}>{Horarios_novo[0].qui[1]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Quinta(2, e)} name="" id="" className={`${Horarios_novo[0].qui[2].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qui[2]}>{Horarios_novo[0].qui[2]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Quinta(3, e)} name="" id="" className={`${Horarios_novo[0].qui[3].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qui[3]}>{Horarios_novo[0].qui[3]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Quinta(4, e)} name="" id="" className={`${Horarios_novo[0].qui[4].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].qui[4]}>{Horarios_novo[0].qui[4]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Sexta</td>
                                <td className="VarialComfig">
                                    <select onChange={(e) => Sexta(0, e)} name="" id="" className={`${Horarios_novo[0].sex[0].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].sex[0]}>{Horarios_novo[0].sex[0]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Sexta(1, e)} name="" id="" className={`${Horarios_novo[0].sex[1].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].sex[1]}>{Horarios_novo[0].sex[1]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Sexta(2, e)} name="" id="" className={`${Horarios_novo[0].sex[2].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].sex[2]}>{Horarios_novo[0].sex[2]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Sexta(3, e)} name="" id="" className={`${Horarios_novo[0].sex[3].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].sex[3]}>{Horarios_novo[0].sex[3]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e) => Sexta(4, e)} name="" id="" className={`${Horarios_novo[0].sex[4].replace(".", "").replace(" ", "_").replace(".", "")}`}>
                                        {
                                            (
                                                <option value={Horarios_novo[0].sex[4]}>{Horarios_novo[0].sex[4]}</option>
                                            )
                                        }
                                        {opt.map((i) => (
                                            <option value={i.value}>{i.nome}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </main>
        </>
    )
}
export default Configurações;