import './EditarOrçamento-Style.css'
import { useEffect, useRef, useState } from "react";
import { collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../config/firebase";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import emailjs from "@emailjs/browser";
import { Helmet } from 'react-helmet';
import { useParams } from "react-router";

function EditarOrçamento() {

    const IdProjeteRecivie = useParams("")

    const [Orçamentos, setOrçamentos] = useState([])

    const [F, setF] = useState(false)

    const [Produtos, setProdutos] = useState([{ produto: "", descrição: "", preço: "", qt: "", total: 0 }])
    const [Clientes, setClientes] = useState([])

    const [ClienteInfo, setClienteInfo] = useState([])

    const [Title, setTitle] = useState("")
    const [Status, setStatus] = useState("Rascunho")
    const [SelectedClientes, setSelectedClientes] = useState("")
    const [Vencimento, setVencimento] = useState("")
    const [Mensagem, setMensagem] = useState("")
    const [Termos, setTermos] = useState("")
    const [Desconto, setDesconto] = useState(0)
    const [DescontoAmont, setDescontoAmont] = useState(0)
    const [SubTotal, setSubTotal] = useState(0)
    const [Total, setTotal] = useState(0)
    const [Type, setType] = useState("Orçamento")
    const [NOr2, setNOr2] = useState()
    const [NOr, setNOr] = useState("")

    const [PdfURL, setPdfURL] = useState("")

    const data = new Date()

    const [DateEmi, setDateEmi] = useState()

    const divRef = useRef(null);

    const toastId = useRef(null);

    useEffect(() => {
        if (SelectedClientes !== "") {
            const loadProjectDetails = async () => {
                try {
                    const projectDoc = doc(db, 'Clientes', SelectedClientes);
                    const projectSnapshot = await getDoc(projectDoc);
                    if (projectSnapshot.exists()) {
                        const projectData = projectSnapshot.data();
                        setClienteInfo([
                            projectData.NomeCl,
                            projectData.SubNomeCl,
                            projectData.EndRuaCl,
                            projectData.EndBairroCl,
                            projectData.EndCidadeCl,
                            projectData.EndEstadoCl,
                            projectData.EndCepCl,
                            projectData.CellNumCl,
                            projectData.EmailCl,
                        ]);
                    } else {
                        alert("nao foi possiel achar: " + SelectedClientes)
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            loadProjectDetails();
        }
    }, [SelectedClientes]);

    useEffect(() => {
        if (IdProjeteRecivie.N !== "") {
            const loadProjectDetails = async () => {
                try {
                    const projectDoc = doc(db, 'Orçamentos', IdProjeteRecivie.N);
                    const projectSnapshot = await getDoc(projectDoc);
                    if (projectSnapshot.exists()) {
                        const projectData = projectSnapshot.data();
                        setOrçamentos([
                            projectData.Status,
                            projectData.Id_Cliente,
                            projectData.Produtos,
                            projectData.Total,
                            projectData.SubTotal,
                            projectData.Desconto_Per,
                            projectData.Desconto_Am,
                            projectData.Termos,
                            projectData.Mensagem,
                            projectData.Vencimento,
                            projectData.Title,
                            projectData.CreatAt,
                            projectData.N_Or,
                            projectData.Type,
                            projectData.Nome_Cliente,
                            projectData.Email_Cl,
                            projectData.CreatAt,
                            projectData.Link_Jpeg,
                        ]);
                    } else {
                        toast.error(IdProjeteRecivie.N + "Nao existe", {
                            onClose: () => window.location.href = "https://kawhealvesdossantos.com.br"
                        })
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            loadProjectDetails();
        }
    }, [IdProjeteRecivie.N]);

    useEffect(() => {
        LoadInfo()
    }, [Orçamentos])

    function LoadInfo() {
        setStatus(Orçamentos[0]);
        setSelectedClientes(Orçamentos[1])
        setProdutos(Orçamentos[2])
        setTotal(Orçamentos[3])
        setSubTotal(Orçamentos[4])
        setDesconto(Orçamentos[5])
        setDescontoAmont(Orçamentos[6])
        setTermos(Orçamentos[7])
        setMensagem(Orçamentos[8])
        setVencimento(Orçamentos[9])
        setTitle(Orçamentos[10])
        setDateEmi(Orçamentos[11])
        setNOr(Orçamentos[12])
        setType(Orçamentos[13])
        setDateEmi(Orçamentos[16])
    }

    function AceitarOr() {

        setStatus("Faturada");
        toastId.current = toast("Faturando", {
            autoClose: false,
            closeButton: false
        });

        setTimeout(() => {
            const divToExport = document.querySelector('.PreviwOr');

            if (divToExport) {
                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const scale = "6";

                html2canvas(divToExport, { scale: scale }).then((canvas) => {
                    const dataURL = canvas.toDataURL('image/jpeg');
                    const imgWidth = pdfWidth;
                    const imgHeight = (imgWidth * divToExport.offsetHeight) / divToExport.offsetWidth;
                    pdf.addImage(dataURL, 'JPEG', 0, 0, imgWidth, imgHeight);
                    const pdfData = pdf.output('blob');
                    const filename = ("Fatura-" + Orçamentos[12] + ".pdf");
                    const storageRef = ref(storage, "Faturas/PDF/" + filename);
                    uploadBytes(storageRef, pdfData).then((snapshot) => {
                        console.log('PDF enviado com sucesso para o Firebase Storage:', snapshot);
                        return getDownloadURL(storageRef);
                    }).then((url) => {
                        setPdfURL(url);
                        const TasksRef = doc(db, 'Faturas', Orçamentos[12]);
                        try {
                            setDoc(TasksRef, {
                                Email_Cl: Orçamentos[15],
                                Link_Pdf: url,
                                Id_Cliente: Orçamentos[1],
                                Nome_Cliente: Orçamentos[14],
                                N_Or: Orçamentos[12],
                                Produtos: Orçamentos[2],
                                Title: Orçamentos[10],
                                Status: "Faturada",
                                Total: Orçamentos[3],
                                SubTotal: Orçamentos[4],
                                Vencimento: Orçamentos[9],
                                Mensagem: Orçamentos[8],
                                Termos: Orçamentos[7],
                                Desconto_Per: Orçamentos[5],
                                Desconto_Am: Orçamentos[6],
                                CreatAt: Orçamentos[16],
                                Link_Jpeg: "",
                            }).then(() => {
                                html2canvas(divToExport, { scale: scale }).then((canvas) => {
                                    const dataURL = canvas.toDataURL('image/png');
                                    const filename = ("Fatura-" + Orçamentos[12] + ".png");
                                    const storageRef = ref(storage, "Faturas/Image/" + filename);
                                    uploadBytes(storageRef, dataURLtoBlob(dataURL)).then((snapshot) => {
                                        return getDownloadURL(storageRef)
                                            .then((url) => {
                                                console.log(url)
                                                var templateParams = {
                                                    LINK_OR: url,
                                                    N_CL: Orçamentos[14],
                                                    TO: Orçamentos[15],
                                                    MSG: "Nova Fatura"
                                                };
                                                emailjs.send('service_btvbj1e', 'template_m0lp0zs', templateParams, "bTup3YaphfZrsBv1X")
                                                    .then(function (response) {
                                                        if (response.status === 200) {
                                                            const TasksRef3 = doc(db, 'Faturas', Orçamentos[12]);
                                                            try {
                                                                updateDoc(TasksRef3, {
                                                                    Link_Jpeg: url,
                                                                })
                                                            } catch (e) {
                                                                toast.update(toastId.current, {
                                                                    render: (`Error inesperado 1: ${e}`),
                                                                    type: toast.TYPE.ERROR,
                                                                    autoClose: 5000,
                                                                    closeButton: null,
                                                                });
                                                            }
                                                            const filename1 = ("Orçamento-" + Orçamentos[12] + ".png");
                                                            const storageRef1 = ref(storage, "Orçamentos/Image/" + filename1);
                                                            const filename2 = ("Orçamento-" + Orçamentos[12] + ".pdf");
                                                            const storageRef2 = ref(storage, "Orçamentos/PDF/" + filename2);
                                                            const TasksRef360 = doc(db, 'Orçamentos', Orçamentos[12]);
                                                            deleteObject(storageRef1).then(() => {
                                                                deleteObject(storageRef2).then(() => {
                                                                    deleteDoc(TasksRef360).then(() => {
                                                                        toast.update(toastId.current, {
                                                                            render: "Faturado com sucesso, um email com a fatura foi enviada",
                                                                            type: toast.TYPE.SUCCESS,
                                                                            autoClose: 5000,
                                                                        });
                                                                    }).catch(error => {
                                                                        toast.update(toastId.current, {
                                                                            render: (`Error inesperado 1: ${error}`),
                                                                            type: toast.TYPE.ERROR,
                                                                            autoClose: 5000,
                                                                            closeButton: null,
                                                                        });
                                                                    })
                                                                }).catch(error => {
                                                                    toast.update(toastId.current, {
                                                                        render: (`Error inesperado 2: ${error}`),
                                                                        type: toast.TYPE.ERROR,
                                                                        autoClose: 5000,
                                                                        closeButton: null,
                                                                    });
                                                                })
                                                            }).catch(error => {
                                                                toast.update(toastId.current, {
                                                                    render: (`Error inesperado 3: ${error}`),
                                                                    type: toast.TYPE.ERROR,
                                                                    autoClose: 5000,
                                                                    closeButton: null,
                                                                });
                                                            })

                                                        }
                                                    }, (e) => {
                                                        toast.update(toastId.current, {
                                                            render: (`Error inesperado 4: ${e}`),
                                                            type: toast.TYPE.ERROR,
                                                            autoClose: 5000,
                                                            closeButton: null,
                                                        });
                                                    });
                                            }).catch((e) => {
                                                toast.update(toastId.current, {
                                                    render: (`Error inesperado 5: ${e}`),
                                                    type: toast.TYPE.ERROR,
                                                    autoClose: 5000,
                                                    closeButton: null,
                                                });
                                            });

                                    }).catch((e) => {
                                        toast.update(toastId.current, {
                                            render: (`Error inesperado 6: ${e}`),
                                            type: toast.TYPE.ERROR,
                                            autoClose: 5000,
                                            closeButton: null,
                                        });
                                    });
                                });
                            })
                        } catch (e) {
                            toast.update(toastId.current, {
                                render: (`Error inesperado 7: ${e}`),
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                closeButton: null,
                            });
                        }
                    }).catch((e) => {
                        toast.update(toastId.current, {
                            render: (`Error inesperado 8: ${e}`),
                            type: toast.TYPE.ERROR,
                            autoClose: 5000,
                            closeButton: null,
                        });
                        console.log(e)
                    });
                });
            } else {
                setStatus(Orçamentos[0])
                toast.error(`A div com a classe 'PreviwOr' não foi encontrada.`);
            }

            // Função auxiliar para converter dataURL em Blob
            function dataURLtoBlob(dataURL) {
                const arr = dataURL.split(',');
                const mime = arr[0].match(/:(.*?);/)[1];
                const bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            }
        }, 3000)
    }

    return (
        <>
            <head>

                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/orçamentos/${NOr}`} />
                <meta property="og:image" content={Orçamentos[17]} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:description" content={`Editar - Orçamento-${NOr}`} />
                <meta name="description" content={`Editar - Orçamento-${NOr}`} />
            </head>

            <title>KAWHE | EDITAR ORÇAMENTO | {NOr}</title>

            <main className="MainAceitarOr">

                <section>
                    <button>Abaixar pdf</button>
                    <button onClick={() => AceitarOr()}>Aceitar Orçamento</button>
                </section>

                <section ref={divRef} className="PreviwOr ViwOrAceitarOr">
                    {
                        Orçamentos.length < 0 ?
                            (
                                <div className="PreviwOr">
                                    Carregando
                                </div>

                            ) : ""
                    }
                    <section>
                        <div className="PreviewHeader">
                            <div className="PreviewHeader1">
                                <div className="Logo">
                                    <img className="LogoImg" src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/favicon.png?alt=media&token=9859bd8c-6246-496f-9e2c-4deb450d85ee" alt="Logo" />
                                </div>
                                <div className="PerInfo">
                                    <p className="MyName">KAWHE ALVES DOS SANTOS</p>
                                    <p>Brazil</p>
                                    <p>cantato@kawhealvesdossantos.com.br</p>
                                    <p>Telefone: +55 69 9 93172767</p>
                                </div>
                            </div>
                            <div className="InfoPreviwOr">
                                <p
                                    className={`StatusOr  
                                            ${Status === "Enviada" ? 'STEnviada'
                                            :
                                            Status === "Rascunho" ? "STRascunho"
                                                :
                                                Status === "Faturada" ? "STEnviada"
                                                    :
                                                    ""
                                        }`}>{Status}</p>
                                <p>{Type} Nº: {NOr}</p>
                                <p>Emitida em: {DateEmi}</p>
                                <p>Data de vencimento: {Vencimento}</p>
                            </div>
                        </div>
                        <div className="DTitleOr">
                            <p>{Title}</p>
                        </div>
                        <div className="InfoCl">
                            <p className="InfoClp1">{ClienteInfo[0] + " " + ClienteInfo[1]}</p>
                            <p className="InfoClp2">{ClienteInfo[2] + " " + ClienteInfo[3]}</p>
                            <p className="InfoClp3">{ClienteInfo[4] + " " + ClienteInfo[5] + " " + ClienteInfo[6]}</p>
                            <p className="InfoClp4">{ClienteInfo[7]}</p>
                        </div>
                    </section>
                    <section>
                        <div className="HeaderOrPreços">
                            <p>Serviço</p>
                            <p>Preço (R$)</p>
                            <p>Quantidade</p>
                            <p>Total</p>
                        </div>
                        {Produtos &&
                            Produtos.map((p, index) => (
                                <div key={index} className="ListPrOr">
                                    <div className="Pr Cw">
                                        <p className="PrP">{p.produto}</p>
                                        <p className="PrC">{p.descrição}</p>
                                    </div>
                                    <p className="Cw">{p.preço}</p>
                                    <p className="Cw">{p.qt}</p>
                                    <p className="Cw">{p.total}</p>
                                </div>
                            ))
                        }
                        <div className="LinePreviewOr"></div>
                    </section>
                    <section className="TotaisPreviwOr">
                        <div className="DTotaisPreviwOr">
                            <div>
                                <p>SubTotal</p>
                                <p>{SubTotal}</p>
                            </div>
                            {Desconto !== 0 &&
                                <div>
                                    <p>Desconto</p>
                                    <p>{Desconto}%</p>
                                    <p>{DescontoAmont}</p>
                                </div>
                            }
                            <div className="DTotalOr">
                                <p>Total {F ? "a pagar" : ""}</p>
                                <p>{Total}</p>
                            </div>
                        </div>
                    </section>
                    <section className="MensagensPreviewOr">
                        <div>
                            {Mensagem !== "" &&
                                <div className="DMensagenOr">
                                    <p>Mensagens:</p>
                                    <div className="DMSGOR">
                                        <p className="MSGOR">{Mensagem}</p>
                                    </div>
                                </div>
                            }

                            {Termos !== "" &&
                                <div>
                                    <p>Termos Legais:</p>
                                    <div className="DTROR">
                                        <p>{Termos}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                </section>
            </main>
        </>
    )
}
export default EditarOrçamento;