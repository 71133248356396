import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoutes from './routes/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

// var userAgent = navigator.userAgent;
// var platform = navigator.platform;
// var geo = navigator.geolocation;
// var ink = navigator.ink;

// console.log("User Agent: " + userAgent);
// console.log("Platform: " + platform);
// console.log("geo: " + geo);
// console.log("ink: " + ink);

root.render(
  <React.StrictMode>
    <AppRoutes />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  </React.StrictMode>
);