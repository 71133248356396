import { useEffect, useRef, useState } from "react";
import NavBar from "../../components/NAV-BAR";
import SideNavBar from "../../components/SIDE-NAV-BAR";
import "./Faturas-style.css"
import { collection, deleteDoc, doc, getDoc, onSnapshot, query, updateDoc } from "firebase/firestore";
import { db, storage } from "../../config/firebase";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { deleteObject, getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useParams } from "react-router-dom";

function Faturas() {

    const toastId = useRef(null);
    const divRef = useRef(null);

    const [ClienteInfo, setClienteInfo] = useState([])
    const [SelectedClientes, setSelectedClientes] = useState("")
    const [Status, setStatus] = useState("Rascunho")
    const [isPreviewVisible, setPreviewVisible] = useState(false)
    const [isPreviewPagar, setPreviewPagar] = useState(false)

    const [Title, setTitle] = useState("")
    const [Vencimento, setVencimento] = useState("")
    const [Mensagem, setMensagem] = useState("")
    const [Termos, setTermos] = useState("")
    const [Desconto, setDesconto] = useState(0)
    const [DescontoAmont, setDescontoAmont] = useState(0)
    const [SubTotal, setSubTotal] = useState(0)
    const [Total, setTotal] = useState(0)
    const [Produtos, setProdutos] = useState([])
    const [DateEmi, setDateEmi] = useState("")
    const [NOr, setNOr] = useState("")
    const [Type, setType] = useState("")
    const [ValorPago, setValorPago] = useState(0)
    const [ValorAPagar, setValorAPagar] = useState(0)
    const [Logo] = useState("https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/favicon.png?alt=media&token=9859bd8c-6246-496f-9e2c-4deb450d85ee")

    const [SelectOR, setSelectOR] = useState("")



    useEffect(() => {
        if (SelectedClientes !== "") {
            const loadProjectDetails = async () => {
                try {
                    const projectDoc = doc(db, 'Clientes', SelectedClientes);
                    const projectSnapshot = await getDoc(projectDoc);
                    if (projectSnapshot.exists()) {
                        const projectData = projectSnapshot.data();
                        setClienteInfo([
                            projectData.NomeCl,
                            projectData.SubNomeCl,
                            projectData.EndRuaCl,
                            projectData.EndBairroCl,
                            projectData.EndCidadeCl,
                            projectData.EndEstadoCl,
                            projectData.EndCepCl,
                            projectData.CellNumCl,
                            projectData.EmailCl
                        ]);
                    } else {
                        toast.error("nao foi possiel achar: " + SelectedClientes)
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            loadProjectDetails();
        }
    }, [SelectedClientes]);

    function GoToAddOr() {
        window.location.href = "/crm/Faturas/add"
    }

    const [Orçamentos, setOrçamentos] = useState([])

    useEffect(() => {
        const q = query(collection(db, "Faturas/"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setOrçamentos(todosArr);
        });
        return () => unsubscribe();
    }, []);

    const [optVisibleIndex, setOptVisibleIndex] = useState(null);

    function TogleOPTBox(index, N) {
        setSelectOR(N)
        if (optVisibleIndex === index) {
            setOptVisibleIndex(null);
        } else {
            setOptVisibleIndex(index);
        }
    };

    function Reenviar(N) {

        toastId.current = toast("Enviando Pdf", {
            autoClose: false,
            closeButton: false
        });
        let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === SelectOR; });

        var templateParams = {
            LINK_OR: F1[0].Link_Jpeg,
            N_CL: F1[0].Nome_Cliente,
            TO: F1[0].Email_Cl,
            MSG: "Nova Fatura"
        };
        emailjs.send('service_btvbj1e', 'template_m0lp0zs', templateParams, "bTup3YaphfZrsBv1X")
            .then(function (response) {
                if (response.status === 200) {
                    toast.update(toastId.current, {
                        render: "Enviado com sucesso",
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        closeButton: null,
                    });
                }
            }, (e) => {
                toast.update(toastId.current, {
                    render: (`Error inesperado: ${e}`),
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    closeButton: null,
                });
            });
    }

    function AbaixarPDF(N) {
        let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === SelectOR; });
        const pdfUrl = F1[0].Link_Pdf;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = (`FATURA-${F1[0].N_Or}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function Excluir(N) {
        confirmAlert({
            title: "Deseja mesmo deletar este orçamento",
            message: "Este ação não tem volta",
            buttons: [
                {
                    label: "Sim",
                    onClick: () => EX()
                },
                {
                    label: "Não",
                    onClick: setOptVisibleIndex(null)
                }
            ]
        });
        function EX() {

            toastId.current = toast("Deletando Fatura", {
                autoClose: false,
                closeButton: false
            });

            const filename1 = ("Fatura-" + SelectOR + ".png");
            const storageRef1 = ref(storage, "Faturas/Image/" + filename1);
            const filename2 = ("Fatura-" + SelectOR + ".pdf");
            const storageRef2 = ref(storage, "Faturas/PDF/" + filename2);
            const TasksRef = doc(db, 'Faturas', SelectOR);
            deleteObject(storageRef1).then(() => {
                deleteObject(storageRef2).then(() => {
                    deleteDoc(TasksRef).then(() => {
                        toast.update(toastId.current, {
                            render: "Fatura deletado com sucesso",
                            type: toast.TYPE.SUCCESS,
                            autoClose: 5000,
                            closeButton: null,

                        });
                    }).catch(error => {
                        toast.update(toastId.current, {
                            render: (`Error inesperado: ${error}`),
                            type: toast.TYPE.ERROR,
                            autoClose: 5000,
                            closeButton: null,
                        });
                    })
                }).catch(error => {
                    toast.update(toastId.current, {
                        render: (`Error inesperado: ${error}`),
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        closeButton: null,
                    });
                })
            }).catch(error => {
                toast.update(toastId.current, {
                    render: (`Error inesperado: ${error}`),
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    closeButton: null,
                });
            })

        }
    }

    const [ValorNaN, setValorNaN] = useState()
    const Valor = parseFloat(ValorNaN);
    const [Data, setData] = useState()


    function CarregarInfos() {
        let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === SelectOR; });
        setTitle(F1[0].Title)
        setVencimento(F1[0].Vencimento)
        setMensagem(F1[0].Mensagem)
        setTermos(F1[0].Termos)
        setDesconto(F1[0].Desconto_Per)
        setDescontoAmont(F1[0].Desconto_Am)
        setSubTotal(F1[0].SubTotal)
        setTotal(F1[0].Total)
        setProdutos(F1[0].Produtos)
        setDateEmi(F1[0].CreatAt)
        setNOr(F1[0].N_Or)
        setStatus(F1[0].Status)
        setValorPago(parseFloat(F1[0].ValorPago))
        setValorAPagar(F1[0].ValorAPagar)
    }

    function CleanInfo() {
        setTitle()
        setVencimento()
        setMensagem()
        setTermos()
        setDesconto()
        setDescontoAmont()
        setSubTotal()
        setTotal()
        setProdutos()
        setDateEmi()
        setNOr()
        setStatus()
        setValorPago()
        setValorAPagar()
    }

    function Pagar(N) {
        let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === SelectOR; });
        CarregarInfos()
        setPreviewPagar(true)
        setOptVisibleIndex(null);
        setSelectedClientes(F1[0].Id_Cliente)
    }

    function SalvarPagamento() {
        if (Valor > ValorAPagar) {
            toast.error("O valor do pagamento não poder ser maior que o valor a pagar")
        } else {
            toastId.current = toast("Salvando pagamento", {
                autoClose: false,
                closeButton: false,
                onOpen: setPreviewPagar(false)
            });
            let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === SelectOR; });
            setPreviewVisible(true)
            try {
                if (ValorPago + Valor < Total) {
                    setTimeout(() => {
                        const divToExport = document.querySelector('.PreviwOr');
                        if (divToExport) {
                            const pdf = new jsPDF('p', 'mm', 'a4');
                            const pdfWidth = pdf.internal.pageSize.getWidth();
                            const scale = "6";
                            html2canvas(divToExport, { scale: scale }).then((canvas) => {
                                const dataURL = canvas.toDataURL('image/jpeg');
                                const imgWidth = pdfWidth;
                                const imgHeight = (imgWidth * divToExport.offsetHeight) / divToExport.offsetWidth;
                                pdf.addImage(dataURL, 'JPEG', 0, 0, imgWidth, imgHeight);
                                const pdfData = pdf.output('blob');
                                const filename = ("Fatura-" + F1[0].N_Or + ".pdf");
                                const storageRef = ref(storage, "Faturas/PDF/" + filename);
                                uploadBytes(storageRef, pdfData).then((snapshot) => {
                                    return getDownloadURL(storageRef);
                                }).then((url) => {
                                    const TasksRef = doc(db, 'Faturas', F1[0].N_Or);
                                    try {
                                        updateDoc(TasksRef, {
                                            Link_Pdf: url,
                                            ValorAPagar: Total - ValorPago - Valor,
                                            ValorPago: ValorPago + Valor,
                                        }).then(() => {
                                            html2canvas(divToExport, { scale: scale }).then((canvas) => {
                                                const dataURL = canvas.toDataURL('image/png');
                                                const filename = ("Fatura-" + F1[0].N_Or + ".png");
                                                const storageRef = ref(storage, "Faturas/Image/" + filename);
                                                uploadBytes(storageRef, dataURLtoBlob(dataURL)).then((snapshot) => {
                                                    return getDownloadURL(storageRef)
                                                        .then((url) => {
                                                            var templateParams = {
                                                                LINK_OR: url,
                                                                N_CL: ClienteInfo[0] + " " + ClienteInfo[1],
                                                                TO: ClienteInfo[8],
                                                                MSG: "Atualização da fatura",
                                                                Body: `
                                                            =============================================
                
                                                            como mostrado acima voçe fez o pagamento de apenas uma parta do valor total
                                                            ainda restão ${Total - ValorPago - Valor} para pagar
                
                                                            =============================================
                
                                                            Pix: 
                                                            Kawhe alves dos santos
                                                            Email: kawhealvesdossantos@gmail.com
                
                                                            =============================================
                                                        `
                                                            };
                                                            emailjs.send('service_btvbj1e', 'template_m0lp0zs', templateParams, "bTup3YaphfZrsBv1X")
                                                                .then(function (response) {
                                                                    if (response.status === 200) {
                                                                        const TasksRef3 = doc(db, 'Faturas', F1[0].N_Or);
                                                                        try {
                                                                            updateDoc(TasksRef3, {
                                                                                Status: "Faturada",
                                                                                Link_Jpeg: url,
                                                                            })
                                                                        } catch (e) {
                                                                            toast.update(toastId.current, {
                                                                                render: (`Error inesperado 1: ${e}`),
                                                                                type: toast.TYPE.ERROR,
                                                                                autoClose: 5000,
                                                                                closeButton: null,
                                                                            });
                                                                        }
                                                                        toast.update(toastId.current, {
                                                                            render: "Salvo com sucesso",
                                                                            type: toast.TYPE.SUCCESS,
                                                                            autoClose: 5000,
                                                                            closeButton: null,
                                                                            onOpen: setPreviewVisible(false) + CleanInfo()
                                                                        });
                                                                    }
                                                                }, (e) => {
                                                                    toast.update(toastId.current, {
                                                                        render: (`Error inesperado 2: ${e}`),
                                                                        type: toast.TYPE.ERROR,
                                                                        autoClose: 5000,
                                                                        closeButton: null,
                                                                    });
                                                                });
                                                        }).catch((e) => {
                                                            toast.update(toastId.current, {
                                                                render: (`Error inesperado 3: ${e}`),
                                                                type: toast.TYPE.ERROR,
                                                                autoClose: 5000,
                                                                closeButton: null,
                                                            });
                                                        });

                                                }).catch((e) => {
                                                    toast.update(toastId.current, {
                                                        render: (`Error inesperado 4: ${e}`),
                                                        type: toast.TYPE.ERROR,
                                                        autoClose: 5000,
                                                        closeButton: null,
                                                    });
                                                });
                                            });
                                        })
                                    } catch (e) {
                                        toast.update(toastId.current, {
                                            render: (`Error inesperado 5: ${e}`),
                                            type: toast.TYPE.ERROR,
                                            autoClose: 5000,
                                            closeButton: null,
                                        });
                                    }
                                }).catch((e) => {
                                    toast.update(toastId.current, {
                                        render: (`Error inesperado 6: ${e}`),
                                        type: toast.TYPE.ERROR,
                                        autoClose: 5000,
                                        closeButton: null,
                                    });
                                });
                            });
                        } else {
                            setStatus("Rascunho")
                            toast.error(`A div com a classe 'PreviwOr' não foi encontrada.`);
                        }
                    }, 2000);
                } else {
                    setStatus("Pago")
                    setTimeout(() => {
                        const divToExport = document.querySelector('.PreviwOr');
                        if (divToExport) {
                            const pdf = new jsPDF('p', 'mm', 'a4');
                            const pdfWidth = pdf.internal.pageSize.getWidth();
                            const scale = "6";
                            html2canvas(divToExport, { scale: scale }).then((canvas) => {
                                const dataURL = canvas.toDataURL('image/jpeg');
                                const imgWidth = pdfWidth;
                                const imgHeight = (imgWidth * divToExport.offsetHeight) / divToExport.offsetWidth;
                                pdf.addImage(dataURL, 'JPEG', 0, 0, imgWidth, imgHeight);
                                const pdfData = pdf.output('blob');
                                const filename = ("Fatura-" + F1[0].N_Or + ".pdf");
                                const storageRef = ref(storage, "Faturas/PDF/" + filename);
                                uploadBytes(storageRef, pdfData).then((snapshot) => {
                                    return getDownloadURL(storageRef);
                                }).then((url) => {
                                    const TasksRef = doc(db, 'Faturas', F1[0].N_Or);
                                    try {
                                        updateDoc(TasksRef, {
                                            Link_Pdf: url,
                                            Status: "Pago",
                                            ValorAPagar: Total - ValorPago - Valor,
                                            ValorPago: ValorPago + Valor,
                                        }).then(() => {
                                            html2canvas(divToExport, { scale: scale }).then((canvas) => {
                                                const dataURL = canvas.toDataURL('image/png');
                                                const filename = ("Fatura-" + F1[0].N_Or + ".png");
                                                const storageRef = ref(storage, "Faturas/Image/" + filename);
                                                uploadBytes(storageRef, dataURLtoBlob(dataURL)).then((snapshot) => {
                                                    return getDownloadURL(storageRef)
                                                        .then((url) => {
                                                            var templateParams = {
                                                                LINK_OR: url,
                                                                N_CL: ClienteInfo[0] + " " + ClienteInfo[1],
                                                                TO: ClienteInfo[8],
                                                                MSG: "Atualização da fatura",
                                                                Body: `
                                                            =============================================
                                                        
                                                            Parabéns!!
                
                                                            Pagamento confirmado
                                                            
                                                            =============================================
                                                        `
                                                            };
                                                            emailjs.send('service_btvbj1e', 'template_m0lp0zs', templateParams, "bTup3YaphfZrsBv1X")
                                                                .then(function (response) {
                                                                    if (response.status === 200) {
                                                                        const TasksRef3 = doc(db, 'Faturas', F1[0].N_Or);
                                                                        try {
                                                                            updateDoc(TasksRef3, {
                                                                                Status: "Faturada",
                                                                                Link_Jpeg: url,
                                                                            })
                                                                        } catch (e) {
                                                                            toast.update(toastId.current, {
                                                                                render: (`Error inesperado 1: ${e}`),
                                                                                type: toast.TYPE.ERROR,
                                                                                autoClose: 5000,
                                                                                closeButton: null,
                                                                            });
                                                                        }
                                                                        toast.update(toastId.current, {
                                                                            render: "Salvo com sucesso",
                                                                            type: toast.TYPE.SUCCESS,
                                                                            autoClose: 5000,
                                                                            closeButton: null,
                                                                            onOpen: setPreviewVisible(false) + CleanInfo()
                                                                        });
                                                                    }
                                                                }, (e) => {
                                                                    toast.update(toastId.current, {
                                                                        render: (`Error inesperado 2: ${e}`),
                                                                        type: toast.TYPE.ERROR,
                                                                        autoClose: 5000,
                                                                        closeButton: null,
                                                                    });
                                                                });
                                                        }).catch((e) => {
                                                            toast.update(toastId.current, {
                                                                render: (`Error inesperado 3: ${e}`),
                                                                type: toast.TYPE.ERROR,
                                                                autoClose: 5000,
                                                                closeButton: null,
                                                            });
                                                        });

                                                }).catch((e) => {
                                                    toast.update(toastId.current, {
                                                        render: (`Error inesperado 4: ${e}`),
                                                        type: toast.TYPE.ERROR,
                                                        autoClose: 5000,
                                                        closeButton: null,
                                                    });
                                                    console.log(e)
                                                });
                                            });
                                        })
                                    } catch (e) {
                                        toast.update(toastId.current, {
                                            render: (`Error inesperado 5: ${e}`),
                                            type: toast.TYPE.ERROR,
                                            autoClose: 5000,
                                            closeButton: null,
                                        });
                                    }
                                }).catch((e) => {
                                    toast.update(toastId.current, {
                                        render: (`Error inesperado 6: ${e}`),
                                        type: toast.TYPE.ERROR,
                                        autoClose: 5000,
                                        closeButton: null,
                                    });
                                });
                            });
                        } else {
                            setStatus("Rascunho")
                            toast.error(`A div com a classe 'PreviwOr' não foi encontrada.`);
                        }
                    }, 2000);
                }
            } catch (e) {
                toast.update(toastId.current, {
                    render: (`Error inesperado 5: ${e}`),
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    closeButton: null,
                });
            }
        }
        function dataURLtoBlob(dataURL) {
            const arr = dataURL.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
    }

    document.addEventListener("keydown", (event) => {
        if (event.keyCode === 27) {
            setOptVisibleIndex(null);
        }
    })

    const HList = 50 + Orçamentos.length * 112

    return (
        <>
            <head>

                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/faturas`} />
                <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="600" />
                <meta property="og:description" content={`Vizualizar todas faturas`} />
                <meta name="description" content="Vizualizar todas faturas" />
            </head>

            <title>KAWHE | FATURAS</title>

            <NavBar />
            <main>
                <SideNavBar />
                <div className='MainOrçamentos'>
                    <section className='NavUpLoad'>
                        <h2 className='TxHeaderUpLoad'>Faturas <span className='QtnArquivos'>{Orçamentos.length}</span></h2>
                        <button className='BtnAddUpLoad' onClick={GoToAddOr} ><i class="fa-solid fa-plus fa-plus-pj"></i>Adicionar Faturas</button>
                    </section>

                    <section className='ListaFaturas' style={{ minHeight: `${HList}px` }}>
                        <tr className="CabecalhoFat">
                            <th>Número</th>
                            <th>Cliente</th>
                            <th>Preço total</th>
                            <th>status</th>
                            <th>Opções</th>
                        </tr>
                        {
                            Orçamentos
                                ?
                                Orçamentos.map((i, index) => (
                                    <div key={index} className="TR_OR2">
                                        <div className="Line_OR"></div>
                                        <tr className="TR_or">
                                            <td><a href={`/crm/Faturas/${i.N_Or}`} className="TR-OR  Ft">{i.N_Or}</a></td>
                                            <td>{i.Nome_Cliente}</td>
                                            <td>R$ {i.Total}</td>
                                            <td>{i.Status}</td>
                                            <td>
                                                <div className={`OPT OPT${index}`} style={{ display: optVisibleIndex === index ? 'block' : 'none' }}>
                                                    <button onClick={() => Reenviar(i.N_Or)}>Reenviar</button>
                                                    <button onClick={() => AbaixarPDF(i.N_Or)}>Abaixar PDF</button>
                                                    <button onClick={() => Excluir(i.N_Or)}>Excluir</button>
                                                    <button onClick={() => Pagar(i.N_Or)}>Registra pagamento</button>
                                                </div>
                                                <button className="BTN_OPT_OR" onClick={() => TogleOPTBox(index, i.N_Or)}>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                </button>
                                            </td>
                                        </tr>
                                    </div>
                                ))
                                :
                                <tr>
                                    <td>
                                        <svg className='Loading'
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="3em"
                                            viewBox="0 0 512 512">
                                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                                        </svg>
                                    </td>
                                </tr>
                        }
                    </section>

                </div>

                {isPreviewVisible && (
                    <div className="PreviewOrçamento">
                        <div ref={divRef} className="PreviwOr">
                            <section>
                                <div className="PreviewHeader">
                                    <div className="PreviewHeader1">
                                        <div className="Logo">
                                            <img className="LogoImg" src={Logo} alt="Logo" />
                                        </div>
                                        <div className="PerInfo">
                                            <p className="MyName">KAWHE ALVES DOS SANTOS</p>
                                            <p>Brazil</p>
                                            <p>cantato@kawhealvesdossantos.com.br</p>
                                            <p>Telefone: +55 69 9 93172767</p>
                                        </div>
                                    </div>
                                    <div className="InfoPreviwOr">
                                        <p
                                            className={`StatusOr  
                                            ${Status === "Enviada" ? 'STEnviada'
                                                    :
                                                    Status === "Rascunho" ? "STRascunho"
                                                        :
                                                        Status === "Faturada" ? "STEnviada"
                                                            :
                                                            Status === "Pago" ? "STEnviada"
                                                                :
                                                                ""
                                                }`}>{Status}</p>
                                        <p>{Type} Nº: {NOr}</p>
                                        <p>Emitida em: {DateEmi}</p>
                                        <p>Data de vencimento: {Vencimento}</p>
                                    </div>
                                </div>
                                <div className="DTitleOr">
                                    <p>{Title}</p>
                                </div>
                                <div className="InfoCl">
                                    <p className="InfoClp1">{ClienteInfo[0] + " " + ClienteInfo[1]}</p>
                                    <p className="InfoClp2">{ClienteInfo[2] + " " + ClienteInfo[3]}</p>
                                    <p className="InfoClp3">{ClienteInfo[4] + " " + ClienteInfo[5] + " " + ClienteInfo[6]}</p>
                                    <p className="InfoClp4">{ClienteInfo[7]}</p>
                                </div>
                            </section>
                            <section>
                                <div className="HeaderOrPreços">
                                    <p>Serviço</p>
                                    <p>Preço (R$)</p>
                                    <p>Quantidade</p>
                                    <p>Total</p>
                                </div>
                                {Produtos &&
                                    Produtos.map((p, index) => (
                                        <div key={index} className="ListPrOr">
                                            <div className="Pr Cw">
                                                <p className="PrP">{p.produto}</p>
                                                <p className="PrC">{p.descrição}</p>
                                            </div>
                                            <p className="Cw">{p.preço}</p>
                                            <p className="Cw">{p.qt}</p>
                                            <p className="Cw">{p.total}</p>
                                        </div>
                                    ))
                                }
                                <div className="LinePreviewOr"></div>
                            </section>
                            <section className="TotaisPreviwOr">
                                <div className="DTotaisPreviwOr">
                                    <div>
                                        <p>SubTotal</p>
                                        <p>{SubTotal}</p>
                                    </div>
                                    {Desconto !== 0 &&
                                        <div>
                                            <p>Desconto</p>
                                            <p>{Desconto}%</p>
                                            <p>{DescontoAmont.toFixed(2)}</p>
                                        </div>
                                    }
                                    <div className="DTotalOr">
                                        <p>Total</p>
                                        <p>{Total}</p>
                                    </div>
                                    <div className="DTotalOr">
                                        <p>Valor pago</p>
                                        <p>{ValorPago + Valor}</p>
                                    </div>
                                    <div className="DTotalOr">
                                        <p>Valor a pagar</p>
                                        <p>{Total - ValorPago - Valor}</p>
                                    </div>
                                </div>
                            </section>
                            <section className="MensagensPreviewOr">
                                <div>
                                    {Mensagem !== "" &&
                                        <div className="DMensagenOr">
                                            <p>Mensagens:</p>
                                            <div className="DMSGOR">
                                                <p className="MSGOR">{Mensagem}</p>
                                            </div>
                                        </div>
                                    }

                                    {Termos !== "" &&
                                        <div>
                                            <p>Termos Legais:</p>
                                            <div className="DTROR">
                                                <p>{Termos}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                )}

                {isPreviewPagar && (
                    <div className="Fade">
                        <div className="ConteinerPagar">

                            <div className="HeaderPagar">
                                <p>Registrar pagamento</p>
                                <span onClick={() => setPreviewPagar(false)}><svg viewBox="0 0 8 8" fill="currentColor" width="12" height="12" data-hook="close-large"><path d="M7.2 0 4 3.2.8 0 .1.7 3.3 4 0 7.3.7 8 4 4.7 7.3 8 8 7.3 4.7 4 7.9.7z"></path></svg></span>
                            </div>

                            <div>
                                <div className="TitlePgar">
                                    <p>Registre um pagamento recebido para a fatura <span className="SPRest">{NOr}</span></p>
                                    <p> Saldo devedor dessa fatura: <span className="SPRest">R$ {ValorAPagar}</span></p>
                                </div>
                                <div className="InputsPagar">
                                    <div className="InputsPagard">
                                        <p>Valor recebido (BRL): <span>*</span></p>
                                        <input type="number" onChange={e => setValorNaN(e.target.value)} value={ValorNaN} />
                                    </div>
                                    <div className="InputsPagard">
                                        <p>Data do pagamento: <span>*</span></p>
                                        <input type="date" onChange={e => setData(e.target.value)} value={Data} />
                                    </div>
                                </div>
                                <div className="SelectPagar">
                                    <p>Método de pagamento <span>*</span></p>
                                    <select name="Método de pagamento" id="MDP">
                                        <option value="PIX">PIX</option>
                                        <option value="CH">CHEQUE</option>
                                        <option value="DINDIN">DINHEIRO</option>
                                        <option value="TED">TED</option>
                                        <option value="OTER">OUTRO</option>
                                    </select>
                                </div>
                            </div>

                            <div className="FooterPagar">
                                <button className="BtnCancelarUpLoad" onClick={() => setPreviewPagar(false)}>CANCELAR</button>
                                <button className="BtnSaveUpLoad" onClick={SalvarPagamento}>REGISTRAR PAGAMENTO</button>
                            </div>

                        </div>
                    </div>
                )}

            </main >
        </>
    )
}
export default Faturas;