import NavBar from '../../components/NAV-BAR';
import SideNavBar from '../../components/SIDE-NAV-BAR';
import './index-home-style.css'


function Home() {
  return (
    <>
      <head>

        <meta property="og:url" content="https://admin.kawhealvesdossantos.com.br" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
        <meta property="og:description" content="Sistema pessoal de contato com o cliente" />
        <meta name="description" content="Sistema pessoal de contato com o cliente" />
      </head>

      <title>KAWHE | HOME</title>
      <NavBar />
      <main>
        <SideNavBar />

      </main>
    </>
  );
}
export default Home;
