import { useParams } from 'react-router-dom';
import './EditarProjeto-Style.css'
import { useEffect, useRef, useState } from 'react';
import { FieldPath, deleteDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../../config/firebase';
import NavBar from '../../../components/NAV-BAR';
import { toast } from 'react-toastify';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

function EditarProjeto() {

    const [Name, setName] = useState("")

    const [FilePhotoUrl, setFilePhotoUrl] = useState([])

    const [FilePhoto, setFilePhoto] = useState([])
    const [FilePhotoPrev, setFilePhotoPrev] = useState([])
    const [FileCapa, setFileCapa] = useState(null)

    const [Type, setType] = useState("")
    const [Estructure, setEstructure] = useState("")
    const [From, setFrom] = useState("")
    const [FinalProject, setFinalProject] = useState("")
    const [PreProject, setPreProject] = useState("")
    const [Render, setRender] = useState("")
    const [Pdf, setPdf] = useState("")
    const [Date, setDate] = useState("")
    const [VideoUrl, setVideoUrl] = useState("")
    const [Id, setId] = useState("")
    const [Url_Capa, setUrl_Capa] = useState("")
    const [Url_Photo, setUrl_Photo] = useState([])

    const IdProjeteRecivie = useParams("")

    const toastId = useRef(null)


    useEffect(() => {
        // Carregar os detalhes do projeto existente com base no projectId
        const loadProjectDetails = async () => {
            try {
                const projectDoc = doc(db, "Projetos", IdProjeteRecivie.id);
                const projectSnapshot = await getDoc(projectDoc);
                if (projectSnapshot.exists()) {
                    const projectData = projectSnapshot.data();
                    setName(projectData.Name);
                    setType(projectData.Type);
                    setEstructure(projectData.Estructure);
                    setFrom(projectData.From);
                    setFinalProject(projectData.FinalProject);
                    setPreProject(projectData.PreProject);
                    setRender(projectData.Render);
                    setPdf(projectData.Pdf);
                    setDate(projectData.Date);
                    setId(projectData.Id);
                    setVideoUrl(projectData.Url_Video);
                    setUrl_Capa(projectData.Url_Capa)
                    setUrl_Photo(projectData.Url_Photo)
                } else {
                    alert("nao foi possiel achar: " + IdProjeteRecivie.id)
                }
            } catch (error) {
                console.error(error);
            }
        };
        loadProjectDetails();
    }, [IdProjeteRecivie.id]);

    function CleanInput() {
        window.location.href = (`/projetos/EditarProjetos/${IdProjeteRecivie.id}`)
    }

    function HandleFilePhotoChange(e) {
        if (e.target.files[0]) {
            setFilePhoto([...FilePhoto, e.target.files[0]]);

            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    setFilePhotoPrev([...FilePhotoPrev, reader.result]);
                };

                reader.readAsDataURL(file);
            }
        }
    };

    const [selectedImageCapa, setSelectedImageCapa] = useState(null);

    function HandleFileCapaChange(e) {
        if (e.target.files[0]) {
            setFileCapa(e.target.files[0]);

            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    setSelectedImageCapa(reader.result);
                };

                reader.readAsDataURL(file);
            }
        }
    };

    function SaveProjeto() {
        toastId.current = toast("Salvando seu arquivo", {
            autoClose: false,
            closeButton: false
        });
        const TasksRef = doc(db, 'Projetos', Name);
        try {
            updateDoc(TasksRef, {
                Name: Name,
                Type: Type,
                From: From,
                FinalProject: FinalProject,
                Estructure: Estructure,
                PreProject: PreProject,
                Render: Render,
                Pdf: Pdf,
                Date: Date,
                Url_Video: VideoUrl,
            }).then(() => {
                if (FileCapa || FilePhoto) {
                    SalvaCapa()
                } else {
                    if (FileCapa) {
                        SalvaCapa()
                    }
                    if (FilePhoto) {
                        SalvaPhoto()
                    }
                }
            })
        } catch (e) {
            toast.update(toastId.current, {
                render: (`Error inesperado 1: ${e}`),
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                closeButton: null,
            });
        }
    }

    function SalvaPhoto() {
        const TasksRef = doc(db, 'Projetos', Name);
        if (FilePhoto) {
            let URLarray = Url_Photo;
            [...FilePhoto].map((i) => {
                const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/fotos/" + i.name)
                const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, i)
                UpLoadPhoto.on(
                    "state_changed",
                    snapshot => {
                        // const PhotoProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        // setFilePhotoProgress(PhotoProgress)
                    },
                    error => {
                        alert(error)
                    },
                    () => {
                        getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                            console.log(url)
                            URLarray.push({ url: url, name: i.name })
                            setFilePhotoUrl(URLarray)
                            try {
                                updateDoc(TasksRef, {
                                    Url_Photo: URLarray,
                                }).then(() => {
                                    if (PhotosApagadas) {
                                        PhotosApagadas.map((i) => {
                                            const storageRef1 = ref(storage, "/Projetos/imagens/" + Name + "/fotos/" + i.Name);
                                            deleteObject(storageRef1).then(() => {
                                                toast.update(toastId.current, {
                                                    render: "Salvo com sucesso",
                                                    type: toast.TYPE.SUCCESS,
                                                    autoClose: 1000,
                                                    closeButton: null,
                                                    onClose: window.location.href = "/projetos"
                                                });
                                                return
                                            }).catch(error => {
                                                toast.update(toastId.current, {
                                                    render: (`Error inesperado: ${error}`),
                                                    type: toast.TYPE.ERROR,
                                                    autoClose: 5000,
                                                    closeButton: null,
                                                });
                                            })
                                        })
                                    } else {
                                        toast.update(toastId.current, {
                                            render: "Salvo com sucesso",
                                            type: toast.TYPE.SUCCESS,
                                            autoClose: 1000,
                                            closeButton: null,
                                            onClose: window.location.href = "/projetos"
                                        });
                                        return
                                    }
                                })
                            } catch (e) {
                                toast.update(toastId.current, {
                                    render: (`Error inesperado: ${e}`),
                                    type: toast.TYPE.ERROR,
                                    autoClose: 5000,
                                    closeButton: null,
                                });
                            }
                        })
                    }
                )
            })
        } else {
            return
        }
    }

    function SalvaCapa() {
        if (FileCapa || FilePhoto) {
            const partes = Url_Capa.split('/');
            const nomeDoArquivoComToken = partes[partes.length - 1];
            const nomeDoArquivoSemToken = nomeDoArquivoComToken.split('?')[0];
            const nomeDoArquivoSubstituido = nomeDoArquivoSemToken.replace(/%2F/g, '/').replace(/%20/g, ' ');

            const FileCapaName = FileCapa.name
            const StorageRefCapa = ref(storage, "Projetos/imagens/" + Name + "/capa/" + FileCapaName)
            const UpLoadCapa = uploadBytesResumable(StorageRefCapa, FileCapa)
            UpLoadCapa.on(
                "state_changed",
                snapshot => {
                    // const CapaProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    // setFileCapaProgress(CapaProgress)
                },
                error => {
                    toast.update(toastId.current, {
                        render: (`Error inesperado 2: ${error}`),
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        closeButton: null,
                    });
                },
                () => {
                    getDownloadURL(UpLoadCapa.snapshot.ref).then(url => {
                        const TasksRef = doc(db, 'Projetos', Name);
                        try {
                            updateDoc(TasksRef, {
                                Url_Capa: url,
                            }).then(() => {
                                const storageRef1 = ref(storage, nomeDoArquivoSubstituido);
                                deleteObject(storageRef1).then(() => {

                                }).catch(error => {
                                    toast.update(toastId.current, {
                                        render: (`Error inesperado: ${error}`),
                                        type: toast.TYPE.ERROR,
                                        autoClose: 5000,
                                        closeButton: null,
                                    });
                                })
                            })
                        } catch (e) {
                            toast.update(toastId.current, {
                                render: (`Error inesperado 1: ${e}`),
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                closeButton: null,
                            });
                        }
                    })
                }
            )
        }
    }

    function CancelarSaveProjeto() {
        window.location.href = "/projetos"
    }

    const [svgVisible, setSvgVisible] = useState(false);

    const [svgVisible2, setSvgVisible2] = useState(false);

    const handleMouseEnter = () => {
        setSvgVisible(true);
    };

    const handleMouseLeave = () => {
        setSvgVisible(false);
    };

    const handleMouseEnter2 = (i) => {
        setSvgVisible2(i);
    };

    const handleMouseLeave2 = () => {
        setSvgVisible2("");
    };


    const [PhotosApagadas, setPhotosApagadas] = useState([])

    function ApagarFoto(Nome, Link, index) {
        setPhotosApagadas([...PhotosApagadas, { Name: Nome, Url: Link }])
        const updatedProdutos = [...Url_Photo.slice(0, index), ...Url_Photo.slice(index + 1)];
        setUrl_Photo(updatedProdutos)
    }

    function ApagarFoto2(index) {
        const updatedProdutos1 = [...FilePhoto.slice(0, index), ...FilePhoto.slice(index + 1)];
        setFilePhoto(updatedProdutos1)

        const updatedProdutos2 = [...FilePhotoPrev.slice(0, index), ...FilePhotoPrev.slice(index + 1)];
        setFilePhotoPrev(updatedProdutos2)
    }

    return (
        <>
            <head>

                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/projetos/EditarProjetos/${Name}`} />
                <meta property="og:image" content={`${Url_Capa}`} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:description" content={`Editar projeto ${Name}`} />
                <meta name="description" content={`Editar projeto ${Name}`} />
            </head>

            <title>KAWHE | PROJETOS | EDITAR | {Name}</title>

            <NavBar />
            <div className='MainViwProjetos'>
                <button className="SaveProjeto" onClick={SaveProjeto}>Salvar projeto</button>
                <button className="SaveProjeto" onClick={CancelarSaveProjeto}>Cancelar</button>
                <div>
                    <div className='itenscarrosel'>
                        <div className='STextosHome'>
                            <div className='LineHomeTextos'></div>
                            <h1>{Name}</h1>
                            <div className='LineHomeTextos'></div>
                            <h4>INFORMAÇÃOES DO PROJETO</h4>
                            <div className='LineHomeTextos'></div>
                            <div className='ItensTextosHome'>
                                <h6>PROJETO: <input type="text" id="InputType" className="IF InputType" onChange={e => setType(e.target.value)} value={Type} /></h6>
                                <h6>ESTRUTURA: <input type="text" id="InputEstructure" className="IF InputEstructure" onChange={e => setEstructure(e.target.value)} value={Estructure} /></h6>
                                <h6>PARA: <input type="text" id="InputFrom" className="IF InputFrom" onChange={e => setFrom(e.target.value)} value={From} /></h6>
                            </div>
                            <div className='LineHomeTextos'></div>
                            <h4>INFORMAÇÃOES DE SOFTWARE</h4>
                            <div className='LineHomeTextos'></div>
                            <div className='ItensTextosHome'>
                                <h6>PROJETO FINAL: <input type="text" id="InputFinalProject" className="IF InputFinalProject" onChange={e => setFinalProject(e.target.value)} value={FinalProject} /></h6>
                                <h6>PRÉ PROJETO: <input type="text" id="InputPreProject" className="IF InputPreProject" onChange={e => setPreProject(e.target.value)} value={PreProject} /></h6>
                                <h6>RENDER: <input type="text" id="InputRender" className="IF InputRender" onChange={e => setRender(e.target.value)} value={Render} /></h6>
                                <h6>PDF: <input type="text" id="InputPdf" className="IF InputPdf" onChange={e => setPdf(e.target.value)} value={Pdf} /></h6>
                            </div>
                            <div className='LineHomeTextos'></div>
                            <h4><input type="date" id="InputDate" className="InputDate" onChange={e => setDate(e.target.value)} value={Date} /></h4>
                            <div className='LineHomeTextos'></div>
                        </div>

                        <div className='SImageHome'>
                            <label
                                htmlFor="FotoCapa"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <svg
                                    style={{ display: svgVisible ? "flex" : "none" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="25px"
                                    viewBox="0 0 512 512">
                                    <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                                </svg>
                                {!FileCapa ?
                                    <img src={Url_Capa} alt={`Capa do projeto ${Name}`} />
                                    :
                                    <img src={selectedImageCapa} alt={`Nova capa`} />
                                }
                            </label>
                            <input accept="image/png" type="file" name="FotoCapa" id="FotoCapa" className="FotoCapa" onChange={HandleFileCapaChange} />
                        </div>
                    </div>
                </div>

                <div>
                    <input type="text" id="InputVideoUrl" className="InputVideoUrl" onChange={e => setVideoUrl(e.target.value)} value={VideoUrl ? VideoUrl : "nao possui link"} />
                </div>

                <div className='DivRow'>
                    {Url_Photo &&
                        Url_Photo.map((i, index) => (
                            <div className={`FotosProjetos ${i.name}`}>
                                <label
                                    className='LabelFotos'
                                    onMouseEnter={() => handleMouseEnter2(index)}
                                    onMouseLeave={() => handleMouseLeave2()}
                                >
                                    <svg
                                        style={{ display: svgVisible2 === index ? "flex" : "none" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="25px"
                                        viewBox="0 0 512 512"
                                        onClick={() => ApagarFoto(i.name, i.url, index)}
                                    >
                                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                    </svg>
                                    <img src={i.url} alt={i.name} className={`FotosProjetos ${i.name}`}></img>
                                </label>
                            </div>
                        ))
                    }

                    {FilePhotoPrev &&
                        FilePhotoPrev.map((i, index) => (
                            <div className={`FotosProjetos ${i.name}`}>
                                <label
                                    className='LabelFotos'
                                    onMouseEnter={() => handleMouseEnter2(index)}
                                    onMouseLeave={() => handleMouseLeave2()}
                                >
                                    <svg
                                        style={{ display: svgVisible2 === index ? "flex" : "none" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="25px"
                                        viewBox="0 0 512 512"
                                        onClick={() => ApagarFoto2(index)}
                                    >
                                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                    </svg>
                                    <img src={i} alt="" className={`FotosProjetos`}></img>
                                </label>
                            </div>
                        ))
                    }

                    <div className={`FotosProjetos`}>
                        <label
                            htmlFor="Fotos"
                            className='LabelFotos'
                        >
                            <img src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/Add%20foto.png?alt=media&token=86a35dc4-25c1-4f8c-916a-424e47431a77" alt="add Projeto" className={`FotosProjetos`}></img>
                        </label>
                        <input accept="image/png" type="file" name="FotoCapa" id="Fotos" className="FotoCapa" onChange={HandleFilePhotoChange} />
                    </div>

                </div>


            </div>
        </>
    )
}
export default EditarProjeto;