import { useState } from 'react';
import "./TicTacToe-Sytle.css"

function TicTacToe() {

    

    const [board, setBoard] = useState(Array(9).fill(null));
    const [xIsNext, setXIsNext] = useState(true);

    const [scoreX, setScoreX] = useState(0);
    const [scoreO, setScoreO] = useState(0);

    const calculateWinner = (squares) => {
        const lines = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];

        for (let i = 0; i < lines.length; i++) {
            const [a, b, c] = lines[i];
            if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
                return squares[a];
            }
        }
        return null;
    };

    const handleClick = (i) => {
        const newBoard = [...board];
        if (calculateWinner(board) || newBoard[i]) return;

        newBoard[i] = xIsNext ? "X" : "O";
        setBoard(newBoard);
        setXIsNext(!xIsNext);

        if (calculateWinner(newBoard)) {
            if (calculateWinner(newBoard) === "X") {
                setScoreX(scoreX + 1); // Incrementa o contador de vitórias do jogador X
            } else {
                setScoreO(scoreO + 1); // Incrementa o contador de vitórias do jogador O
            }
        }
    };

    const renderSquare = (i) => {
        return (
            <button className="square" onClick={() => handleClick(i)}>
                {board[i]}
            </button>
        );
    };

    const winner = calculateWinner(board);
    let status;
    if (winner) {
        status = "Vencedor: " + winner;
    } else if (board.every((square) => square)) {
        status = "Empate!";
    } else {
        status = "Próximo jogador: " + (xIsNext ? "X" : "O");
    }
    
    function Limpar() {
        setBoard(Array(9).fill(null))
    }

    function Reset() {
        window.location.href="/games/velha"
    }

    return (
        <div className="game">
            <div className="game-board">
                <div className="board-row">
                    {renderSquare(0)}
                    {renderSquare(1)}
                    {renderSquare(2)}
                </div>
                <div className="board-row">
                    {renderSquare(3)}
                    {renderSquare(4)}
                    {renderSquare(5)}
                </div>
                <div className="board-row">
                    {renderSquare(6)}
                    {renderSquare(7)}
                    {renderSquare(8)}
                </div>
                <div className="status">{status}</div>
                <div className="scoreboard">
                    <h2>Placar</h2>
                    <div className="player-score">
                        <p>Jogador X: {scoreX}</p>
                    </div>
                    <div className="player-score">
                        <p>Jogador O: {scoreO}</p>
                    </div>
                </div>
                <button className="Clean" onClick={Limpar}>Limpar</button>
                <button className="Reset" onClick={Reset}>Resetart</button>
            </div>
        </div>
    );
}
export default TicTacToe;