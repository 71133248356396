import './NavTaskManager-style.css'
import { useRef, useState } from 'react'
import { doc, setDoc } from '@firebase/firestore'
import { db } from '../../config/firebase'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'

function NavTaskManager() {
  const id = uuidv4()

  const [Name, setName] = useState('')
  const [Day, setDay] = useState('')
  const [Month, setMonth] = useState('')
  const [Year, setYaer] = useState('')
  const [DateInp, setDate] = useState('')
  const [EndTime, setEndtime] = useState('')
  const [StartTime, setstartTime] = useState('')
  const [Status] = useState('Progress')

  console.log(Year)
  console.log(Month)
  console.log(Day)

  const toastId = useRef(null)

  function CleanInput() {
    setName('')
    setDay('')
    setMonth('')
    setYaer('')
    setDate('')
    setEndtime('')
    setstartTime('')
  }

  function OpenAddTask() {
    const AddTask = document.querySelector(".Fadetask")
    AddTask.style.display = "flex"
  }

  function CloseAddTask() {
    const AddTask = document.querySelector(".Fadetask")
    AddTask.style.display = "none"
  }

  function CreateTask() {
    if (Name === '' || Date === '' || EndTime === '' || StartTime === '') {
      if (Name === '') {
        toast.warn("Preencha o campo 'Nome' para salvar")
      } else if (DateInp === '') {
        toast.warn("Preencha o campo 'Data' para salvar")
      } else if (EndTime === '') {
        toast.warn("selecione uma Hora de inicio para salvar")
      } else if (StartTime === '') {
        toast.warn("selecione uma Hora de termino para salvar")
      }
    } else {

      const partes = DateInp.split("-");
      const data = new Date(partes[0], partes[1] - 1, partes[2]);
  
      toastId.current = toast('Salvando sua tarefa', {
        autoClose: false,
        closeButton: false
      })
      const TasksRef = doc(db, 'Tasks', Name)
      try {
        setDoc(TasksRef, {
          Id: id,
          name: Name,
          day: parseInt(data.getDate().toString().padStart(2, '0')),
          month: parseInt((data.getMonth()).toString().padStart(2, '0')),
          year: parseInt(data.getFullYear().toString()),
          endTime: EndTime,
          startTime: StartTime,
          Status: Status
        }).then(() => {
          toast.update(toastId.current, {
            render: 'Salvo com sucesso',
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
            closeButton: null,
            onOpen: CloseAddTask() + CleanInput()
          })
        })
      } catch (e) {
        toast.update(toastId.current, {
          render: `Error inesperado: ${e}`,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          closeButton: null
        })
      }
    }
  }

  return (
    <>
      <nav className='NavTaskManager'>
        <div className='BtnCanceledTaskManager'>
          <a href='/kawhe/cancelado' id='BtnCanceledTaskManager'>
            Cancelado
          </a>
        </div>

        <div className='BtnInprogressTaskManager'>
          <a href='/kawhe/emprogresso' id='BtnInprogressTaskManager'>
            Em Progresso
          </a>
        </div>

        <div className='BtnReadyTaskManager'>
          <a href='/kawhe/finalizado' id='BtnReadyTaskManager'>
            Finalizado
          </a>
        </div>

        <div className='ButtonAddTask'>
          <div className='LineT'></div>
          <button className='ButtonAddTaskbtn' onClick={OpenAddTask}>
            <i class='fa-solid fa-plus'></i>
          </button>
        </div>
      </nav>

      <div className='Fadetask'>
        <div className='PopUpAddTask'>
          <div className='IputName'>
            <label htmlFor="Name">Nome:</label>
            <input type='text' className='taskinput' id='Name' onChange={e => setName(e.target.value)} value={Name} ></input>
          </div>
          <section>
            <div className='inputDate'>
              <label htmlFor="Data">Data:</label>
              <input type='date' className='datainput' id='Data' onChange={e => setDate(e.target.value)} value={DateInp} ></input>
            </div>
            <div className='InputsTimeTasks'>
              <div className='InputTimeTasks'>
                <label htmlFor="St">Hora de inicio:</label>
                <input type="Time" className='datainput' id='St' onChange={e => setstartTime(e.target.value)} value={StartTime} />
              </div>
              <div className='InputTimeTasks'>
                <label htmlFor="Et">Hora de termino:</label>
                <input type="Time" className='datainput' id='Et' onChange={e => setEndtime(e.target.value)} value={EndTime} />
              </div>
            </div>
          </section>
          <progress value={0} max={100}></progress>
          <div>
            <button className='BtnSaveTask' onClick={CreateTask}>Salvar</button>
            <button className='BtnCancelarTask' onClick={CloseAddTask}>Cancelar</button>
          </div>
        </div>
      </div>
    </>
  )
}
export default NavTaskManager
