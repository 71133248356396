import { useEffect, useRef } from "react";
import "../../Orçamentos/CriarOrçamentos/CriarOrçamentos-Style.css"
import { useState } from "react";
import NavBar from "../../../components/NAV-BAR";
import SideNavBar from "../../../components/SIDE-NAV-BAR";
import { collection, doc, getDoc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../config/firebase";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Logo from "../../../images/logo.png"
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

function CriarFaturas() {

    const [Produtos, setProdutos] = useState([{ produto: "", descrição: "", preço: "", qt: "", total: 0 }])
    const [Clientes, setClientes] = useState([])

    const [ClienteInfo, setClienteInfo] = useState([])

    const [isPreviewVisible, setPreviewVisible] = useState(false)
    const [Title, setTitle] = useState("")
    const [Status, setStatus] = useState("Faturada")
    const [SelectedClientes, setSelectedClientes] = useState("")
    const [Vencimento, setVencimento] = useState("")
    const [Mensagem, setMensagem] = useState("")
    const [Termos, setTermos] = useState("")
    const [Desconto, setDesconto] = useState(0)
    const [DescontoAmont, setDescontoAmont] = useState(0)
    const [SubTotal, setSubTotal] = useState(0)
    const [Total, setTotal] = useState(0)

    const [Type, setType] = useState("Fatura")

    const [NFt2, setNFt2] = useState()
    const [NFt, setNFt] = useState("")

    const [PdfURL, setPdfURL] = useState("")

    const data = new Date()

    const Ano = data.getFullYear()
    const Mes = (data.getMonth() + 1)
    const Dia = data.getDate()

    const [DateEmi, setDateEmi] = useState(`${Dia}-${Mes}-${Ano}`)

    const divRef = useRef(null);

    const toastId = useRef(null);

    useEffect(() => {
        const loadProjectDetails = async () => {
            try {
                const projectDoc = doc(db, 'Comfigs', 'NumeroPOr');
                const projectSnapshot = await getDoc(projectDoc);
                if (projectSnapshot.exists()) {
                    const projectData = projectSnapshot.data();
                    setNFt2(projectData.NumeroOr);
                    setNFt(String(projectData.NumeroOr).padStart(7, "0"));
                } else {
                    alert("nao foi possiel achar: " + SelectedClientes)
                }
            } catch (error) {
                console.error(error);
            }
        };
        loadProjectDetails();
    }, []);

    useEffect(() => {
        const q = query(collection(db, 'Clientes'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setClientes(todosArr);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (SelectedClientes !== "") {
            const loadProjectDetails = async () => {
                try {
                    const projectDoc = doc(db, 'Clientes', SelectedClientes);
                    const projectSnapshot = await getDoc(projectDoc);
                    if (projectSnapshot.exists()) {
                        const projectData = projectSnapshot.data();
                        setClienteInfo([
                            projectData.NomeCl,
                            projectData.SubNomeCl,
                            projectData.EndRuaCl,
                            projectData.EndBairroCl,
                            projectData.EndCidadeCl,
                            projectData.EndEstadoCl,
                            projectData.EndCepCl,
                            projectData.CellNumCl,
                            projectData.EmailCl
                        ]);
                    } else {
                        alert("nao foi possiel achar: " + SelectedClientes)
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            loadProjectDetails();
        }
    }, [SelectedClientes]);

    function AddIntensButton(e) {
        setProdutos([...Produtos, { produto: "", descrição: "", preço: "", qt: "", total: 0 }])
    }

    function HandleChangeProduto(e, index, field) {
        const { value } = e.target;
        const newProdutos = [...Produtos];
        newProdutos[index][field] = value;
        setProdutos(newProdutos);
        if (field === "preço" || field === "qt") {
            const preço = parseFloat(newProdutos[index].preço) || 0;
            const qt = parseFloat(newProdutos[index].qt) || 0;
            newProdutos[index][field] = value;
            newProdutos[index].total = (preço * qt);
            let totalSubSum = 0;
            Produtos.forEach((produto) => {
                totalSubSum += parseFloat(produto.total) || 0;
            });
            setSubTotal(totalSubSum);
            setTotal((totalSubSum - DescontoAmont))
        } else {
            newProdutos[index][field] = value;
        }
        setProdutos(newProdutos);
    }

    function handleDesncontochanged(e) {
        setDesconto(e.target.value)
        const DescontoCalc = SubTotal - (SubTotal * e.target.value) / 100
        setDescontoAmont((SubTotal * e.target.value) / 100)
        setTotal(DescontoCalc)
    }

    function handleChangeCl(e) {
        setSelectedClientes(e.target.value);
    };

    const [focusedIndex, setFocusedIndex] = useState(true);

    function handleMouseEnter(index) {
        setFocusedIndex(index);
    };

    function handleMouseLeave() {
        setFocusedIndex(false);
    };

    function OpenPreviw() {
        if (Title === "") {
            toast.warn("Preemcha o campo 'Título' para poder prosegir");
        } else if (SelectedClientes === "") {
            toast.warn("Este orçamento precisa ser vinculado a um cliente");
        } else if (Vencimento === "") {
            toast.warn("Qual prazo para este orçamento");
        } else if (SubTotal === 0) {
            toast.warn("Preencha os valores dos itens");
        } else if (Termos === "") {
            toast.warn("Preemcha o campo 'Termo' para poder prosegir");
        } else {
            setPreviewVisible(true);
        }
    }

    function ClosePreviw() {
        setPreviewVisible(false);
    }

    function saveDivToPDF() {
        const divToExport = document.querySelector('.PreviwOr');
        if (divToExport) {
            toastId.current = toast("Enviando Pdf", {
                autoClose: false,
                closeButton: false
            });
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const scale = "6";

            html2canvas(divToExport, { scale: scale }).then((canvas) => {
                const dataURL = canvas.toDataURL('image/jpeg');
                const imgWidth = pdfWidth;
                const imgHeight = (imgWidth * divToExport.offsetHeight) / divToExport.offsetWidth;
                pdf.addImage(dataURL, 'JPEG', 0, 0, imgWidth, imgHeight);
                const pdfData = pdf.output('blob');
                const filename = ("Fatura-" + NFt + ".pdf");
                const storageRef = ref(storage, "Faturas/PDF/" + filename);
                uploadBytes(storageRef, pdfData).then((snapshot) => {
                    console.log('PDF enviado com sucesso para o Firebase Storage:', snapshot);
                    return getDownloadURL(storageRef);
                }).then((url) => {
                    setPdfURL(url);
                    const TasksRef = doc(db, 'Faturas', NFt);
                    try {
                        setDoc(TasksRef, {
                            Email_Cl: ClienteInfo[8],
                            Link_Pdf: url,
                            Id_Cliente: SelectedClientes,
                            Nome_Cliente: ClienteInfo[0] + " " + ClienteInfo[1],
                            N_Or: NFt,
                            Produtos: Produtos,
                            Title: Title,
                            Status: Status,
                            Total: Total,
                            SubTotal: SubTotal,
                            Vencimento: Vencimento,
                            Mensagem: Mensagem,
                            Termos: Termos,
                            Desconto_Per: Desconto,
                            Desconto_Am: DescontoAmont,
                            CreatAt: (`${Dia} ${Mes} ${Ano}`),
                            Link_Jpeg: "",
                            ValorAPagar: Total,
                            ValorPago: 0,
                        }).then(() => {
                            html2canvas(divToExport, { scale: scale }).then((canvas) => {
                                const dataURL = canvas.toDataURL('image/png');
                                const filename = ("Fatura-" + NFt + ".png");
                                const storageRef = ref(storage, "Faturas/Image/" + filename);
                                uploadBytes(storageRef, dataURLtoBlob(dataURL)).then((snapshot) => {
                                    return getDownloadURL(storageRef)
                                        .then((url) => {
                                            console.log(url)
                                            var templateParams = {
                                                LINK_OR: url,
                                                N_CL: ClienteInfo[0] + " " + ClienteInfo[1],
                                                TO: ClienteInfo[8],
                                                MSG: "Nova Fatura",
                                                T_ALT_FT: "Fatura"
                                            };
                                            emailjs.send('service_btvbj1e', 'template_m0lp0zs', templateParams, "bTup3YaphfZrsBv1X")
                                                .then(function (response) {
                                                    if (response.status === 200) {
                                                        const TasksRef2 = doc(db, 'Comfigs', "NumeroPOr");
                                                        const TasksRef3 = doc(db, 'Faturas', NFt);
                                                        try {
                                                            const calc = (NFt2 + 1)
                                                            updateDoc(TasksRef2, {
                                                                NumeroOr: calc,
                                                            })
                                                            updateDoc(TasksRef3, {
                                                                Status: "Enviada",
                                                                Link_Jpeg: url,
                                                            })
                                                        } catch (e) {
                                                            toast.update(toastId.current, {
                                                                render: (`Error inesperado 1: ${e}`),
                                                                type: toast.TYPE.ERROR,
                                                                autoClose: 5000,
                                                                closeButton: null,
                                                            });
                                                        }
                                                        toast.update(toastId.current, {
                                                            render: "Enviado com sucesso",
                                                            type: toast.TYPE.SUCCESS,
                                                            autoClose: 5000,
                                                            closeButton: null,
                                                            onOpen: ClosePreviw(),
                                                            onClose: () => window.location.href = "/crm/faturas"
                                                        });
                                                    }
                                                }, (e) => {
                                                    toast.update(toastId.current, {
                                                        render: (`Error inesperado 2: ${e}`),
                                                        type: toast.TYPE.ERROR,
                                                        autoClose: 5000,
                                                        closeButton: null,
                                                    });
                                                });
                                        }).catch((e) => {
                                            toast.update(toastId.current, {
                                                render: (`Error inesperado 3: ${e}`),
                                                type: toast.TYPE.ERROR,
                                                autoClose: 5000,
                                                closeButton: null,
                                            });
                                        });

                                }).catch((e) => {
                                    toast.update(toastId.current, {
                                        render: (`Error inesperado 4: ${e}`),
                                        type: toast.TYPE.ERROR,
                                        autoClose: 5000,
                                        closeButton: null,
                                    });
                                    console.log(e)
                                });
                            });
                        })
                    } catch (e) {
                        toast.update(toastId.current, {
                            render: (`Error inesperado 5: ${e}`),
                            type: toast.TYPE.ERROR,
                            autoClose: 5000,
                            closeButton: null,
                        });
                    }
                }).catch((e) => {
                    toast.update(toastId.current, {
                        render: (`Error inesperado 6: ${e}`),
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        closeButton: null,
                    });
                });
            });
        } else {
            setStatus("Rascunho")
            toast.error(`A div com a classe 'PreviwOr' não foi encontrada.`);
        }

        // Função auxiliar para converter dataURL em Blob
        function dataURLtoBlob(dataURL) {
            const arr = dataURL.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
    }

    function DiscartItem(id) {
        if (Produtos.length > 1) {
            const updatedProdutos = [...Produtos.slice(0, id), ...Produtos.slice(id + 1)];
            setProdutos(updatedProdutos)
        } else {
            return
        }
    }

    return (
        <>
            <head>

                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/clientes`} />
                <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="600" />
                <meta property="og:description" content={`Lançamentos de novas faturas`} />
                <meta name="description" content="Lançamentos de novas faturas" />
            </head>

            <title>KAWHE | NOVA FATURA</title>
            
            <NavBar />
            <main>
                <SideNavBar />
                <div className='MainOrçamentos'>
                    <section className='NavUpLoad'>
                        <h2 className='TxHeaderUpLoad'>Nova Fatura<span className='QtnArquivos'></span></h2>
                        <a href="/crm/faturas" className='BtnCencelUpLoad' >Cancelar</a>
                        <button className='BtnAddUpLoad' onClick={OpenPreviw} >Vizualizar</button>
                    </section>
                    <section className="InputsOr">
                        <section className="InfoOr">
                            <section>
                                <p className="TituloOrInfo" >Informações da fatura</p>
                            </section>
                            <section>
                                <div>
                                    <p className="TOr">Titulo:</p>
                                    <input
                                        type="text"
                                        name="titulo"
                                        id="TituloOr"
                                        className="TituloOr"
                                        onChange={e => setTitle(e.target.value)}
                                        value={Title}
                                        placeholder="Titulo da fatura"
                                    />
                                </div>
                                <div>
                                    <p className="TOr">Cliente:</p>
                                    <select
                                        name="SelectClientes"
                                        id="SelectClientes"
                                        className="SelectClientes"
                                        onChange={handleChangeCl}
                                    >
                                        <option
                                            value=""
                                        >
                                            Nenhum
                                        </option>
                                        {
                                            Clientes && Clientes.map((i, index) => (
                                                <option
                                                    key={index}
                                                    value={i.Id}
                                                >
                                                    {i.NomeCl + " " + i.SubNomeCl}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <input type="text" className="EmailClientes" value={ClienteInfo[8]} />
                                </div>
                            </section>
                            <section>
                                <div>
                                    <p className="TOr">Data de vencimento:</p>
                                    <input
                                        type="date"
                                        name="VencimentoOr"
                                        id="VencimentoOr"
                                        className="VencimentoOr"
                                        onChange={e => setVencimento(e.target.value)} value={Vencimento}
                                    />
                                </div>
                            </section>
                        </section>

                        <section className="ItensOr">
                            <section className="ListItensOr">
                                <div>
                                    <p className="TextIDO">Itens do orçamento</p>
                                </div>

                                {Produtos &&
                                    Produtos.map((produto, index) => (
                                        <div key={index} className="ItensListaInput">
                                            <div className="ITL ItensListaInput1">
                                                <p>Serviço</p>
                                                <select
                                                    value={produto.produto}
                                                    name="Produto"
                                                    id="ProdutoOr"
                                                    className="Produto"
                                                    onChange={(e) => HandleChangeProduto(e, index, "produto")}
                                                >
                                                    <option value=""></option>
                                                    <option value="PPCIP">PPCIP</option>
                                                    <option value="ARQ.">ARQ.</option>
                                                    <option value="HID">HID.</option>
                                                    <option value="EST.">EST.</option>
                                                    <option value="RENDER">RENDER</option>
                                                    <option value="ANIMAÇÃO">ANIMAÇÂO</option>
                                                </select>
                                            </div>
                                            <div className="ITL ItensListaInput2">
                                                <p>Descrição</p>
                                                <input
                                                    type="text"
                                                    name="Description"
                                                    id="DescriptionOr"
                                                    className="DescriptionOr"
                                                    onChange={(e) => HandleChangeProduto(e, index, "descrição")}
                                                />
                                            </div>
                                            <div className="ITL ItensListaInput3">
                                                <p>Preço (R$)</p>
                                                <input
                                                    type="text"
                                                    name="Price"
                                                    id="PriceOr"
                                                    className="PriceOr"
                                                    onChange={(e) => HandleChangeProduto(e, index, "preço")}
                                                />
                                            </div>
                                            <div className="ITL ItensListaInput4">
                                                <p>Quant.</p>
                                                <input
                                                    type="text"
                                                    name="QT"
                                                    id="QtOr"
                                                    className="QtOr"
                                                    onChange={(e) => HandleChangeProduto(e, index, "qt")}
                                                />
                                            </div>
                                            <div
                                                className="ITL ItensListaInput5"
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <p>Total</p>
                                                R$ {Produtos[index].total}
                                            </div>
                                            <div
                                                key={index}
                                                className={`TrashOR  ${focusedIndex === index ? 'trashViw' : ''}`}
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <button onClick={() => DiscartItem(index)}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                    >
                                                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                                <button
                                    className="ButtonAddNewItemOr"
                                    onClick={AddIntensButton}
                                >
                                    <i class="fa-solid fa-plus fa-plus-pj"></i>
                                    Adicionar item
                                </button>
                            </section>
                            <section className="TotaisOr">
                                <div className="SubtotalOr">
                                    <div>
                                        <p>Subtotal</p>
                                    </div>
                                    <div>
                                        <p>R$ {SubTotal}</p>
                                    </div>
                                </div>
                                <div className="DescontOr">
                                    <div className="DescontOrT">
                                        <p>Desconto</p>
                                        <div className="InputDisconto">%
                                            <input
                                                type="number"
                                                value={Desconto}
                                                onChange={handleDesncontochanged}
                                                max={5}
                                            />
                                        </div>
                                    </div>
                                    <div className="TIPD">
                                        <p>R$ {DescontoAmont.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="LineOr"></div>
                                <div className="TotalOr">
                                    <h3>TOTAL</h3>
                                    <p>R$ {Total}</p>
                                </div>
                            </section>
                        </section>
                        <section className="InfoAddOr">
                            <div>
                                <h3>Informações adicionais</h3>
                            </div>
                            <div>
                                <p>Mensagens</p>
                                <textarea
                                    name="Mensagens"
                                    id="MensagemOr"
                                    cols="50"
                                    rows="5"
                                    onChange={e => setMensagem(e.target.value)}
                                    value={Mensagem}
                                >
                                </textarea>
                            </div>
                            <div>
                                <p>Termos</p>
                                <textarea
                                    name="Term"
                                    id="TermOr"
                                    cols="50"
                                    rows="5"
                                    onChange={e => setTermos(e.target.value)}
                                    value={Termos}
                                >
                                </textarea>
                            </div>
                        </section>
                    </section>

                    {isPreviewVisible && (
                        <div className="PreviewOrçamento">
                            <div style={{ height: "87vh" }}>
                                <div ref={divRef} className="PreviwOr">
                                    <section>
                                        <div className="PreviewHeader">
                                            <div className="PreviewHeader1">
                                                <div className="Logo">
                                                    <img className="LogoImg" src={Logo} alt="Logo" />
                                                </div>
                                                <div className="PerInfo">
                                                    <p className="MyName">KAWHE ALVES DOS SANTOS</p>
                                                    <p>Brazil</p>
                                                    <p>cantato@kawhealvesdossantos.com.br</p>
                                                    <p>Telefone: +55 69 9 93172767</p>
                                                </div>
                                            </div>
                                            <div className="InfoPreviwOr">
                                                <p
                                                    className={`StatusOr  
                                            ${Status === "Enviada" ? 'STEnviada'
                                                            :
                                                            Status === "Rascunho" ? "STRascunho"
                                                                :
                                                                Status === "Faturada" ? 'STEnviada'
                                                                    :
                                                                    ""
                                                        }`}>{Status}</p>
                                                <p>{Type} Nº: {NFt}</p>
                                                <p>Emitida em: {DateEmi}</p>
                                                <p>Data de vencimento: {Vencimento}</p>
                                            </div>
                                        </div>
                                        <div className="DTitleOr">
                                            <p>{Title}</p>
                                        </div>
                                        <div className="InfoCl">
                                            <p className="InfoClp1">{ClienteInfo[0] + " " + ClienteInfo[1]}</p>
                                            <p className="InfoClp2">{ClienteInfo[2] + " " + ClienteInfo[3]}</p>
                                            <p className="InfoClp3">{ClienteInfo[4] + " " + ClienteInfo[5] + " " + ClienteInfo[6]}</p>
                                            <p className="InfoClp4">{ClienteInfo[7]}</p>
                                        </div>
                                    </section>
                                    <section>
                                        <div className="HeaderOrPreços">
                                            <p>Serviço</p>
                                            <p>Preço (R$)</p>
                                            <p>Quantidade</p>
                                            <p>Total</p>
                                        </div>
                                        {Produtos &&
                                            Produtos.map((p, index) => (
                                                <div key={index} className="ListPrOr">
                                                    <div className="Pr Cw">
                                                        <p className="PrP">{p.produto}</p>
                                                        <p className="PrC">{p.descrição}</p>
                                                    </div>
                                                    <p className="Cw">{p.preço}</p>
                                                    <p className="Cw">{p.qt}</p>
                                                    <p className="Cw">{p.total}</p>
                                                </div>
                                            ))
                                        }
                                        <div className="LinePreviewOr"></div>
                                    </section>
                                    <section className="TotaisPreviwOr">
                                        <div className="DTotaisPreviwOr">
                                            <div>
                                                <p>SubTotal</p>
                                                <p>{SubTotal}</p>
                                            </div>
                                            {Desconto !== 0 &&
                                                <div>
                                                    <p>Desconto</p>
                                                    <p>{Desconto}%</p>
                                                    <p>{DescontoAmont}</p>
                                                </div>
                                            }
                                            <div className="DTotalOr">
                                                <p>Total</p>
                                                <p>{Total}</p>
                                            </div>

                                            <div className="DTotalOr">
                                                <p>Valor pago</p>
                                                <p>0</p>
                                            </div>
                                            <div className="DTotalOr">
                                                <p>Valor a pagar</p>
                                                <p>{Total}</p>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="MensagensPreviewOr">
                                        <div>
                                            {Mensagem !== "" &&
                                                <div className="DMensagenOr">
                                                    <p>Mensagens:</p>
                                                    <div className="DMSGOR">
                                                        <p className="MSGOR">{Mensagem}</p>
                                                    </div>
                                                </div>
                                            }

                                            {Termos !== "" &&
                                                <div>
                                                    <p>Termos Legais:</p>
                                                    <div className="DTROR">
                                                        <p>{Termos}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="ButtonPreviw">
                                <button onClick={ClosePreviw}>Voltar</button>
                                <button onClick={saveDivToPDF} >Salvar fatura</button>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </>
    )
}
export default CriarFaturas;