import { useState } from 'react'
import './Login-syle.css'
import { auth } from '../../config/firebase'
import {
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { toast } from 'react-toastify'

function Login() {
  onAuthStateChanged(auth, user => {
    if (user) {
      window.location.href = "/"
    }
  })

  const [Email, setEmail] = useState('')
  const [Pass, setPass] = useState('')
  const [ViwPass, setViwPass] = useState(false)

  function HandleViwPas() {
    setViwPass(!ViwPass)
  }

  function Login() {
    if (Email === '') {
      toast.warn('Preencha o campo de email')
    } else if (Pass === '') {
      toast.warn('Preencha o campo de senha')
    } else {
      setPersistence(auth, browserSessionPersistence).then(() => {
        signInWithEmailAndPassword(auth, Email, Pass)
          .then(userCredential => {
            const user = userCredential.user
            console.log(user)
            window.location.href = '/'
          })
          .catch(error => {
            console.log(error.code)
            if (error.code === 'auth/invalid-credential') {
              toast.warn('Usuário ou senha inválidos')
            }
          })
      })
    }
  }

  return (
    <>
      <head>

        <meta property="og:title" content="CRM || kawhe" />
        <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/clientes`} />
        <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta property="og:description" content={`Login para o crm KAWHE`} />
        <meta name="description" content="Login para o crm KAWHE" />
      </head>

      <title>KAWHE | LOGIN</title>

      <main className='MainLogin'>
        <div className='CardLogin'>
          <a href='/'>
            <img src='https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa' alt='Logo'></img>
          </a>
          <h5>Email:</h5>
          <input
            type='email'
            onChange={e => setEmail(e.target.value)}
            value={Email}
            autoComplete='off'
          />
          <h5>Senha:</h5>
          <input
            type={!ViwPass ? 'password' : 'text'}
            onChange={e => setPass(e.target.value)}
            value={Pass}
            autoComplete='off'
          />
          <span onClick={HandleViwPas}>
            {' '}
            {!ViwPass ? 'Mostra senha' : 'Oucultar senha'}{' '}
          </span>
          <button onClick={Login}>Entrar</button>
          <p>--------------ou--------------</p>
          <a href='/usuarios/create'>Inscreverse</a>
        </div>
      </main>
    </>
  )
}
export default Login
