import { useEffect, useRef, useState } from "react";
import NavBar from "../../../components/NAV-BAR";
import SideNavBar from "../../../components/SIDE-NAV-BAR";
import "./Orçamento-style.css"
import { collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../config/firebase";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { deleteObject, getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useParams } from "react-router-dom";

function ViwOrçamentoCl() {

    const Param = useParams()

    const [F, setF] = useState(false)

    const toastId = useRef(null);
    const divRef = useRef(null);

    const [ClienteInfo, setClienteInfo] = useState([])
    const [SelectedClientes, setSelectedClientes] = useState("")
    const [Status, setStatus] = useState("Rascunho")
    const [isPreviewVisible, setPreviewVisible] = useState(false)

    const [Title, setTitle] = useState("")
    const [DataNaoF, setVencimento] = useState("")
    const [ano, mes, dia] = DataNaoF.split('-');
    const Vencimento = `${dia}/${mes}/${ano}`;

    const [Mensagem, setMensagem] = useState("")
    const [Termos, setTermos] = useState("")
    const [Desconto, setDesconto] = useState(0)
    const [DescontoAmont, setDescontoAmont] = useState(0)
    const [SubTotal, setSubTotal] = useState(0)
    const [Total, setTotal] = useState(0)
    const [Produtos, setProdutos] = useState([])
    const [DateEmi, setDateEmi] = useState("")
    const [NOr, setNOr] = useState("")
    const [Type, setType] = useState("")
    const [Logo] = useState("https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/favicon.png?alt=media&token=9859bd8c-6246-496f-9e2c-4deb450d85ee")

    const [SelectOR, setSelectOR] = useState("")

    const [PdfURL, setPdfURL] = useState("")

    useEffect(() => {
        if (SelectedClientes !== "") {
            const loadProjectDetails = async () => {
                try {
                    const projectDoc = doc(db, 'Clientes', SelectedClientes);
                    const projectSnapshot = await getDoc(projectDoc);
                    if (projectSnapshot.exists()) {
                        const projectData = projectSnapshot.data();
                        setClienteInfo([
                            projectData.NomeCl,
                            projectData.SubNomeCl,
                            projectData.EndRuaCl,
                            projectData.EndBairroCl,
                            projectData.EndCidadeCl,
                            projectData.EndEstadoCl,
                            projectData.EndCepCl,
                            projectData.CellNumCl,
                            projectData.EmailCl
                        ]);
                    } else {
                        toast.error("nao foi possiel achar: " + SelectedClientes)
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            loadProjectDetails();
        }
    }, [SelectedClientes]);

    function GoToAddOr() {
        window.location.href = "/crm/orçamentos/add"
    }

    const [Orçamentos, setOrçamentos] = useState([])

    useEffect(() => {
        const q = query(collection(db, "Orçamentos/"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setOrçamentos(todosArr);
        });
        return () => unsubscribe();
    }, []);

    const [optVisibleIndex, setOptVisibleIndex] = useState(null);
    const [Enviado, setEnviado] = useState(false);

    function TogleOPTBox(index, N) {
        let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === N; });
        setSelectOR(N)
        if (optVisibleIndex === index) {
            setOptVisibleIndex(null);
        } else {
            setOptVisibleIndex(index);
            if (F1[0].Status === "Enviada") {
                setEnviado(true)
                setSelectOR(N)
            } else {
                setEnviado(false)
                setSelectOR(N)
            }
        }
    };

    function Editar(N) {

    }

    function Reenviar(N) {

        toastId.current = toast("Enviando Pdf", {
            autoClose: false,
            closeButton: false
        });
        let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === SelectOR; });
        if (Enviado) {
            var templateParams = {
                LINK_OR: F1[0].Link_Jpeg,
                LINK_APOR: (`https://admin.kawhealvesdossantos.com.br/crm/aceitaror/${SelectOR}`),
                N_CL: F1[0].Nome_Cliente,
                TO: F1[0].Email_Cl,
                MSG: "Novo Orçamento"
            };
            emailjs.send('service_btvbj1e', 'template_m0lp0zs', templateParams, "bTup3YaphfZrsBv1X")
                .then(function (response) {
                    if (response.status === 200) {
                        toast.update(toastId.current, {
                            render: "Enviado com sucesso",
                            type: toast.TYPE.SUCCESS,
                            autoClose: 5000,
                            closeButton: null,
                        });
                    }
                }, (e) => {
                    toast.update(toastId.current, {
                        render: (`Error inesperado: ${e}`),
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        closeButton: null,
                    });
                });
        } else {
            setStatus("Enviada");
            setSelectedClientes(F1[0].Id_Cliente)
            setProdutos(F1[0].Produtos)
            setTotal(F1[0].Total)
            setSubTotal(F1[0].SubTotal)
            setDesconto(F1[0].Desconto_Per)
            setDescontoAmont(F1[0].Desconto_Am)
            setTermos(F1[0].Termos)
            setMensagem(F1[0].Mensagem)
            setVencimento(F1[0].Vencimento)
            setTitle(F1[0].Title)
            setDateEmi(F1[0].CreatAt)
            setNOr(F1[0].N_Or)
            setType(F1[0].Type)

            setPreviewVisible(true)

            setTimeout(() => {
                const divToExport = document.querySelector('.PreviwOr');
                if (divToExport) {
                    const pdf = new jsPDF('p', 'mm', 'a4');
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    html2canvas(divToExport).then((canvas) => {
                        const dataURL = canvas.toDataURL('image/jpeg');
                        const imgWidth = pdfWidth;
                        const imgHeight = (imgWidth * divToExport.offsetHeight) / divToExport.offsetWidth;
                        pdf.addImage(dataURL, 'JPEG', 0, 0, imgWidth, imgHeight);
                        const pdfData = pdf.output('blob');
                        const filename = ("Orçamento-" + SelectOR + ".pdf");
                        const storageRef = ref(storage, "Orçamentos/PDF/" + filename);
                        const upOrPdf = uploadBytesResumable(storageRef, pdfData)
                        upOrPdf.on(
                            "state_changed",
                            snapshot => { },
                            e => {
                                toast.update(toastId.current, {
                                    render: (`Error inesperado: ${e}`),
                                    type: toast.TYPE.ERROR,
                                    autoClose: 5000,
                                    closeButton: null,
                                    onOpen: setPreviewVisible(false)
                                });
                            },
                            () => {
                                getDownloadURL(upOrPdf.snapshot.ref).then(url => {
                                    const TasksRef = doc(db, 'Orçamentos', SelectOR);
                                    try {
                                        updateDoc(TasksRef, {
                                            Link_Pdf: url,
                                            Status: "Enviada",
                                            Link_Jpeg: "",
                                        }).then(() => {
                                            function dataURLtoBlob(dataURL) {
                                                const arr = dataURL.split(',');
                                                const mime = arr[0].match(/:(.*?);/)[1];
                                                const bstr = atob(arr[1]);
                                                let n = bstr.length;
                                                const u8arr = new Uint8Array(n);
                                                while (n--) {
                                                    u8arr[n] = bstr.charCodeAt(n);
                                                }
                                                return new Blob([u8arr], { type: mime });
                                            }
                                            html2canvas(divToExport).then((canvas) => {
                                                const dataURL = canvas.toDataURL('image/png');
                                                const filename = ("Orçamento-" + SelectOR + ".png");
                                                const storageRef = ref(storage, "Orçamentos/Image/" + filename);
                                                uploadBytesResumable(storageRef, dataURLtoBlob(dataURL))
                                                    .then((snapshot) => {
                                                        return getDownloadURL(storageRef)
                                                            .then((url) => {
                                                                try {
                                                                    updateDoc(TasksRef, {
                                                                        Link_Jpeg: url,
                                                                    })
                                                                } catch (e) {
                                                                    toast.update(toastId.current, {
                                                                        render: (`Error inesperado: ${e}`),
                                                                        type: toast.TYPE.ERROR,
                                                                        autoClose: 5000,
                                                                        closeButton: null,
                                                                        onOpen: setPreviewVisible(false)
                                                                    });
                                                                }

                                                                var templateParams = {
                                                                    LINK_OR: F1[0].Link_Jpeg,
                                                                    LINK_APOR: (`https://admin.kawhealvesdossantos.com.br/crm/aceitaror/${SelectOR}`),
                                                                    N_CL: F1[0].Nome_Cliente,
                                                                    TO: F1[0].Email_Cl,
                                                                    MSG: "Novo Orçamentos"
                                                                };

                                                                emailjs.send('service_btvbj1e', 'template_m0lp0zs', templateParams, "bTup3YaphfZrsBv1X")
                                                                    .then(function (response) {
                                                                        if (response.status === 200) {
                                                                            toast.update(toastId.current, {
                                                                                render: "Enviado com sucesso",
                                                                                type: toast.TYPE.SUCCESS,
                                                                                autoClose: 5000,
                                                                                closeButton: null,
                                                                                onOpen: setPreviewVisible(false)
                                                                            });
                                                                        }
                                                                    }, (e) => {
                                                                        toast.update(toastId.current, {
                                                                            render: (`Error inesperado: ${e}`),
                                                                            type: toast.TYPE.ERROR,
                                                                            autoClose: 5000,
                                                                            closeButton: null,
                                                                            onOpen: setPreviewVisible(false)
                                                                        });
                                                                    });

                                                            })
                                                    })
                                            })
                                        })
                                    } catch (e) {
                                        toast.update(toastId.current, {
                                            render: (`Error inesperado: ${e}`),
                                            type: toast.TYPE.ERROR,
                                            autoClose: 5000,
                                            closeButton: null,
                                            onOpen: setPreviewVisible(false)
                                        });
                                    }
                                })
                            }
                        )
                    });
                } else {
                    toast.update(toastId.current, {
                        render: (`A div com a classe 'PreviwOr' não foi encontrada.`),
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        closeButton: null,
                    });
                }
            }, 3000);
        }
    }

    function AbaixarPDF(N) {
        let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === SelectOR; });
        const pdfUrl = F1[0].Link_Pdf;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = (`ORÇAMENTO-${F1[0].N_Or}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function Faturar(N) {

        let F1 = Orçamentos.filter(function (obj) { return obj.N_Or === SelectOR; });

        setSelectedClientes(F1[0].Id_Cliente)
        setProdutos(F1[0].Produtos)
        setTotal(F1[0].Total)
        setSubTotal(F1[0].SubTotal)
        setDesconto(F1[0].Desconto_Per)
        setDescontoAmont(F1[0].Desconto_Am)
        setTermos(F1[0].Termos)
        setMensagem(F1[0].Mensagem)
        setVencimento(F1[0].Vencimento)
        setTitle(F1[0].Title)
        setDateEmi(F1[0].CreatAt)
        setNOr(F1[0].N_Or)
        setType(F1[0].Type)


        setStatus("Faturada");
        setPreviewVisible(true)

        console.log(SelectOR)

        toastId.current = toast("Faturando", {
            autoClose: false,
            closeButton: false
        });

        setTimeout(() => {
            const divToExport = document.querySelector('.PreviwOr');

            if (divToExport) {
                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const scale = "6";

                html2canvas(divToExport, { scale: scale }).then((canvas) => {
                    const dataURL = canvas.toDataURL('image/jpeg');
                    const imgWidth = pdfWidth;
                    const imgHeight = (imgWidth * divToExport.offsetHeight) / divToExport.offsetWidth;
                    pdf.addImage(dataURL, 'JPEG', 0, 0, imgWidth, imgHeight);
                    const pdfData = pdf.output('blob');
                    const filename = ("Fatura-" + SelectOR + ".pdf");
                    const storageRef = ref(storage, "Faturas/PDF/" + filename);
                    uploadBytes(storageRef, pdfData).then((snapshot) => {
                        console.log('PDF enviado com sucesso para o Firebase Storage:', snapshot);
                        return getDownloadURL(storageRef);
                    }).then((url) => {
                        setPdfURL(url);
                        const TasksRef = doc(db, 'Faturas', SelectOR);
                        try {
                            setDoc(TasksRef, {
                                Email_Cl: F1[0].Email_Cl,
                                Link_Pdf: url,
                                Id_Cliente: F1[0].Id_Cliente,
                                Nome_Cliente: F1[0].Nome_Cliente,
                                N_Or: F1[0].N_Or,
                                Produtos: F1[0].Produtos,
                                Title: F1[0].Title,
                                Status: "Faturada",
                                Total: F1[0].Total,
                                SubTotal: F1[0].SubTotal,
                                Vencimento: F1[0].Vencimento,
                                Mensagem: F1[0].Mensagem,
                                Termos: F1[0].Termos,
                                Desconto_Per: F1[0].Desconto_Per,
                                Desconto_Am: F1[0].Desconto_Am,
                                CreatAt: F1[0].CreatAt,
                                Link_Jpeg: "",
                                ValorAPagar: F1[0].Total,
                                ValorPago: 0,
                            }).then(() => {
                                html2canvas(divToExport, { scale: scale }).then((canvas) => {
                                    const dataURL = canvas.toDataURL('image/png');
                                    const filename = ("Fatura-" + SelectOR + ".png");
                                    const storageRef = ref(storage, "Faturas/Image/" + filename);
                                    uploadBytes(storageRef, dataURLtoBlob(dataURL)).then((snapshot) => {
                                        return getDownloadURL(storageRef)
                                            .then((url) => {
                                                console.log(url)
                                                var templateParams = {
                                                    LINK_OR: url,
                                                    N_CL: F1[0].Nome_Cliente,
                                                    TO: F1[0].Email_Cl,
                                                    MSG: "Nova Fatura",
                                                    T_ALT_FT: "Fatura"
                                                };
                                                emailjs.send('service_btvbj1e', 'template_m0lp0zs', templateParams, "bTup3YaphfZrsBv1X")
                                                    .then(function (response) {
                                                        if (response.status === 200) {
                                                            const TasksRef3 = doc(db, 'Faturas', SelectOR);
                                                            try {
                                                                updateDoc(TasksRef3, {
                                                                    Link_Jpeg: url,
                                                                })
                                                            } catch (e) {
                                                                toast.update(toastId.current, {
                                                                    render: (`Error inesperado 1: ${e}`),
                                                                    type: toast.TYPE.ERROR,
                                                                    autoClose: 5000,
                                                                    closeButton: null,
                                                                });
                                                            }

                                                            const filename1 = ("Orçamento-" + SelectOR + ".png");
                                                            const storageRef1 = ref(storage, "Orçamentos/Image/" + filename1);
                                                            const filename2 = ("Orçamento-" + SelectOR + ".pdf");
                                                            const storageRef2 = ref(storage, "Orçamentos/PDF/" + filename2);
                                                            const TasksRef360 = doc(db, 'Orçamentos', SelectOR);
                                                            deleteObject(storageRef1).then(() => {
                                                                deleteObject(storageRef2).then(() => {
                                                                    deleteDoc(TasksRef360).then(() => {
                                                                        toast.update(toastId.current, {
                                                                            render: "Faturado com sucesso",
                                                                            type: toast.TYPE.SUCCESS,
                                                                            autoClose: 5000,
                                                                            onOpen: setPreviewVisible(false),
                                                                            onClose: () => window.location.href = "/crm/faturas"
                                                                        });
                                                                    }).catch(error => {
                                                                        toast.update(toastId.current, {
                                                                            render: (`Error inesperado 1: ${error}`),
                                                                            type: toast.TYPE.ERROR,
                                                                            autoClose: 5000,
                                                                            closeButton: null,
                                                                        });
                                                                    })
                                                                }).catch(error => {
                                                                    toast.update(toastId.current, {
                                                                        render: (`Error inesperado 2: ${error}`),
                                                                        type: toast.TYPE.ERROR,
                                                                        autoClose: 5000,
                                                                        closeButton: null,
                                                                    });
                                                                })
                                                            }).catch(error => {
                                                                toast.update(toastId.current, {
                                                                    render: (`Error inesperado 3: ${error}`),
                                                                    type: toast.TYPE.ERROR,
                                                                    autoClose: 5000,
                                                                    closeButton: null,
                                                                });
                                                            })

                                                        }
                                                    }, (e) => {
                                                        toast.update(toastId.current, {
                                                            render: (`Error inesperado 4: ${e}`),
                                                            type: toast.TYPE.ERROR,
                                                            autoClose: 5000,
                                                            closeButton: null,
                                                        });
                                                    });
                                            }).catch((e) => {
                                                toast.update(toastId.current, {
                                                    render: (`Error inesperado 5: ${e}`),
                                                    type: toast.TYPE.ERROR,
                                                    autoClose: 5000,
                                                    closeButton: null,
                                                });
                                            });

                                    }).catch((e) => {
                                        toast.update(toastId.current, {
                                            render: (`Error inesperado 6: ${e}`),
                                            type: toast.TYPE.ERROR,
                                            autoClose: 5000,
                                            closeButton: null,
                                        });
                                    });
                                });
                            })
                        } catch (e) {
                            toast.update(toastId.current, {
                                render: (`Error inesperado 7: ${e}`),
                                type: toast.TYPE.ERROR,
                                autoClose: 5000,
                                closeButton: null,
                            });
                        }
                    }).catch((e) => {
                        toast.update(toastId.current, {
                            render: (`Error inesperado 8: ${e}`),
                            type: toast.TYPE.ERROR,
                            autoClose: 5000,
                            closeButton: null,
                        });
                        console.log(e)
                    });
                });
            } else {
                setStatus("Rascunho")
                toast.error(`A div com a classe 'PreviwOr' não foi encontrada.`);
            }

            // Função auxiliar para converter dataURL em Blob
            function dataURLtoBlob(dataURL) {
                const arr = dataURL.split(',');
                const mime = arr[0].match(/:(.*?);/)[1];
                const bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            }
        }, 5000)
    }

    function Excluir(N) {
        confirmAlert({
            title: "Deseja mesmo deletar este orçamento",
            message: "Este ação não tem volta",
            buttons: [
                {
                    label: "Sim",
                    onClick: () => EX()
                },
                {
                    label: "Não",
                    onClick: setOptVisibleIndex(null)
                }
            ]
        });
        function EX() {

            toastId.current = toast("Deletando orçamento", {
                autoClose: false,
                closeButton: false
            });

            const filename1 = ("Orçamento-" + SelectOR + ".png");
            const storageRef1 = ref(storage, "Orçamentos/Image/" + filename1);

            const filename2 = ("Orçamento-" + SelectOR + ".pdf");
            const storageRef2 = ref(storage, "Orçamentos/PDF/" + filename2);

            const TasksRef = doc(db, 'Orçamentos', SelectOR);

            deleteObject(storageRef1).then(() => {
                deleteObject(storageRef2).then(() => {
                    deleteDoc(TasksRef).then(() => {
                        toast.update(toastId.current, {
                            render: "Orçamento deletado com sucesso",
                            type: toast.TYPE.SUCCESS,
                            autoClose: 5000,
                            closeButton: null,

                        });
                    }).catch(error => {
                        toast.update(toastId.current, {
                            render: (`Error inesperado: ${error}`),
                            type: toast.TYPE.ERROR,
                            autoClose: 5000,
                            closeButton: null,
                        });
                    })
                }).catch(error => {
                    toast.update(toastId.current, {
                        render: (`Error inesperado: ${error}`),
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        closeButton: null,
                    });
                })
            }).catch(error => {
                toast.update(toastId.current, {
                    render: (`Error inesperado: ${error}`),
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    closeButton: null,
                });
            })

        }
    }

    document.addEventListener("keydown", (event) => {
        if (event.keyCode === 27) { //escape key
            setOptVisibleIndex(null);
        }
    })

    const OrçamentosF1 = Orçamentos.filter(objeto => objeto.Nome_Cliente === Param.IdCl)

    const HList = 50 + OrçamentosF1.length * 112

    return (
        <>
            <head>

                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/orçamentos/search/${Param.IdCl}`} />
                <meta property="og:image" content={`${ClienteInfo[9]}`} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="600" />
                <meta property="og:description" content={`Vizualizar todas os orçamentos em nome de ${Param.IdCl}`} />
                <meta name="description" content={`Vizualizar todas os orçamentos em nome de ${Param.IdCl}`} />
            </head>

            <title>KAWHE | ORÇAMENTOS | {Param.IdCl}</title>

            <NavBar />
            <main>
                <SideNavBar />
                <div className='MainOrçamentos'>
                    <section className='NavUpLoad'>
                        <h2 className='TxHeaderUpLoad'>Orçamentos <span className='QtnArquivos'>{Orçamentos.length}</span></h2>
                        <button className='BtnAddUpLoad' onClick={GoToAddOr} ><i class="fa-solid fa-plus fa-plus-pj"></i>Adicionar Orçamento</button>
                    </section>

                    <section className='ListaClientes' style={{ minHeight: `${HList}px` }}>
                        <tr className="CabecalhoFat">
                            <th>Número</th>
                            <th>Cliente</th>
                            <th>Preço total</th>
                            <th>status</th>
                            <th>Opções</th>
                        </tr>
                        {
                            OrçamentosF1
                                ?
                                OrçamentosF1.map((i, index) => (
                                    <div key={index} className="TR_OR2">
                                        <div className="Line_OR"></div>
                                        <tr className="TR_or">
                                            <td><a href={`/crm/orçamentos/${i.N_Or}`} className="TR-OR Ft">{i.N_Or}</a></td>
                                            <td>{i.Nome_Cliente}</td>
                                            <td>R$ {i.Total}</td>
                                            <td>{i.Status}</td>
                                            <td>
                                                <div className={`OPT OPT${index}`} style={{ display: optVisibleIndex === index ? 'block' : 'none' }}>
                                                    {/* <button onClick={() => Editar(i.N_Or)}>Editar</button> */}
                                                    <button onClick={() => Reenviar(i.N_Or)}>{Enviado ? "Reenviar" : "Enviar"}</button>
                                                    <button onClick={() => AbaixarPDF(i.N_Or)}>Abaixar PDF</button>
                                                    <button onClick={() => Faturar(i.N_Or)}>Faturar</button>
                                                    <button onClick={() => Excluir(i.N_Or)}>Excluir</button>
                                                </div>
                                                <button className="BTN_OPT_OR" onClick={() => TogleOPTBox(index, i.N_Or)}>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                </button>
                                            </td>
                                        </tr>
                                    </div>
                                ))
                                :
                                <tr>
                                    <td>
                                        <svg className='Loading'
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="3em"
                                            viewBox="0 0 512 512">
                                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                                        </svg>
                                    </td>
                                </tr>
                        }
                    </section>

                </div>

                {isPreviewVisible && (
                    <div className="PreviewOrçamento">
                        <div ref={divRef} className="PreviwOr">
                            <section>
                                <div className="PreviewHeader">
                                    <div className="PreviewHeader1">
                                        <div className="Logo">
                                            <img className="LogoImg" src={Logo} alt="Logo" />
                                        </div>
                                        <div className="PerInfo">
                                            <p className="MyName">KAWHE ALVES DOS SANTOS</p>
                                            <p>Brazil</p>
                                            <p>cantato@kawhealvesdossantos.com.br</p>
                                            <p>Telefone: +55 69 9 93172767</p>
                                        </div>
                                    </div>
                                    <div className="InfoPreviwOr">
                                        <p
                                            className={`StatusOr  
                                            ${Status === "Enviada" ? 'STEnviada'
                                                    :
                                                    Status === "Rascunho" ? "STRascunho"
                                                        :
                                                        Status === "Faturada" ? "STEnviada"
                                                            :
                                                            ""
                                                }`}>{Status}</p>
                                        <p>{Type} Nº: {NOr}</p>
                                        <p>Emitida em: {DateEmi}</p>
                                        <p>Data de vencimento: {Vencimento}</p>
                                    </div>
                                </div>
                                <div className="DTitleOr">
                                    <p>{Title}</p>
                                </div>
                                <div className="InfoCl">
                                    <p className="InfoClp1">{ClienteInfo[0] + " " + ClienteInfo[1]}</p>
                                    <p className="InfoClp2">{ClienteInfo[2] + " " + ClienteInfo[3]}</p>
                                    <p className="InfoClp3">{ClienteInfo[4] + " " + ClienteInfo[5] + " " + ClienteInfo[6]}</p>
                                    <p className="InfoClp4">{ClienteInfo[7]}</p>
                                </div>
                            </section>
                            <section>
                                <div className="HeaderOrPreços">
                                    <p>Serviço</p>
                                    <p>Preço (R$)</p>
                                    <p>Quantidade</p>
                                    <p>Total</p>
                                </div>
                                {Produtos &&
                                    Produtos.map((p, index) => (
                                        <div key={index} className="ListPrOr">
                                            <div className="Pr Cw">
                                                <p className="PrP">{p.produto}</p>
                                                <p className="PrC">{p.descrição}</p>
                                            </div>
                                            <p className="Cw">{p.preço}</p>
                                            <p className="Cw">{p.qt}</p>
                                            <p className="Cw">{p.total}</p>
                                        </div>
                                    ))
                                }
                                <div className="LinePreviewOr"></div>
                            </section>
                            <section className="TotaisPreviwOr">
                                <div className="DTotaisPreviwOr">
                                    <div>
                                        <p>SubTotal</p>
                                        <p>{SubTotal}</p>
                                    </div>
                                    {Desconto !== 0 &&
                                        <div>
                                            <p>Desconto</p>
                                            <p>{Desconto}%</p>
                                            <p>{DescontoAmont.toFixed(2)}</p>
                                        </div>
                                    }
                                    <div className="DTotalOr">
                                        <p>Total {F ? "a pagar" : ""}</p>
                                        <p>{Total}</p>
                                    </div>
                                </div>
                            </section>
                            <section className="MensagensPreviewOr">
                                <div>
                                    {Mensagem !== "" &&
                                        <div className="DMensagenOr">
                                            <p>Mensagens:</p>
                                            <div className="DMSGOR">
                                                <p className="MSGOR">{Mensagem}</p>
                                            </div>
                                        </div>
                                    }

                                    {Termos !== "" &&
                                        <div>
                                            <p>Termos Legais:</p>
                                            <div className="DTROR">
                                                <p>{Termos}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </main >
        </>
    )
}
export default ViwOrçamentoCl;