import { useState } from "react";
import NavBar from "../../../components/NAV-BAR";
import "./AddProjetos-Style.css"
import { toast } from "react-toastify";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../config/firebase";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

function AddProjetos() {

  const [previewImage, setPreviewImage] = useState(null);
  const [Saving, setSaving] = useState(false);

  const [FileCapa, setFileCapa] = useState(false)

  const [Name, setName] = useState("")
  const [Type, setType] = useState("")
  const [Estructure, setEstructure] = useState("")
  const [From, setFrom] = useState("")
  const [FinalProject, setFinalProject] = useState("")
  const [PreProject, setPreProject] = useState("")
  const [Render, setRender] = useState("")
  const [Pdf, setPdf] = useState("")
  const [Date, setDate] = useState("")
  const [VideoUrl, setVideoUrl] = useState("")

  const [FotosRender, setFotosRender] = useState(false)
  const [FotosProjetoARQ, setFotosProjetoARQ] = useState(false)
  const [FotosProjetoMEC, setFotosProjetoMEC] = useState(false)
  const [FotosProjetoEST, setFotosProjetoEST] = useState(false)
  const [FotosProjetoHID, setFotosProjetoHID] = useState(false)
  const [FotosProjetoELE, setFotosProjetoELE] = useState(false)
  const [FotosProjetoPPCIP, setFotosProjetoPPCIP] = useState(false)

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    if (e.target.files[0]) {
      setFileCapa(e.target.files[0]);
    }
  };

  function HandleChangRender(e) {
    setFotosRender(Array.from(e.target.files))
  }

  function HandleChangProjetoARQ(e) {
    setFotosProjetoARQ(Array.from(e.target.files))
  }

  function HandleChangProjetoMEC(e) {
    setFotosProjetoMEC(Array.from(e.target.files))
  }

  function HandleChangProjetoEST(e) {
    setFotosProjetoEST(Array.from(e.target.files))
  }

  function HandleChangProjetoHID(e) {
    setFotosProjetoHID(Array.from(e.target.files))
  }

  function HandleChangProjetoELE(e) {
    setFotosProjetoELE(Array.from(e.target.files))
  }

  function HandleChangProjetoPPCIP(e) {
    setFotosProjetoPPCIP(Array.from(e.target.files))
  }

  const [StatuSave, setStatusSave] = useState([
    { Name: "informaçãoes", Value: "stand" },
    { Name: "render", Value: "stand" },
    { Name: "arq", Value: "stand" },
    { Name: "est", Value: "stand" },
    { Name: "hid", Value: "stand" },
    { Name: "ele", Value: "stand" },
    { Name: "ppcip", Value: "stand" },
    { Name: "mec", Value: "stand" },
    { Name: "capa", Value: "stand" }
  ])

  function Save() {
    if (!Name) {
      toast.warn("Preencha a campo 'Nome' para poder savar o projetos")
    } else if (!FileCapa) {
      toast.warn("Selecione uma capa para poder salvar")
    } else if (!FotosRender & !FotosProjetoPPCIP & !FotosProjetoMEC & FotosProjetoHID & !FotosProjetoEST & !FotosProjetoELE & !FotosProjetoARQ) {
      toast.warn("Selecione pele menos uma foto para poder salvar")
    } else if (!Type) {
      toast.warn("Preencha a campo 'Tipo' para poder savar o projetos")
    } else if (!Estructure) {
      toast.warn("Preencha a campo 'Estrtutura' para poder savar o projetos")
    } else if (!From) {
      toast.warn("Preencha a campo 'Para' para poder savar o projetos")
    } else if (!FinalProject) {
      toast.warn("Preencha a campo 'Projeto final' para poder savar o projetos")
    } else if (!PreProject) {
      toast.warn("Preencha a campo 'Pré projeto' para poder savar o projetos")
    } else if (!Render) {
      toast.warn("Preencha a campo 'Render' para poder savar o projetos")
    } else if (!Pdf) {
      toast.warn("Preencha a campo 'Pdf' para poder savar o projetos")
    } else if (!Date) {
      toast.warn("Preencha a campo 'Data' para poder savar o projetos")
    } else {
      setSaving(true)
      const NP = [...StatuSave]
      NP[0].Value = "InProgress"
      setStatusSave(NP)

      const TasksRef = doc(db, 'Projetos', Name);
      const id = uuidv4()
      try {
        setDoc(TasksRef, {
          Id: id,
          Name: Name,
          Type: Type,
          From: From,
          FinalProject: FinalProject,
          Estructure: Estructure,
          PreProject: PreProject,
          Render: Render,
          Pdf: Pdf,
          Date: Date,
          Url_Video: VideoUrl,
          Url_Photo: "",
          Url_Capa: ""
        }).then(() => {
          const NP1 = [...StatuSave]
          NP1[0].Value = "Ready"
          setStatusSave(NP1)


          if (FileCapa) {
            const NP2 = [...StatuSave]
            NP2[8].Value = "InProgress"
            setStatusSave(NP2)
            const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/capa/" + FileCapa.name)
            const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, FileCapa)
            UpLoadPhoto.on(
              "state_changed",
              snapshot => {
              },
              error => {
                alert(error)
              },
              () => {
                getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                  try {
                    updateDoc(TasksRef, {
                      Url_Capa: url,
                    }).then(() => {
                      const NP3 = [...StatuSave]
                      NP3[8].Value = "Ready"
                      setStatusSave(NP3)
                    })
                  } catch (e) {
                    setSaving(false)
                    toast.error("Algo deu errado ao salvar a capa")
                  }
                })
              }
            )
          }

          let URLarray = [];

          if (FotosRender.length > 0) {
            const NP4 = [...StatuSave]
            NP4[1].Value = "InProgress"
            setStatusSave(NP4)
            FotosRender.map((i) => {
              const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/fotos/" + i.name)
              const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, i)
              UpLoadPhoto.on(
                "state_changed",
                snapshot => {
                },
                error => {
                  alert(error)
                },
                () => {
                  getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                    URLarray.push({ url: url, name: i.name, Type: "RENDER" })
                    try {
                      updateDoc(TasksRef, {
                        Url_Photo: URLarray,
                      }).then(() => {
                        const NP5 = [...StatuSave]
                        NP5[1].Value = "Ready"
                        setStatusSave(NP5)
                      })
                    } catch (e) {
                      setSaving(false)
                      toast.error("Algo deu errado ao salvar as fotos de render")
                    }
                  })
                }
              )
            })

          }

          if (FotosProjetoARQ.length > 0) {
            const NP6 = [...StatuSave]
            NP6[2].Value = "InProgress"
            setStatusSave(NP6)
            FotosProjetoARQ.map((i) => {
              const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/fotos/" + i.name)
              const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, i)
              UpLoadPhoto.on(
                "state_changed",
                snapshot => {
                },
                error => {
                  alert(error)
                },
                () => {
                  getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                    URLarray.push({ url: url, name: i.name, Type: "ARQ." })
                    try {
                      updateDoc(TasksRef, {
                        Url_Photo: URLarray,
                      }).then(() => {
                        const NP15 = [...StatuSave]
                        NP15[2].Value = "Ready"
                        setStatusSave(NP15)
                      })
                    } catch (e) {
                      setSaving(false)
                      toast.error("Algo deu errado ao salvar as pranchas de arquitetura")
                    }
                  })
                }
              )
            })
          }

          if (FotosProjetoEST.length > 0) {
            const NP7 = [...StatuSave]
            NP7[3].Value = "InProgress"
            setStatusSave(NP7)
            FotosProjetoEST.map((i) => {
              const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/fotos/" + i.name)
              const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, i)
              UpLoadPhoto.on(
                "state_changed",
                snapshot => {
                },
                error => {
                  alert(error)
                },
                () => {
                  getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                    URLarray.push({ url: url, name: i.name, Type: "EST." })
                    try {
                      updateDoc(TasksRef, {
                        Url_Photo: URLarray,
                      }).then(() => {
                        const NP8 = [...StatuSave]
                        NP8[3].Value = "Ready"
                        setStatusSave(NP8)
                      })
                    } catch (e) {
                      setSaving(false)
                      toast.error("Algo deu errado ao salvar as pranchas de estrutural")
                    }
                  })
                }
              )
            })
          }

          if (FotosProjetoHID.length > 0) {
            const NP9 = [...StatuSave]
            NP9[4].Value = "InProgress"
            setStatusSave(NP9)
            FotosProjetoHID.map((i) => {
              const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/fotos/" + i.name)
              const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, i)
              UpLoadPhoto.on(
                "state_changed",
                snapshot => {
                },
                error => {
                  alert(error)
                },
                () => {
                  getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                    URLarray.push({ url: url, name: i.name, Type: "HID." })
                    try {
                      updateDoc(TasksRef, {
                        Url_Photo: URLarray,
                      }).then(() => {
                        const NP10 = [...StatuSave]
                        NP10[4].Value = "Ready"
                        setStatusSave(NP10)
                      })
                    } catch (e) {
                      setSaving(false)
                      toast.error("Algo deu errado ao salvar as pranchas de hidraulica")
                    }
                  })
                }
              )
            })
          }

          if (FotosProjetoELE.length > 0) {
            const NP11 = [...StatuSave]
            NP11[5].Value = "InProgress"
            setStatusSave(NP11)
            FotosProjetoELE.map((i) => {
              const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/fotos/" + i.name)
              const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, i)
              UpLoadPhoto.on(
                "state_changed",
                snapshot => {
                },
                error => {
                  alert(error)
                },
                () => {
                  getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                    URLarray.push({ url: url, name: i.name, Type: "ELE." })
                    try {
                      updateDoc(TasksRef, {
                        Url_Photo: URLarray,
                      }).then(() => {
                        const NP12 = [...StatuSave]
                        NP12[5].Value = "Ready"
                        setStatusSave(NP12)
                      })
                    } catch (e) {
                      setSaving(false)
                      toast.error("Algo deu errado ao salvar as pranchas de eletrica")
                    }
                  })
                }
              )
            })
          }

          if (FotosProjetoPPCIP.length > 0) {
            const NP13 = [...StatuSave]
            NP13[6].Value = "InProgress"
            setStatusSave(NP13)
            FotosProjetoPPCIP.map((i) => {
              const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/fotos/" + i.name)
              const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, i)
              UpLoadPhoto.on(
                "state_changed",
                snapshot => {
                },
                error => {
                  alert(error)
                },
                () => {
                  getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                    URLarray.push({ url: url, name: i.name, Type: "PPCIP" })
                    try {
                      updateDoc(TasksRef, {
                        Url_Photo: URLarray,
                      }).then(() => {
                        const NP14 = [...StatuSave]
                        NP14[6].Value = "Ready"
                        setStatusSave(NP14)
                      })
                    } catch (e) {
                      setSaving(false)
                      toast.error("Algo deu errado ao salvar as pranchas de ppcip")
                    }
                  })
                }
              )
            })
          }

          if (FotosProjetoMEC.length > 0) {
            const NP15 = [...StatuSave]
            NP15[7].Value = "InProgress"
            setStatusSave(NP15)
            FotosProjetoMEC.map((i) => {
              const StorageRefPhoto = ref(storage, "Projetos/imagens/" + Name + "/fotos/" + i.name)
              const UpLoadPhoto = uploadBytesResumable(StorageRefPhoto, i)
              UpLoadPhoto.on(
                "state_changed",
                snapshot => {
                },
                error => {
                  alert(error)
                },
                () => {
                  getDownloadURL(UpLoadPhoto.snapshot.ref).then(url => {
                    URLarray.push({ url: url, name: i.name, Type: "MEC." })
                    try {
                      updateDoc(TasksRef, {
                        Url_Photo: URLarray,
                      }).then(() => {
                        const NP16 = [...StatuSave]
                        NP16[7].Value = "Ready"
                        setStatusSave(NP16)
                      })
                    } catch (e) {
                      setSaving(false)
                      toast.error("Algo deu errado ao salvar as pranchas de mecânica")
                    }
                  })
                }
              )
            })
          }

        })
      } catch (e) {
        setSaving(false)
        toast.error("Algo deu errado ao salvar as informações")
      } finally {
        // setSaving(false)
        toast.success("Salvo com sucesso")
        // window.location.href = "/projetos"
      }
    }
  }

  return (
    <>
      <head>
        <meta property="og:title" content="PORTIFOLIO || kawhe" />
        <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/projetos/addprojetos`} />
        <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta property="og:description" content={`Adiconar projeto ao portifolio`} />
        <meta name="description" content="Adiconar projeto ao portifolio" />
      </head>
      <title>KAWHE | PROJETOS | ADICIONAR</title>
      <NavBar />
      <div className='SecundMain'>
        <div className="MainAddProjetos">
          <section className="SideLeft">
            <div className="InpName Inp">
              <label htmlFor="Name">Nome:</label>
              <input type="text" name="Name" id="Name" onChange={e => setName(e.target.value)} value={Name} />
            </div>
            <div className="InpType Inp">
              <label htmlFor="Type">Tipo do projeto:</label>
              <input type="text" name="Type" id="Type" onChange={e => setType(e.target.value)} value={Type} />
            </div>
            <div className="InpEstructure Inp">
              <label htmlFor="Estructure">Estrutura:</label>
              <input type="text" name="Estructure" id="Estructure" onChange={e => setEstructure(e.target.value)} value={Estructure} />
            </div>
            <div className="InpFrom Inp">
              <label htmlFor="From">Feito para:</label>
              <input type="text" name="From" id="From" onChange={e => setFrom(e.target.value)} value={From} />
            </div>
            <div className="InpFinalProject Inp">
              <label htmlFor="FinalProject">Projeto final:</label>
              <input type="text" name="FinalProject" id="FinalProject" onChange={e => setFinalProject(e.target.value)} value={FinalProject} />
            </div>
            <div className="InpPreProject Inp">
              <label htmlFor="PreProject">Pré projeto:</label>
              <input type="text" name="PreProject" id="PreProject" onChange={e => setPreProject(e.target.value)} value={PreProject} />
            </div>
            <div className="InpRender Inp">
              <label htmlFor="Render">Render:</label>
              <input type="text" name="Render" id="Render" onChange={e => setRender(e.target.value)} />
            </div>
            <div className="InpPdf Inp">
              <label htmlFor="Pdf">Pdf:</label>
              <input type="text" name="Pdf" id="Pdf" onChange={e => setPdf(e.target.value)} value={Pdf} />
            </div>
            <div className="InpDate Inp">
              <label htmlFor="Date">Data:</label>
              <input type="Date" name="Date" id="Date" onChange={e => setDate(e.target.value)} value={Date} />
            </div>
          </section>
          <section className="SideMidle" >
            <div>
              <label htmlFor="Capa">
                <div>
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Preview"
                      className="preview-image"
                      style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                    />
                  ) :
                    <span>Selecione um imagem</span>
                  }
                </div>
              </label>
              <input type="file" name="Capa" id="Capa" onChange={handleImageChange} accept="image/*" />
            </div>
            <div className="InpArq Inp">
              <label htmlFor="FotosArq">Fotos das pranchas Arquitêtonica <span>{!FotosProjetoARQ ? "0" : FotosProjetoARQ.length}</span> </label>
              <input type="file" name="FotosArq" id="FotosArq" multiple accept="image/*" onChange={HandleChangProjetoARQ} />
            </div>
            <div className="InpEst Inp">
              <label htmlFor="FotosEst">Fotos das pranchas Estrutural <span>{!FotosProjetoEST ? "0" : FotosProjetoEST.length}</span> </label>
              <input type="file" name="FotosEst" id="FotosEst" multiple accept="image/*" onChange={HandleChangProjetoEST} />
            </div>
            <div className="InpHid Inp">
              <label htmlFor="FotosHid">Fotos das pranchas Hidraulica <span>{!FotosProjetoHID ? "0" : FotosProjetoHID.length}</span> </label>
              <input type="file" name="FotosHid" id="FotosHid" multiple accept="image/*" onChange={HandleChangProjetoHID} />
            </div>
            <div className="InpEle Inp">
              <label htmlFor="FotosEle">Fotos das pranchas Eletrica <span>{!FotosProjetoELE ? "0" : FotosProjetoELE.length}</span> </label>
              <input type="file" name="FotosEle" id="FotosEle" multiple accept="image/*" onChange={HandleChangProjetoELE} />
            </div>
            <div className="InpPpcip Inp">
              <label htmlFor="FotosPpcip">Fotos das pranchas PPCIP <span>{!FotosProjetoPPCIP ? "0" : FotosProjetoPPCIP.length}</span> </label>
              <input type="file" name="FotosPpcip" id="FotosPpcip" multiple accept="image/*" onChange={HandleChangProjetoPPCIP} />
            </div>
            <div className="InpMec Inp">
              <label htmlFor="FotosMec">Fotos das pranchas Mecânica <span>{!FotosProjetoMEC ? "0" : FotosProjetoMEC.length}</span> </label>
              <input type="file" name="FotosMec" id="FotosMec" multiple accept="image/*" onChange={HandleChangProjetoMEC} />
            </div>
            <div className="InpRender Inp">
              <label htmlFor="FotosRender">Fotos dos Render <span>{!FotosRender ? "0" : FotosRender.length}</span> </label>
              <input type="file" name="FotosRender" id="FotosRender" multiple accept="image/*" onChange={HandleChangRender} />
            </div>
          </section>
          <section className="SideRight">
            <div className="InpVideo Inp">
              <label htmlFor="Video">Link do video:</label>
              <input type="text" name="Video" id="Video" onChange={e => setVideoUrl(e.target.value)} value={VideoUrl} />
            </div>
            <div>
              <button onClick={Save}>Salvar</button>
            </div>
          </section>

          {
            Saving &&
            <div className="Fade">
              <div className="SaveIndicator">
                <h3 className="Hearde">Salvando projeto</h3>

                <p>
                  {
                    StatuSave[0].Value === "stand" &&
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  }
                  {
                    StatuSave[0].Value === "InProgress" &&
                    <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                  }
                  {
                    StatuSave[0].Value === "Ready" &&
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                  }
                  <span>Informações</span>
                </p>

                <p>
                  {
                    StatuSave[1].Value === "stand" &&
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  }
                  {
                    StatuSave[1].Value === "InProgress" &&
                    <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                  }
                  {
                    StatuSave[1].Value === "Ready" &&
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                  }
                  <span>Capa</span>
                </p>


                {
                  FotosRender.length > 0 &&
                  <p>
                    {
                      StatuSave[1].Value === "stand" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                    }
                    {
                      StatuSave[1].Value === "InProgress" &&
                      <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                    }
                    {
                      StatuSave[1].Value === "Ready" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    }
                    <span>Render</span>
                  </p>
                }

                {
                  FotosProjetoARQ.length > 0 &&
                  <p>
                    {
                      StatuSave[2].Value === "stand" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                    }
                    {
                      StatuSave[2].Value === "InProgress" &&
                      <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                    }
                    {
                      StatuSave[2].Value === "Ready" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    }
                    <span>Arquitêtonico</span>
                  </p>
                }

                {
                  FotosProjetoEST.length > 0 &&
                  <p>
                    {
                      StatuSave[3].Value === "stand" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                    }
                    {
                      StatuSave[3].Value === "InProgress" &&
                      <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                    }
                    {
                      StatuSave[3].Value === "Ready" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    }
                    <span>Estrutural</span>
                  </p>
                }

                {
                  FotosProjetoHID.length > 0 &&
                  <p>
                    {
                      StatuSave[4].Value === "stand" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                    }
                    {
                      StatuSave[4].Value === "InProgress" &&
                      <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                    }
                    {
                      StatuSave[4].Value === "Ready" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    }
                    <span>Hidraulico</span>
                  </p>
                }

                {
                  FotosProjetoELE.length > 0 &&
                  <p>
                    {
                      StatuSave[5].Value === "stand" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                    }
                    {
                      StatuSave[5].Value === "InProgress" &&
                      <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                    }
                    {
                      StatuSave[5].Value === "Ready" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    }
                    <span>Eletrico</span>
                  </p>
                }

                {
                  FotosProjetoPPCIP.length > 0 &&
                  <p>
                    {
                      StatuSave[6].Value === "stand" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                    }
                    {
                      StatuSave[6].Value === "InProgress" &&
                      <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                    }
                    {
                      StatuSave[6].Value === "Ready" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    }
                    <span>PPCIP</span>
                  </p>
                }

                {
                  FotosProjetoMEC.length > 0 &&
                  <p>
                    {
                      StatuSave[7].Value === "stand" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                    }
                    {
                      StatuSave[7].Value === "InProgress" &&
                      <svg className="LoadinSave" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                    }
                    {
                      StatuSave[7].Value === "Ready" &&
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    }
                    <span>Mecânico</span>
                  </p>
                }

              </div>
            </div>
          }

        </div>
      </div>

    </>
  )
}
export default AddProjetos;