import { useState } from "react";
import NavBar from "../../components/NAV-BAR";
import SideNavBar from "../../components/SIDE-NAV-BAR";
import "./Calc-Hid_Style.css"

function Calc_Hid() {

    const [Hid, setHid] = useState([])
    const [Select, setSelect] = useState("")

    const HList = 50 + Hid.length * 112

    const [optVisibleIndex, setOptVisibleIndex] = useState(null);

    function TogleOPTBox(index, N) {
        setSelect(N)
        if (optVisibleIndex === index) {
            setOptVisibleIndex(null);
        } else {
            setOptVisibleIndex(index);
        }
    };

    return (
        <>
            <NavBar />
            <main>
                <SideNavBar/>
                <div className="MainCalcHid">
                <section className='NavUpLoad'>
                        <h2 className='TxHeaderUpLoad'>Faturas <span className='QtnArquivos'>{Hid.length}</span></h2>
                        <button className='BtnAddUpLoad' ><i class="fa-solid fa-plus fa-plus-pj"></i>Adicionar Faturas</button>
                    </section>

                    <section className='ListaFaturas' style={{ minHeight: `${HList}px` }}>
                        <tr className="CabecalhoFat">
                            <th>Ref</th>
                            <th>Cliente</th>
                            <th>Opções</th>
                        </tr>
                        {
                            Hid
                                ?
                                Hid.map((i, index) => (
                                    <div key={index} className="TR_OR2">
                                        <div className="Line_OR"></div>
                                        <tr className="TR_or">
                                            <td><a href={`/crm/Faturas/${i.N_Or}`} className="TR-OR  Ft">{i.N_Or}</a></td>
                                            <td>{i.Nome_Cliente}</td>
                                            <td>
                                                <div className={`OPT OPT${index}`} style={{ display: optVisibleIndex === index ? 'block' : 'none' }}>
                                                    <button>Reenviar</button>
                                                </div>
                                                <button className="BTN_OPT_OR" onClick={() => TogleOPTBox(index, i.N_Or)}>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                    <div className="CIRCLE_BTN_OR"></div>
                                                </button>
                                            </td>
                                        </tr>
                                    </div>
                                ))
                                :
                                <tr>
                                    <td>
                                        <svg className='Loading'
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="3em"
                                            viewBox="0 0 512 512">
                                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                                        </svg>
                                    </td>
                                </tr>
                        }
                    </section>
                </div>
            </main>
        </>
    )
}
export default Calc_Hid;