import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where
} from 'firebase/firestore'
import NavTaskManager from '../../../components/NavTaskManager'
import './TaskManager-InProgress-Style.css'
import { auth, db } from '../../../config/firebase'
import { useEffect, useState } from 'react'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import NavBar from '../../../components/NAV-BAR'
import SideNavBar from '../../../components/SIDE-NAV-BAR'

function TaskManagerInProgress() {
  onAuthStateChanged(auth, user => {
    if (!user) {
      window.location.href = '/login'
    }
    if (
      user.uid === 'pT9QAfGunpZ3Abzl1PrRxeW9v5Q2' ||
      user.uid === 'RaugT4w726WAhUvG8H5nUyi9e493'
    ) {
    } else {
      signOut(auth)
        .then(() => {
          window.location.href = '/login'
        })
        .catch(error => {
          console.log(error)
        })
    }
  })

  const [Tasks, setTask] = useState([])

  useEffect(() => {
    const q = query(collection(db, 'Tasks'), where('Status', '==', 'Progress'))
    const unsubscribe = onSnapshot(q, querySnapshot => {
      let todosArr = []
      querySnapshot.forEach(doc => {
        todosArr.push({ ...doc.data(), id: doc.id })
      })
      setTask(todosArr)
    })
    return () => unsubscribe()
  }, [])

  function MoveTaskToReady(Task) {
    const TasksRef = doc(db, 'Tasks', Task)
    updateDoc(TasksRef, {
      Status: 'Ready'
    })
  }

  function MoveToCanceled(Task) {
    const TasksRef = doc(db, 'Tasks', Task)
    updateDoc(TasksRef, {
      Status: 'Canceled'
    })
  }

  return (
    <>
      <head>

        <meta property="og:title" content="Task || kawhe" />
        <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/clientes`} />
        <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta property="og:description" content={`Tarefas em progresso`} />
        <meta name="description" content="Tarefas em progresso" />
      </head>

      <title>KAWHE | TASKMANAGER | EM PROGRESSO</title>
      <NavBar />
      <main>
        <SideNavBar />
        <div className='MainTasks'>
          <NavTaskManager />
          <section className='TaskManager-InProgress'>
            {Tasks.map(tasks => (
              <div className={`CardListTaskManagerInProgress`} key={tasks.Id}>
                <h1 className='TaskName'>{tasks.name}</h1>
                <div className='LineTaskInProgress'></div>
                <div className='BtnsTaskManager'>
                  <div className='CreatAt'>{tasks.startTime} - {tasks.endTime}</div>
                  <div className='Term'>{tasks.day} / {tasks.month+1} / {tasks.year}</div>
                  <button
                    className='UpToReady'
                    onClick={() => MoveTaskToReady(tasks.name)}
                    id={tasks.Id}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='2.5em'
                      viewBox='0 0 448 512'
                    >
                      <path d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z' />
                    </svg>
                  </button>
                  <button
                    className='Trash'
                    onClick={() => MoveToCanceled(tasks.name)}
                    id={tasks.Id}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='2.5em'
                      viewBox='0 0 448 512'
                    >
                      <path d='M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z' />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </section>
        </div>
      </main>
    </>
  )
}
export default TaskManagerInProgress
