import { useEffect, useRef, useState } from "react";
import NavBar from "../../components/NAV-BAR";
import SideNavBar from "../../components/SIDE-NAV-BAR";
import "./Redirects-Styel.css"
import { toast } from "react-toastify";
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { v4 as uuidv4 } from 'uuid';
import clipboardCopy from 'clipboard-copy';

function Redirects() {

    const [Links, setLinks] = useState([])
    const [Link_Novo, setLink_Novo] = useState("")
    const [Link_Og, setLink_Og] = useState("")

    const toastId = useRef(null)

    function Limpar() {
        setLink_Novo("")
        setLink_Og("")
    }

    function Save() {
        if (Link_Og === "") {
            toast.error("Preencha o link original")
        } else if (Link_Novo === "") {
            toast.error("Preencha o link Novo")
        } else {
            toastId.current = toast("Salvando seu arquivo", {
                autoClose: false,
                closeButton: false
            });
            const id = uuidv4()
            const Ref = doc(db, 'Links', id);
            try {
                setDoc(Ref, {
                    LinkOG: Link_Og,
                    LinkNV: Link_Novo,
                }).then(() => {
                    toast.update(toastId.current, {
                        render: "Salvo com sucesso",
                        type: toast.TYPE.SUCCESS,
                        autoClose: 1000,
                        closeButton: null,
                        onClose: Limpar()
                    });
                })
            } catch (e) {
                toast.update(toastId.current, {
                    render: (`Error inesperado 1: ${e}`),
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    closeButton: null,
                });
            }
        }
    }

    useEffect(() => {
        const q = query(collection(db, 'Links'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setLinks(todosArr);
        });
        return () => unsubscribe();
    }, []);

    const [optVisibleIndex, setOptVisibleIndex] = useState(null);
    const [SelectOR, setSelectOR] = useState("")

    function TogleOPTBox(index, N) {
        setSelectOR(N)
        if (optVisibleIndex === index) {
            setOptVisibleIndex(null);
        } else {
            setOptVisibleIndex(index);
        }
    };

    const handleCopiarClick = (T) => {
        clipboardCopy(T).then(()=>{
            toast.success("Copiado para area de transferencia")
        })
    };

    return (
        <>
            <head>
                <meta property="og:url" content="https://admin.kawhealvesdossantos.com.br/redirects" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa" />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="400" />
                <meta property="og:description" content="Redirects" />
                <meta name="description" content="Redirects" />
            </head>

            <NavBar />
            <main>
                <SideNavBar />
                <div className='MainRedirects'>
                    <section className="Header_Links">
                        <div className="TitleL">
                            <h2>Links <span>{Links.length}</span></h2>
                        </div>
                        <div className="Inputs_Links">
                            <div>
                                <label htmlFor="Link">Link original:</label>
                                <input className="I01" type="link" name="Link" id="Link" onChange={e => setLink_Og(e.target.value)} value={Link_Og} />
                            </div>
                            <div>
                                <label htmlFor="Link_ENC">Novo link:</label>
                                <p>https://kawhealvesdossantos.com.br/encurtador/</p>
                                <input className="I02" type="link" name="Link_ENC" id="Link_ENC" onChange={e => setLink_Novo(e.target.value)} value={Link_Novo} />
                            </div>
                        </div>
                        <div className="DivBTN">
                            <button className="BTN_SAVE_LINKS" onClick={Save}>Salvar</button>
                            <button className="BTN_CLEAN_LINKS" onClick={Limpar}>Limpar</button>
                        </div>
                    </section>

                    <section className="List_Links ListaDeProjetos">
                        <tr>
                            <th className="TH01 ThCProjetos">
                                Link original
                            </th>
                            <th className="TH02 ThTProjetos">
                                Novo link
                            </th>
                            <th className="TH02 ThOpProjetos">
                                Opções
                            </th>
                        </tr>
                        {
                            Links &&
                            Links.map((link, index) => (
                                <tr key={index} id={index} className="cardListaProjetos">
                                    <td className="TD01 TdCProjetos"><a href={link.LinkOG}>{link.LinkOG}</a></td>
                                    <td className="NameProjeto TdTProjetos TD02" onClick={()=>handleCopiarClick(`https://kawhealvesdossantos.com.br/encurtador/${link.LinkNV}`)} >{link.LinkNV}</td>
                                    <td className="TdOpProjetos TD02">
                                        <div className={`OPT OPT${index}`} style={{ display: optVisibleIndex === index ? 'block' : 'none' }}>
                                            <button>Editar</button>
                                            <button>Remover</button>
                                        </div>
                                        <button className="BTN_OPT_OR" onClick={() => TogleOPTBox(index, link.LinkNV)}>
                                            <div className="CIRCLE_BTN_OR"></div>
                                            <div className="CIRCLE_BTN_OR"></div>
                                            <div className="CIRCLE_BTN_OR"></div>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </section>
                </div>
            </main>
        </>
    )
}
export default Redirects;