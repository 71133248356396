import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet';


function ViwFaturas() {

    const IdProjeteRecivie = useParams("")

    const [Orçamentos, setOrçamentos] = useState([])

    const [F, setF] = useState(false)

    const [Produtos, setProdutos] = useState([{ produto: "", descrição: "", preço: "", qt: "", total: 0 }])
    const [Clientes, setClientes] = useState([])

    const [ClienteInfo, setClienteInfo] = useState([])

    const [Title, setTitle] = useState("")
    const [Status, setStatus] = useState("Rascunho")
    const [SelectedClientes, setSelectedClientes] = useState("")
    const [Vencimento, setVencimento] = useState("")
    const [Mensagem, setMensagem] = useState("")
    const [Termos, setTermos] = useState("")
    const [Desconto, setDesconto] = useState(0)
    const [DescontoAmont, setDescontoAmont] = useState(0)
    const [SubTotal, setSubTotal] = useState(0)
    const [Total, setTotal] = useState(0)
    const [Type, setType] = useState("Orçamento")
    const [NOr2, setNOr2] = useState()
    const [NOr, setNOr] = useState("")

    const [PdfURL, setPdfURL] = useState("")

    const data = new Date()

    const [DateEmi, setDateEmi] = useState()

    const divRef = useRef(null);

    const toastId = useRef(null);

    useEffect(() => {
        if (SelectedClientes !== "") {
            const loadProjectDetails = async () => {
                try {
                    const projectDoc = doc(db, 'Clientes', SelectedClientes);
                    const projectSnapshot = await getDoc(projectDoc);
                    if (projectSnapshot.exists()) {
                        const projectData = projectSnapshot.data();
                        setClienteInfo([
                            projectData.NomeCl,
                            projectData.SubNomeCl,
                            projectData.EndRuaCl,
                            projectData.EndBairroCl,
                            projectData.EndCidadeCl,
                            projectData.EndEstadoCl,
                            projectData.EndCepCl,
                            projectData.CellNumCl,
                            projectData.EmailCl,
                        ]);
                    } else {
                        alert("nao foi possiel achar: " + SelectedClientes)
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            loadProjectDetails();
        }
    }, [SelectedClientes]);

    useEffect(() => {
        if (IdProjeteRecivie.N !== "") {
            const loadProjectDetails = async () => {
                try {
                    const projectDoc = doc(db, 'Faturas', IdProjeteRecivie.N);
                    const projectSnapshot = await getDoc(projectDoc);
                    if (projectSnapshot.exists()) {
                        const projectData = projectSnapshot.data();
                        setOrçamentos([
                            projectData.Status,
                            projectData.Id_Cliente,
                            projectData.Produtos,
                            projectData.Total,
                            projectData.SubTotal,
                            projectData.Desconto_Per,
                            projectData.Desconto_Am,
                            projectData.Termos,
                            projectData.Mensagem,
                            projectData.Vencimento,
                            projectData.Title,
                            projectData.CreatAt,
                            projectData.N_Or,
                            projectData.Type,
                            projectData.Nome_Cliente,
                            projectData.Email_Cl,
                            projectData.CreatAt,
                            projectData.Link_Jpeg,
                            projectData.Link_Pdf,
                        ]);
                    } else {
                        toast.error(IdProjeteRecivie.N + "Nao existe", {
                            onClose: () => window.location.href = "https://kawhealvesdossantos.com.br"
                        })
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            loadProjectDetails();
        }
    }, [IdProjeteRecivie.N]);

    useEffect(() => {
        LoadInfo()
    }, [Orçamentos])

    function LoadInfo() {
        setStatus(Orçamentos[0]);
        setSelectedClientes(Orçamentos[1])
        setProdutos(Orçamentos[2])
        setTotal(Orçamentos[3])
        setSubTotal(Orçamentos[4])
        setDesconto(Orçamentos[5])
        setDescontoAmont(Orçamentos[6])
        setTermos(Orçamentos[7])
        setMensagem(Orçamentos[8])
        setVencimento(Orçamentos[9])
        setTitle(Orçamentos[10])
        setDateEmi(Orçamentos[11])
        setNOr(Orçamentos[12])
        setType(Orçamentos[13])
        setDateEmi(Orçamentos[16])
    }

    function GotoPdf() {
        window.location.href = `${Orçamentos[18]}`
    }

    function GotoJpeg() {
        window.location.href = `${Orçamentos[17]}`
    }

    return (
        <>
            <head>

                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/Faturas/${NOr}`} />
                <meta property="og:image" content={Orçamentos[17]} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:description" content={`Orçamento-${NOr}`} />
                <meta name="description" content={`Orçamento-${NOr}`} />
            </head>

            <title>KAWHE | Fatura | {NOr}</title>

            <main className="MainAceitarOr">

                <section>
                    <button onClick={GotoPdf}>Abaixar pdf</button>
                    <button onClick={GotoJpeg}>Abaixar imagen</button>
                </section>
                <div style={{ height: "92vh" }}>
                    <section ref={divRef} className="PreviwOr ViwOrAceitarOr">
                        {
                            Orçamentos.length < 0 ?
                                (
                                    <div className="PreviwOr">
                                        Carregando
                                    </div>

                                ) : ""
                        }
                        <section>
                            <div className="PreviewHeader">
                                <div className="PreviewHeader1">
                                    <div className="Logo">
                                        <img className="LogoImg" src="https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/favicon.png?alt=media&token=9859bd8c-6246-496f-9e2c-4deb450d85ee" alt="Logo" />
                                    </div>
                                    <div className="PerInfo">
                                        <p className="MyName">KAWHE ALVES DOS SANTOS</p>
                                        <p>Brazil</p>
                                        <p>cantato@kawhealvesdossantos.com.br</p>
                                        <p>Telefone: +55 69 9 93172767</p>
                                    </div>
                                </div>
                                <div className="InfoPreviwOr">
                                    <p
                                        className={`StatusOr  
                                            ${Status === "Enviada" ? 'STEnviada'
                                                :
                                                Status === "Rascunho" ? "STRascunho"
                                                    :
                                                    Status === "Faturada" ? "STEnviada"
                                                        :
                                                        ""
                                            }`}>{Status}</p>
                                    <p>{Type} Nº: {NOr}</p>
                                    <p>Emitida em: {DateEmi}</p>
                                    <p>Data de vencimento: {Vencimento}</p>
                                </div>
                            </div>
                            <div className="DTitleOr">
                                <p>{Title}</p>
                            </div>
                            <div className="InfoCl">
                                <p className="InfoClp1">{ClienteInfo[0] + " " + ClienteInfo[1]}</p>
                                <p className="InfoClp2">{ClienteInfo[2] + " " + ClienteInfo[3]}</p>
                                <p className="InfoClp3">{ClienteInfo[4] + " " + ClienteInfo[5] + " " + ClienteInfo[6]}</p>
                                <p className="InfoClp4">{ClienteInfo[7]}</p>
                            </div>
                        </section>
                        <section>
                            <div className="HeaderOrPreços">
                                <p>Serviço</p>
                                <p>Preço (R$)</p>
                                <p>Quantidade</p>
                                <p>Total</p>
                            </div>
                            {Produtos &&
                                Produtos.map((p, index) => (
                                    <div key={index} className="ListPrOr">
                                        <div className="Pr Cw">
                                            <p className="PrP">{p.produto}</p>
                                            <p className="PrC">{p.descrição}</p>
                                        </div>
                                        <p className="Cw">{p.preço}</p>
                                        <p className="Cw">{p.qt}</p>
                                        <p className="Cw">{p.total}</p>
                                    </div>
                                ))
                            }
                            <div className="LinePreviewOr"></div>
                        </section>
                        <section className="TotaisPreviwOr">
                            <div className="DTotaisPreviwOr">
                                <div>
                                    <p>SubTotal</p>
                                    <p>{SubTotal}</p>
                                </div>
                                {Desconto !== 0 &&
                                    <div>
                                        <p>Desconto</p>
                                        <p>{Desconto}%</p>
                                        <p>{DescontoAmont}</p>
                                    </div>
                                }
                                <div className="DTotalOr">
                                    <p>Total {F ? "a pagar" : ""}</p>
                                    <p>{Total}</p>
                                </div>
                            </div>
                        </section>
                        <section className="MensagensPreviewOr">
                            <div>
                                {Mensagem !== "" &&
                                    <div className="DMensagenOr">
                                        <p>Mensagens:</p>
                                        <div className="DMSGOR">
                                            <p className="MSGOR">{Mensagem}</p>
                                        </div>
                                    </div>
                                }

                                {Termos !== "" &&
                                    <div>
                                        <p>Termos Legais:</p>
                                        <div className="DTROR">
                                            <p>{Termos}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>
                    </section>
                </div>
            </main>
        </>
    )
}
export default ViwFaturas;