import { signOut } from 'firebase/auth'
import './side-nav-bar-style.css'
import { auth } from '../../config/firebase'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useState } from 'react'

function SideNavBar() {
  function OpenSideNavBar() {
    const div = document.querySelector('.SideNavBar')
    const BtnOpenSideBar = document.querySelector('.BtnOpenSideBar')
    const BarNav1 = document.querySelector('.BarNav1')
    const BarNav2 = document.querySelector('.BarNav2')
    const BarNav3 = document.querySelector('.BarNav3')

    if (div.classList.contains('SideOpen')) {
      closeSideNavBar()
    } else {
      BtnOpenSideBar.classList.add('SideOpen_btn')
      BarNav1.classList.add('SideOpen_btn_bar1')
      BarNav2.classList.add('SideOpen_btn_bar2')
      BarNav3.classList.add('SideOpen_btn_bar3')
      div.classList.remove('SideClose')
      div.classList.add('SideOpen')
    }
  }

  function closeSideNavBar() {
    const div = document.querySelector('.SideNavBar')
    const BtnOpenSideBar = document.querySelector('.BtnOpenSideBar')
    const BarNav1 = document.querySelector('.BarNav1')
    const BarNav2 = document.querySelector('.BarNav2')
    const BarNav3 = document.querySelector('.BarNav3')

    BtnOpenSideBar.classList.remove('SideOpen_btn')
    BarNav1.classList.remove('SideOpen_btn_bar1')
    BarNav2.classList.remove('SideOpen_btn_bar2')
    BarNav3.classList.remove('SideOpen_btn_bar3')
    div.classList.add('SideClose')
    div.classList.remove('SideOpen')
  }

  function LogOut() {
    confirmAlert({
      title: 'Certeza que quer sair?',
      message: 'Voçe sera redirecionado para pagina de login',
      buttons: [
        {
          label: 'Sim',
          onClick: () => suot()
        },
        {
          label: 'Não'
        }
      ]
    })
    function suot() {
      signOut(auth)
        .then(() => {
          window.location.href = '/login'
        })
        .catch(error => {
          toast.error(error.message)
        })
    }
  }

  const [PortifolioIsOpen, setPortifolioIsOpen] = useState(false)

  function HandleClickPortifolio() {
    setPortifolioIsOpen(!PortifolioIsOpen)
  }

  const [PessoalIsOpen, setPessoalIsOpen] = useState(false)

  function HandleClickPessoal() {
    setPessoalIsOpen(!PessoalIsOpen)
  }

  const [ExploreIsOpen, setExploreIsOpen] = useState(false)

  function HandleClickExplore() {
    setExploreIsOpen(!ExploreIsOpen)
  }

  const [FinançasIsOpen, setFinançasIsOpen] = useState(false)

  function HandleClickFinancas() {
    setFinançasIsOpen(!FinançasIsOpen)
  }

  const [OpçõesIsOpen, setOpçõesIsOpen] = useState(false)

  function HandleClickOpcao() {
    setOpçõesIsOpen(!OpçõesIsOpen)
  }

  const [AnexosIsOpen, setAnexosIsOpen] = useState(false)

  function HandleClickAnexos() {
    setAnexosIsOpen(!AnexosIsOpen)
  }

  const [LinksIsOpen, setLinksIsOpen] = useState(false)

  function HandleClickLinks() {
    setLinksIsOpen(!LinksIsOpen)
  }

  return (
    <>
      <section>
        <button className='BtnOpenSideBar' onClick={OpenSideNavBar}>
          <div className='BarNav BarNav1'></div>
          <div className='BarNav BarNav2'></div>
          <div className='BarNav BarNav3'></div>
        </button>
      </section>

      <nav className='SideNavBar opacity SideClose'>
        <div className='SideNavBar opacity'>
          <a href='/' className='link-SideNavBar H'>
            HOME
          </a>

          <div className='LineNavSidBar'></div>
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickPortifolio} style={{ marginBottom: !PortifolioIsOpen ? "10px" : "0" }}>
              <div>
                {"PORTIFOLIO"}
              </div>
              <div>
                {!PortifolioIsOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                }
              </div>
            </div>
            {PortifolioIsOpen && (
              <div className='Links'>
                <a href='/projetos' className='link-SideNavBar PJ'>
                  PROJETOS
                </a>
                <a href='/produtos' className='link-SideNavBar PD'>
                  PRODUTOS
                </a>
              </div>
            )}
          </section>

          <div className='LineNavSidBar'></div>
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickPessoal} style={{ marginBottom: !PessoalIsOpen ? "10px" : "0" }}>
              <div>
                {"PESSOAL"}
              </div>
              <div>
                {!PessoalIsOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                }
              </div>
            </div>
            {PessoalIsOpen && (
              <div className='Links'>
                <a href='/kawhe/emprogresso' className='link-SideNavBar KT'>
                  TAREFAS
                </a>
                <a href='/ajenda' className='link-SideNavBar AJ'>
                  AJENDA
                </a>
              </div>
            )}
          </section>


          <div className='LineNavSidBar'></div>
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickExplore} style={{ marginBottom: !ExploreIsOpen ? "10px" : "0" }}>
              <div>
                {"EXPLORER"}
              </div>
              <div>
                {!ExploreIsOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                }
              </div>
            </div>
            {ExploreIsOpen && (
              <div className='Links'>
                <a href='/uploads' className='link-SideNavBar UP'>
                  ARQUIVOS
                </a>
              </div>
            )}
          </section>

          <div className='LineNavSidBar'></div>
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickFinancas} style={{ marginBottom: !FinançasIsOpen ? "10px" : "0" }}>
              <div>
                {"FINANÇAS"}
              </div>
              <div>
                {!FinançasIsOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                }
              </div>
            </div>
            {FinançasIsOpen && (
              <div className='Links'>
                <a href='/crm/clientes' className='link-SideNavBar CC'>
                  CLIENTES
                </a>
                <a href='/crm/orçamentos' className='link-SideNavBar CC'>
                  ORÇAMENTOS
                </a>
                <a href='/crm/faturas' className='link-SideNavBar CC'>
                  FATURAS
                </a>
              </div>
            )}
          </section>

          <div className='LineNavSidBar'></div>
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickOpcao} style={{ marginBottom: !OpçõesIsOpen ? "10px" : "0" }}>
              <div>
                {"OPÇÕES"}
              </div>
              <div>
                {!OpçõesIsOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                }
              </div>
            </div>
            {OpçõesIsOpen && (
              <div className='Links'>
                <a href='/configurações' className='link-SideNavBar CC'>
                  CONFIG'S
                </a>
              </div>
            )}
          </section>

          <div className='LineNavSidBar'></div>
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickLinks} style={{ marginBottom: !LinksIsOpen ? "10px" : "0" }}>
              <div>
                {"LINK'S"}
              </div>
              <div>
                {!LinksIsOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                }
              </div>
            </div>
            {LinksIsOpen && (
              <div className='Links'>
                <a href='/redirects' className='link-SideNavBar CC'>
                  LINKS
                </a>
              </div>
            )}
          </section>

          <div className='LineNavSidBar'></div>
          <section className='Drop'>
            <div className='HeaderDrop' onClick={HandleClickAnexos} style={{ marginBottom: !AnexosIsOpen ? "10px" : "0" }}>
              <div>
                {"ANEXOS"}
              </div>
              <div>
                {!AnexosIsOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                }
              </div>
            </div>
            {AnexosIsOpen && (
              <div className='Links'>
                <a href='/anexos/calc-hid' className='link-SideNavBar CC'>
                  CALC. HID
                </a>
              </div>
            )}
          </section>
        </div>
        <div>
          <button className='BtnLogUotSide' onClick={LogOut}>
            SAIR
          </button>
        </div>
      </nav>
    </>
  )
}
export default SideNavBar
