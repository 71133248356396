import { useRef, useState } from 'react'
import './Creat-Acount-Style.css'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { auth } from '../../../../config/firebase'
import { toast } from 'react-toastify'

function CreatAcount () {
  const [Name, setName] = useState('')
  const [Email, setEmail] = useState('')
  const [Pass, setPass] = useState('')
  const toastId = useRef(null)

  function create () {
    if (Name === '') {
      toast.warn('Preencha o nome')
    } else if (Email === '') {
      toast.warn('Preencha a Email')
    } else if (Pass === '') {
      toast.warn('Preencha a senha')
    } else {
      toastId.current = toast('Salvando seu arquivo', {
        autoClose: false,
        closeButton: false
      })
      createUserWithEmailAndPassword(auth, Email, Pass)
        .then(userCredential => {
          updateProfile(auth.currentUser, {
            displayName: Name
          })
            .then(() => {
              window.location.href="/games/velha/oline/login"
            })
            .catch(error => {
              toast.warn(error)
            })
          })
          .catch(error => {
          toast.warn(error)
        })
    }
  }

  return (
    <>
      <input
        type='text'
        placeholder='Nome'
        onChange={e => setName(e.target.value)}
        value={Name}
      />
      <input
        type='text'
        placeholder='Email'
        onChange={e => setEmail(e.target.value)}
        value={Email}
      />
      <input
        type='text'
        placeholder='Senha'
        onChange={e => setPass(e.target.valeu)}
        value={Pass}
      />
      <button onClick={create}>Criar conta</button>
    </>
  )
}
export default CreatAcount
