import { useEffect, useState } from 'react';
import NavBar from '../../components/NAV-BAR';
import SideNavBar from '../../components/SIDE-NAV-BAR';
import './projetos-style.css'
import { collection, onSnapshot, query } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

function Projetos() {

    onAuthStateChanged(auth, (user) => {
        if (!user) {
            window.location.href = "/login"
        }
        if (user.uid === "pT9QAfGunpZ3Abzl1PrRxeW9v5Q2" || user.uid === "RaugT4w726WAhUvG8H5nUyi9e493") {
        } else {
            signOut(auth).then(() => {
                window.location.href = "/login"
            }).catch((error) => {
                console.log(error)
            });
        }
    });

    const [Projetos, setProjetos] = useState([])

    useEffect(() => {
        const q = query(collection(db, 'Projetos'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setProjetos(todosArr);
        });
        return () => unsubscribe();
    }, []);

    function GoToAddProjetos() {
        window.location.href = "/projetos/addprojetos"
    }

    return (
        <>
            <head>

                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/crm/clientes`} />
                <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="600" />
                <meta property="og:description" content={`Vizualizar projetos existentes`} />
                <meta name="description" content="Vizualizar projetos existentes" />
            </head>

            <title>KAWHE | PROJETOS</title>
            <NavBar />
            <div className='SecundMain'>
                <SideNavBar />
                <div className='MainProjetos'>
                    <section className='HeaderProjetos'>
                        <h2 className='TxHeaderProjetos'>Projetos <span className='QtnProjetos'>{Projetos.length}</span></h2>
                        <button className='BtnAddProjeto' onClick={GoToAddProjetos}><i class="fa-solid fa-plus fa-plus-pj"></i>Adicionar Projeto</button>
                    </section>
                    <section className='ListaDeProjetos'>
                        <tr>
                            <th className='ThCProjetos'>Capa</th>
                            <th className='ThTProjetos'>Título</th>
                            <th className='ThOpProjetos'>Opções</th>
                        </tr>
                        {Projetos.map((item) => (
                            <tr key={item.Id} id={item.Id} className='cardListaProjetos'>
                                <td className='TdCProjetos'><a href={(`https://kawhealvesdossantos.com.br/portifolio/${item.Name}`)}><img src={item.Url_Capa} alt={"imagem de projeto"} className='ImagemPreviw' /></a></td>
                                <td className='NameProjeto TdTProjetos' >{item.Name}</td>
                                <td className='TdOpProjetos'>
                                    <a href={(`/projetos/EditarProjetos/${item.Name}`)} id={item.Id} className='ButtonOptionsProjetos' >
                                        <div className='circle'></div>
                                        <div className='circle'></div>
                                        <div className='circle'></div>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </section>
                </div>
            </div>
        </>
    )
}
export default Projetos;