import { useEffect, useRef, useState } from "react";
import NavBar from "../../components/NAV-BAR";
import SideNavBar from "../../components/SIDE-NAV-BAR";
import 'react-calendar/dist/Calendar.css';
import './Ajenda-Style.css'
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid'

function Ajenda() {

    // add taks
    const id = uuidv4()

    const [Name, setName] = useState('')
    const [Day, setDay] = useState('')
    const [Month2, setMonth2] = useState('')
    const [Year, setYaer] = useState('')
    const [DateInp, setDate] = useState('')
    const [EndTime, setEndtime] = useState('')
    const [StartTime, setstartTime] = useState('')
    const [Status] = useState('Progress')

    console.log(Year)
    console.log(Month2)
    console.log(Day)

    const toastId = useRef(null)

    function CleanInput() {
        setName('')
        setDay('')
        setMonth2('')
        setYaer('')
        setDate('')
        setEndtime('')
        setstartTime('')
    }

    function OpenAddTask() {
        const AddTask = document.querySelector(".Fadetask")
        AddTask.style.display = "flex"
    }

    function CloseAddTask() {
        const AddTask = document.querySelector(".Fadetask")
        AddTask.style.display = "none"
    }

    function CreateTask() {
        if (Name === '' || Date === '' || EndTime === '' || StartTime === '') {
            if (Name === '') {
                toast.warn("Preencha o campo 'Nome' para salvar")
            } else if (DateInp === '') {
                toast.warn("Preencha o campo 'Data' para salvar")
            } else if (EndTime === '') {
                toast.warn("selecione uma Hora de inicio para salvar")
            } else if (StartTime === '') {
                toast.warn("selecione uma Hora de termino para salvar")
            }
        } else {

            const partes = DateInp.split("-");
            const data = new Date(partes[0], partes[1] - 1, partes[2]);

            toastId.current = toast('Salvando sua tarefa', {
                autoClose: false,
                closeButton: false
            })
            const TasksRef = doc(db, 'Tasks', Name)
            try {
                setDoc(TasksRef, {
                    Id: id,
                    name: Name,
                    day: parseInt(Dia),
                    month: parseInt((month).toString().padStart(2, '0')),
                    year: parseInt(Ano),
                    endTime: EndTime,
                    startTime: StartTime,
                    Status: Status
                }).then(() => {
                    toast.update(toastId.current, {
                        render: 'Salvo com sucesso',
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        closeButton: null,
                        onOpen: CloseAddTask() + CleanInput()
                    })
                })
            } catch (e) {
                toast.update(toastId.current, {
                    render: `Error inesperado: ${e}`,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    closeButton: null
                })
            }
        }
    }

    // add taks

    const today = new Date();
    const [year, setYear] = useState(today.getFullYear());
    const [month, setMonth] = useState(today.getMonth());
    const [activeDay, setActiveDay] = useState(today.getDate());
    const [days, setDays] = useState([]);
    const [events, setEvents] = useState([]); // Lista de eventos

    const [DiaDaSemana, setDiaDaSemana] = useState('');
    const [Dia, setDia] = useState('');
    const [Mes, setMes] = useState('');
    const [Ano, setAno] = useState('');


    useEffect(() => {
        const q = query(collection(db, "Tasks/"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setEvents(todosArr);
        });
        return () => unsubscribe();
    }, []);

    const handleClick = (day) => {
        setActiveDay(day);
    };

    // Função para adicionar dias com as classes day, prev-date, next-date, active, today e manipular cliques
    const initCalendar = () => {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const prevLastDay = new Date(year, month, 0);
        const prevDays = prevLastDay.getDate();
        const lastDate = lastDay.getDate();
        const day = firstDay.getDay();
        const nextDays = 7 - lastDay.getDay() - 1;

        const newDays = [];

        // Preencher dias do mês anterior
        for (let x = prevDays - day + 1; x <= prevDays; x++) {
            newDays.push(
                <div
                    className={`day prev-date`}
                    key={x}
                >
                    {x}
                </div>
            );
        }

        // Preencher dias do mês atual
        for (let i = 1; i <= lastDate; i++) {
            const isToday = i === today.getDate() && year === today.getFullYear() && month === today.getMonth();
            const isCurrentMonth = month === today.getMonth(); // Adicionado para verificar se é o mês atual

            newDays.push(
                <div
                    className={`day ${isToday && isCurrentMonth ? 'today' : ''} ${activeDay === i ? 'active' : ''} ${events.some((event) => event.day === i && event.month === month && event.year === year && event.Status === "Progress") ? "event event_Y" : events.some((event) => event.day === i && event.month === month && event.year === year && event.Status === "Ready") ? "event event_R" : events.some((event) => event.day === i && event.month === month && event.year === year && event.Status === "Canceled") ? "event event_C" : ""}`}
                    key={i + prevDays}
                    onClick={() => handleClick(i)}
                >
                    {i}
                </div>
            );
        }

        // Preencher dias do próximo mês
        for (let j = 1; j <= nextDays; j++) {
            newDays.push(
                <div
                    className={`day next-date`}
                    key={j + lastDate + prevDays}
                >
                    {j}
                </div>
            );
        }

        setDays(newDays);
        updateDateInfo();
    };

    // Função para atualizar o mês e o ano ao clicar nos botões de prev e next
    const prevMonth = () => {
        setMonth((prevMonth) => (prevMonth - 1 + 12) % 12);
        if (month === 0) {
            setYear((prevYear) => prevYear - 1);
        }

        // Se voltar para o mês atual, configurar activeDay para o dia atual
        if ((month - 1 + 12) % 12 === today.getMonth()) {
            setActiveDay(today.getDate());
        } else {
            setActiveDay(0); // Define activeDay como 0 ao mudar de mês
        }
    };

    const nextMonth = () => {
        setMonth((prevMonth) => (prevMonth + 1) % 12);
        setActiveDay(1); // Define activeDay como 0 ao mudar de mês
        if (month === 11) {
            setYear((prevYear) => prevYear + 1);
        }
    };

    console.log(days)

    const [inputDate, setInputDate] = useState('');

    const handleInputChange = (e) => {
        let value = e.target.value;

        // Remove caracteres não numéricos
        value = value.replace(/\D/g, '');

        // Adiciona uma barra após os dois primeiros caracteres
        if (value.length > 2) {
            value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }

        setInputDate(value);
    };

    const handleGoToDate = () => {
        const [inputMonth, inputYear] = inputDate.split('/').map(Number);
        if (!isNaN(inputMonth) && !isNaN(inputYear) && inputMonth >= 1 && inputMonth <= 12) {
            setMonth(inputMonth - 1);
            setYear(inputYear);
            setActiveDay(1); // Define activeDay como 0 ao mudar de mês
        } else {
            alert('Por favor, insira uma data válida no formato mm/yyyy.');
        }
    };

    const handleGoToToday = () => {
        const today = new Date();
        setMonth(today.getMonth());
        setYear(today.getFullYear());
        setActiveDay(today.getDate());
    };

    // Função para atualizar os elementos com informações da data
    const updateDateInfo = () => {
        const currentDate = new Date(year, month, activeDay);
        setDiaDaSemana(currentDate.toLocaleDateString('pt-BR', { weekday: 'long' }));
        setDia(`${String(activeDay).padStart(2, '0')}`);
        setMes(currentDate.toLocaleDateString('pt-BR', { month: 'long' }));
        setAno(currentDate.getFullYear());
    };


    // Filtrar eventos para o dia selecionado
    const eventsForSelectedDay = events.filter(
        (event) => event.day === activeDay && event.month === month && event.year === year
    );

    console.log(events)

    useEffect(() => {
        initCalendar();
        const dayElements = document.querySelectorAll('.day');
        dayElements.forEach((element) => {
            const dayNumber = parseInt(element.innerText, 10);
            if (events.some((event) => event.day === dayNumber && event.month === month && event.year === year)) {
                element.classList.add('event');
            } else {
                element.classList.remove('event');
            }
        });
    }, [events, month, year, activeDay]);

    console.log(`${Ano}-${(month+1).toString().padStart(2, '0')}-${Dia}`)
    console.log(DateInp)

    return (
        <>
            <head>
                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/Ajenda`} />
                <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="600" />
                <meta property="og:description" content={`Ajenda`} />
                <meta name="description" content="Ajenda" />
            </head>

            <title>KAWHE | AJENDA</title>
            <NavBar />
            <main>
                <SideNavBar />
                <div className='MainAjenda'>
                    <section className="ConteinerCalender">
                        <div class="month">
                            <i class="fas fa-angle-left prev" onClick={prevMonth}></i>
                            <div class="date">{getMonthName(month)} {year}</div>
                            <i class="fas fa-angle-right next" onClick={nextMonth}></i>
                        </div>
                        <div class="weekdays">
                            <div>Dom</div>
                            <div>Seg</div>
                            <div>Ter</div>
                            <div>Qua</div>
                            <div>Qui</div>
                            <div>Sex</div>
                            <div>Sab</div>
                        </div>
                        <div class="days">
                            {days}
                        </div>
                        <div class="goto-today">
                            <div class="goto">
                                <input type="text" placeholder="mm/yyyy" class="date-input" value={inputDate} onChange={handleInputChange} />
                                <button class="goto-btn" onClick={handleGoToDate}>Go</button>
                            </div>
                            <button class="today-btn" onClick={handleGoToToday}>Today</button>
                        </div>
                    </section>
                    <section className="ConteinerListTasksDay">
                        <div className="today-date">
                            <p className="DiaSemana">{DiaDaSemana}</p>
                            <div className="DataCompleta">
                                <p className="Dia">{Dia}</p>
                                <p className="Mes">{Mes}</p>
                                <p className="Ano">{Ano}</p>
                            </div>
                        </div>

                        <div className="EventList">
                            {eventsForSelectedDay.map((event, index) => (
                                <div key={event.Id} className={`Event ${event.Status === "Progress" && "Hover_Y"} ${event.Status === "Canceled" && "Hover_C"} ${event.Status === "Ready" && "Hover_R"}`} >
                                    <div className="DivInd">
                                        <div className={`Ind ${event.Status === "Progress" && "Ind_Y"} ${event.Status === "Canceled" && "Ind_C"} ${event.Status === "Ready" && "Ind_R"} `}></div>
                                    </div>
                                    <div>
                                        <p>{`${event.name}`}</p>
                                        <p>{`${event.startTime} - ${event.endTime}`}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div>
                            <button class="add-event" onClick={OpenAddTask}>
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </section>
                </div>
            </main>

            <div className='Fadetask'>
                <div className='PopUpAddTask'>
                    <div className='IputName'>
                        <label htmlFor="Name">Nome:</label>
                        <input type='text' className='taskinput' id='Name' onChange={e => setName(e.target.value)} value={Name} ></input>
                    </div>
                    <section>
                        <div className='inputDate'>
                            <label htmlFor="Data">Data:</label>
                            <input type='date' className='datainput' id='Data' onChange={e => setDate(e.target.value)} value={`${Ano}-${(month+1).toString().padStart(2, '0')}-${Dia}`} ></input>
                        </div>
                        <div className='InputsTimeTasks'>
                            <div className='InputTimeTasks'>
                                <label htmlFor="St">Hora de inicio:</label>
                                <input type="Time" className='datainput' id='St' onChange={e => setstartTime(e.target.value)} value={StartTime} />
                            </div>
                            <div className='InputTimeTasks'>
                                <label htmlFor="Et">Hora de termino:</label>
                                <input type="Time" className='datainput' id='Et' onChange={e => setEndtime(e.target.value)} value={EndTime} />
                            </div>
                        </div>
                    </section>
                    <progress value={0} max={100}></progress>
                    <div>
                        <button className='BtnSaveTask' onClick={CreateTask}>Salvar</button>
                        <button className='BtnCancelarTask' onClick={CloseAddTask}>Cancelar</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Ajenda;

function getMonthName(month) {
    const monthNames = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril',
        'Maio', 'Junho', 'Julho', 'Agosto',
        'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    return monthNames[month];
}