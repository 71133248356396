import { useEffect, useRef, useState } from "react";
import NavBar from "../../components/NAV-BAR";
import SideNavBar from "../../components/SIDE-NAV-BAR";
import "./Produtos-Style.css"
import { collection, doc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../config/firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

function Produtos() {

    const [Produtos, setProdutos] = useState([])
    const [PopUp, setPopUp] = useState(false)

    useEffect(() => {
        const q = query(collection(db, 'Produtos'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            querySnapshot.forEach((doc) => {
                todosArr.push({ ...doc.data() });
            });
            setProdutos(todosArr);
        });
        return () => unsubscribe();
    }, []);

    const [Name, setName] = useState("")
    const [Price, setPrice] = useState("")
    const [Image_capa, setImage_capa] = useState(null)
    const [File, setFile] = useState(null)
    const [Image, setImage] = useState([])
    const [Video, setVideo] = useState("")

    const toastId = useRef(null);

    function Clean() {
        setName("")
        setPrice("")
        setImage([])
        setImage_capa(null)
        setVideo("")
        setPopUp(false)
    }

    function OpenPopUp() {
        setPopUp(!PopUp)
    }
    function ClosePopUp() {
        setPopUp(!PopUp)
    }

    function SalvaProd() {
        if (Name === "") {
            toast.warn("Preencha o nome")
        } else if (Price === "") {
            toast.warn("Preencha o preço")
        } else if (Image_capa === null) {
            toast.warn("Selecione um imagem para capa")
        } else {
            toastId.current = toast("Salvando o produto", {
                autoClose: false,
                closeButton: false
            });
            const id = uuidv4()
            const TasksRef = doc(db, 'Produtos', Name);
            try {
                setDoc(TasksRef, {
                    Id: id,
                    Name: Name,
                    Url_Capa: "",
                    Fotos: "",
                    Price: Price,
                    Video: Video,
                    Link_File: ""
                }).then(() => {
                    const FileName = Image_capa.name
                    const StorageRef = ref(storage, "Produtos/" + Name + "/Capa/" + FileName)
                    const UpLoadCapa = uploadBytesResumable(StorageRef, Image_capa)
                    UpLoadCapa.on(
                        "state_changed",
                        snapshot => {
                        },
                        error => {
                            alert(error)
                        },
                        () => {
                            getDownloadURL(UpLoadCapa.snapshot.ref).then(url => {
                                updateDoc(TasksRef, {
                                    Url_Capa: url,
                                }).then(() => {
                                    if (Image.length <= 0) {
                                        toast.update(toastId.current, {
                                            render: "Salvo com sucesso",
                                            type: toast.TYPE.SUCCESS,
                                            autoClose: 5000,
                                            closeButton: null,
                                            onOpen: Clean()
                                        });
                                    } else {
                                        let ARRurl = [];
                                        Image.map((i) => {
                                            const StorageRef = ref(storage, "Produtos/" + Name + "/Fotos/" + i.name)
                                            const UpLoadCapa = uploadBytesResumable(StorageRef, i)
                                            UpLoadCapa.on(
                                                "state_changed",
                                                snapshot => {
                                                },
                                                error => {
                                                    alert(error)
                                                },
                                                () => {
                                                    getDownloadURL(UpLoadCapa.snapshot.ref).then(url => {
                                                        ARRurl.push({ url: url })
                                                        updateDoc(TasksRef, {
                                                            Fotos: ARRurl,
                                                        }).then(() => {

                                                            const StorageRef = ref(storage, "Produtos/" + Name + "/File/" + File.name)
                                                            const UpLoadFile = uploadBytesResumable(StorageRef, File)
                                                            UpLoadFile.on(
                                                                "state_changed",
                                                                snapshot => {

                                                                },
                                                                error => {
                                                                    alert(error)
                                                                },
                                                                () => {
                                                                    getDownloadURL(UpLoadFile.snapshot.ref).then(url => {
                                                                        updateDoc(TasksRef, {
                                                                            Link_File: url
                                                                        }).then(() => {
                                                                            toast.update(toastId.current, {
                                                                                render: "Salvo com sucesso",
                                                                                type: toast.TYPE.SUCCESS,
                                                                                autoClose: 5000,
                                                                                closeButton: null,
                                                                                onOpen: Clean()
                                                                            });
                                                                        }).catch((e) => {
                                                                            toast.update(toastId.current, {
                                                                                render: (`Error inesperado 4: ${e}`),
                                                                                type: toast.TYPE.ERROR,
                                                                                autoClose: 5000,
                                                                                closeButton: null,
                                                                            });
                                                                        })
                                                                    })
                                                                }
                                                            )
                                                        }).catch((e) => {
                                                            toast.update(toastId.current, {
                                                                render: (`Error inesperado 3: ${e}`),
                                                                type: toast.TYPE.ERROR,
                                                                autoClose: 5000,
                                                                closeButton: null,
                                                            });
                                                        })
                                                    })
                                                }
                                            )
                                        })
                                    }
                                }).catch((e) => {
                                    toast.update(toastId.current, {
                                        render: (`Error inesperado 2: ${e}`),
                                        type: toast.TYPE.ERROR,
                                        autoClose: 5000,
                                        closeButton: null,
                                    });
                                })
                            })
                        }
                    )
                })
            } catch (e) {
                toast.update(toastId.current, {
                    render: (`Error inesperado 1: ${e}`),
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    closeButton: null,
                });
            }
        }
    }

    function HandleImage_capa(e) {
        setImage_capa(e.target.files[0])
    }

    function HandleImage(e) {
        setImage(Array.from(e.target.files));
    }

    function HandleFile(e) {
        setFile(e.target.files[0])
    }

    const [SelectOR, setSelectOR] = useState("")
    const [optVisibleIndex, setOptVisibleIndex] = useState(null);

    function TogleOPTBox(index, N) {
        setSelectOR(N)
        if (optVisibleIndex === index) {
            setOptVisibleIndex(null);
        } else {
            setOptVisibleIndex(index);
        }
    };

    return (
        <>
            <head>

                <meta property="og:title" content="CRM || kawhe" />
                <meta property="og:url" content={`https://admin.kawhealvesdossantos.com.br/Produtos`} />
                <meta property="og:image" content={"https://firebasestorage.googleapis.com/v0/b/kawhe-alves-dos-santos-db4be.appspot.com/o/01-2.png?alt=media&token=291e2cf7-9731-4ed7-8373-57b197a007aa"} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="600" />
                <meta property="og:description" content={`Vizualizar/ adicionar produtos`} />
                <meta name="description" content="Vizualizar / adicionar produtos" />
            </head>

            <title>KAWHE | PRODUTOS</title>

            <NavBar />
            <main>
                <SideNavBar />
                <div className='MainOrçamentos'>
                    <section className='HeaderProjetos'>
                        <h2 className='TxHeaderProjetos'>Produtos <span className='QtnProjetos'>{Produtos.length}</span></h2>
                        <button className='BtnAddProjeto' onClick={() => OpenPopUp()}><i class="fa-solid fa-plus fa-plus-pj"></i>Adicionar Produtos</button>
                    </section>

                    <section className='ListaDeProjetos'>

                        <tr className="HeaderProdutos">
                            <th className='ThCProjetos'>Capa</th>
                            <th className='ThTProjetos'>Título</th>
                            <th className='ThTProjetos'>Preço</th>
                            <th className='ThOpProjetos'>Opções</th>
                        </tr>
                        <div>
                            {Produtos.map((item, index) => (
                                <tr key={item.Id} id={item.Id} className='cardListaProjetos'>
                                    <td className='TdCProjetos'><a href={(`https://kawhealvesdossantos.com.br/loja/${item.Name}`)}><img src={item.Url_Capa} alt={"imagem de projeto"} className='ImagemPreviw' /></a></td>
                                    <td className='NameProjeto TdTProjetos' >{item.Name}</td>
                                    <td className='NameProjeto TdTProjetos' >{item.Price}</td>
                                    <td className='ThOpProjetos' >
                                        <div className={`OPT OPT${index}`} style={{ display: optVisibleIndex === index ? 'block' : 'none' }}>
                                            <button>Editar</button>
                                            <button>Remover</button>
                                        </div>
                                        <button className="BTN_OPT_OR" onClick={() => TogleOPTBox(index, item.Name)}>
                                            <div className="CIRCLE_BTN_OR"></div>
                                            <div className="CIRCLE_BTN_OR"></div>
                                            <div className="CIRCLE_BTN_OR"></div>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </div>
                    </section>

                    {
                        PopUp && (
                            <section className="AddProd">
                                <div className="AP-T">
                                    <p>Adiconar produtos</p>
                                </div>
                                <div className="AP-M">
                                    <div>
                                        <label htmlFor="Name">Nome:</label>
                                        <input type="text" name="Name" id="Name" placeholder="Nome do produto" className="Name-Prod" onChange={e => setName(e.target.value)} value={Name} />
                                    </div>

                                    <div>
                                        <label htmlFor="Price">Preço:</label>
                                        <input type="price" name="Price" id="Price" placeholder="Preço do produto" className="Price-Prod" onChange={e => setPrice(e.target.value)} value={Price} />
                                    </div>

                                    <div>
                                        <label htmlFor="Image_capa">Imagem Capa:</label>
                                        <input type="File" name="Image_capa" id="Image_capa" className="Image_capa-Prod" accept="image/*" onChange={HandleImage_capa} />
                                    </div>

                                    <div>
                                        <label htmlFor="Video">Link de um video</label>
                                        <input type="text" name="L-V" id="Video" placeholder="https://www.youtube.com/embed/(ID-Video)" className="Video-Prod" onChange={e => setVideo(e.target.value)} value={Video} />
                                    </div>

                                    <div>
                                        <label htmlFor="Video">Arquivo</label>
                                        <input type="file" name="L-V" id="Video" placeholder="https://www.youtube.com/embed/(ID-Video)" className="Video-Prod" onChange={HandleFile} />
                                    </div>

                                    <div>
                                        <label htmlFor="Fotos">Fotos:</label>
                                        <input type="file" name="Fotos" id="Fotos" className="Fotos-Prod" accept="image/*" multiple onChange={HandleImage} />
                                    </div>
                                </div>
                                <div className="AP-Btn">
                                    <button className="Save" onClick={SalvaProd}>Salvar</button>
                                    <button className="Cancel" onClick={ClosePopUp}>Cancelar</button>
                                </div>
                            </section>
                        )
                    }
                </div>
            </main>
        </>
    )
}
export default Produtos;